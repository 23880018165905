import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MenuItem as IMenuItem } from '../../model/type/menu-item';

import { classNames } from '../../../../utils/class-names';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { AppRoute } from '../../../../router';

import cls from './menu-item.module.scss';

interface Props {
  item: IMenuItem;
  currentItem: IMenuItem;
  children?: React.ReactNode;
}

const MenuItem = memo((props: Props) => {
  const { item, currentItem, children } = props;
  const [itemName, setItemName] = useState(item?.name || '');
  const Icon = item.Icon;
  const navigate = useNavigate();
  const userInfo: any = useSelector(getUserInfo);

  const handleItemClick = () => {
    if (item.path === AppRoute.IN_STORE) {
      window.open(userInfo?.company?.website || '', '_blank');
    } else {
      navigate(item.redirectTo || item.path);
    }
  };

  const mods = {
    [cls.current]: currentItem === item,
    [cls.group]: !!children,
  };

  useEffect(() => {
    if (item.path === AppRoute.IN_STORE) {
      if (!userInfo?.company?.is_ecommerce) {
        setItemName('View Site');
        localStorage.setItem('cc', 'View Site');
      } else {
        setItemName('View Store');
        localStorage.setItem('cc', 'View Store');
      }
    }
  }, [item, userInfo]);

  return (
    <>
      <div className={classNames(cls.menuItem, mods)} onClick={handleItemClick}>
        {Icon && <Icon />}
        {itemName}
      </div>
      {item.subMenuItems && item === currentItem && children}
    </>
  );
});

export { MenuItem };
