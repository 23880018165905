import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';
import services from '../../services';
import { fetchProducts, setUserInfoAction } from '../../store/ducks/inSiteAppState';

function PrivateRoute({ children }: any) {
  const { isAuthenticated } = useAuthContext();
  const dispatch = useDispatch();

  const getAccountInfo = async () => {
    try {
      console.log('Account Info Call');
      let response = await services.getAccountInfo();

      // FIXME: No check for response. If return error try/cath not catch it.
      dispatch(setUserInfoAction(response));
    } catch (error) {
      console.log(error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      getAccountInfo();
      //dispatch(fetchProducts());
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default PrivateRoute;
