import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';

import mockedGraph from './mock.png';
import { Button } from '../../../button';

const GraphLinkedinEngagementRate = (props: GraphProps) => {
  const { className, ...rest } = props;

  return (
    <GraphWrapper className={className} {...rest} header="LINKEDIN ENGAGED USERS" empty>
      {/* <img className={cls.mockedGraph} src={mockedGraph} alt="Mock" />
      <TextBlock className={cls.info} centred>
        <span className={cls.inc}>20%</span>
        <span className={cls.text}>increase</span>
      </TextBlock> */}
      <Button>Connect</Button>
    </GraphWrapper>
  );
};

export { GraphLinkedinEngagementRate };
