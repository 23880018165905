import { Typography } from '@mui/material';
import InSIteSmallCard from '../../components/InSIteSmallCard/InSIteSmallCard';
import { ContentWrapper } from '../../components/content-wrapper';
import useStyles from '../../theme';
import useStripePay from '../../hooks/useStripePay/useStripePay';
import { useEffect, useState } from 'react';
import infoIcon from '../../../src/assets/info.png';
import InSiteInformationDialouge from '../../components/InSiteInformationDialouge/InSiteInformationDialouge';
import { useSelector } from 'react-redux';
import { getProductsByPage } from '../../store/ducks/inSiteAppState';
import { processPrice } from '../../utils';

export default function SocialMedia() {
  const classes = useStyles();
  let { handlePay } = useStripePay();
  const [informationDialouge, setInformationDialouge] = useState(false);
  const [disableInfo, setDisableInfo] = useState(null);

  const products = useSelector((state: any) => getProductsByPage(state, 'social_media_management'));

  useEffect(() => {
    let obj: any = {};
    products.forEach((element: any) => {
      obj[element._id] = false;
    });
    setDisableInfo(obj);
    // eslint-disable-next-line
  }, []);

  const handleStripePay = (id: any) => {
    setDisableInfo((prevState: any) => {
      let state = Object.assign({}, prevState); // creating copy of state variable jasper
      state[id] = true; // update the name property, assign a new value
      return state;
    });
    handlePay(id);
  };

  return (
    <ContentWrapper>
      {informationDialouge && (
        <InSiteInformationDialouge
          content={
            <p
              style={{
                color: '#4C505F',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'justify',
              }}>
              Implementing the correct Social Media strategy for your business means happier
              customers and better word of mouth. We have full-time professional social media
              specialists available to handle all of your business’s social media channels.
              <br />
              <br />
              <b>
                Did you know that the daily average that a person spends on social media is 142
                minutes a day.
              </b>
              <br />
              <br />
              This means that your next client is most likely browsing their social media feeds
              right now. Therefore, if you’re looking to build a strong community on Facebook, get
              more followers on Instagram, and generate lots of likes and comments, Then look no
              further because RankWorks has different levels of social media management plans that
              are designed to help your company’s brand grow online.
            </p>
          }
          title={'Social Media Management'}
          openStatus={informationDialouge}
          setInformationDialouge={setInformationDialouge}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          width: '100%',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography className={classes.typographyTitle}>Social Media Management</Typography>
          <div
            style={{
              marginLeft: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}>
            <img
              src={infoIcon}
              alt="Info"
              className="info"
              onClick={() => {
                setInformationDialouge(true);
              }}
            />
          </div>
        </div>
        <div className="admin__table_header_wrapper">
          <Typography className={classes.typographyCardTitleItalic} style={{ marginLeft: '10px' }}>
            Did you know that the daily average that a person spends on social media is 142 minutes
            a day.
          </Typography>
        </div>
        <Typography className={classes.typographySubTitle}>
          This means that your next client is most likely browsing their social media feeds right
          now!
          <br />
          <br />
          <li>Facebook </li>
          <li>Instagram </li>
          <li>Youtube </li>
          <li>Twitter </li>
        </Typography>
        <Typography
          className={classes.typographyCardSubTitle}
          style={{ fontStyle: 'italic', marginBottom: '20px' }}>
          Let Us manage your Social Media presence while you manage your business!!
        </Typography>
        <div style={{ display: 'flex', width: '100%', gap: '15px' }}>
          {products &&
            products.map((content: any, index: number) => {
              return (
                <InSIteSmallCard
                  key={content.default_price_id}
                  title={content.name}
                  subTitle={
                    processPrice(content.pricing[0].unit_amount_decimal) +
                    '/' +
                    content.pricing[0].recurring.interval
                  }
                  unitAmount={content.pricing[0].unit_amount_decimal}
                  contentArray={content.services}
                  tag={content.ui.insite_most_popular}
                  type="regular"
                  activeBtnFn={() => handleStripePay(content._id)}
                  isDisabled={disableInfo ? disableInfo[content._id] : false}
                />
              );
            })}
        </div>
      </div>
    </ContentWrapper>
  );
}
