import { FC, ReactNode } from 'react';

import cls from './label.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  className?: string;
  children?: ReactNode;
}

const Label: FC<Props> = props => {
  const { className, children, ...rest } = props;

  return (
    <label className={classNames(cls.label, className)} {...rest}>
      {children}
    </label>
  );
};

export { Label };
