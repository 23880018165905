import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import services from '../../../../services';
import { useSelector } from 'react-redux';
import {
  setDashboardActiveUsersDataByDeviceAction,
  getActiveUsersByDevice,
} from '../../../../store/ducks/dashboardState';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { Pie, Doughnut } from 'react-chartjs-2';
import { fetchRevenueByProduct, selectRevenueByProductList } from '../../../../store/ducks/admin-dashboard-state';

const GraphAdminRevenueByProduct = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const productList = useSelector(selectRevenueByProductList);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (productList.length === 0) dispatch(fetchRevenueByProduct());
  }, [dispatch]);

  const getPieChartInfo = (type: string) => {
    let dataSet: any = [];
    let labelSet: any = [];
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const PIE_CHART_COLORS = [
      styles.getPropertyValue('--Accent-warm-Warm'),
      styles.getPropertyValue('--Primary-Primary-300'),
      styles.getPropertyValue('--Secondary-Secondary-300'),
      styles.getPropertyValue('--Accent-cold-Cool'),
      styles.getPropertyValue('--Information-strong'),
      styles.getPropertyValue('--Secondary-Secondary-700'),
      styles.getPropertyValue('--Accent-cold-Cool-800'),
    ];

    productList.forEach((element: any) => {
      dataSet.push(element['total_amount']);
      labelSet.push(element['name']);
    });

    return {
      labels: labelSet,
      datasets: [
        {
          label: type,
          data: dataSet,
          backgroundColor: PIE_CHART_COLORS,
          borderColor: PIE_CHART_COLORS,
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="REVENUE BY PRODUCT CATEGORY"
      total={isLoading ? 'Loading...' : ''}
    >
      <Pie
        data={getPieChartInfo('Total Revenue')}
        style={{
          maxHeight: '380px',
          maxWidth: '100%',
        }}
        options={{
          plugins: {
            legend: {
              position: 'left',
              labels: {
                boxWidth: 20,
                boxHeight: 20,
              },
            },

            datalabels: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              right: 20,
              left: 10,
              bottom: 10,
            },
          },
        }}
      />
    </GraphWrapper>
  );
};

export { GraphAdminRevenueByProduct };
