import { memo, useEffect, useState } from 'react';
import { Button } from '../button';
import { getTagManagerScript } from '../../utils';
import { TextBlock } from '../text-block';
import cls from './gtm-copy-modal.module.scss';
import { Frame } from '../frame';
import { Modal } from '../modal';
import { ReactComponent as IcnCopy } from '../../assets/icons/icn-copy.svg';
import { SelectOption } from '../select/ui/select';
import InSiteSnackBar from '../InSIteSnackBar/InSiteSnackBar';

interface Props {
  onClick: () => void;
  defaultPageNo: number;
  businessCategories?: SelectOption<string>[];
  noOverlay: boolean;
  gray: boolean;
  gtmCode: string;
}

const GTMTagModal = memo((props: Props) => {
  const { defaultPageNo, businessCategories, onClick, noOverlay, gray, gtmCode } = props;

  const [pageNo, setPageNo] = useState(defaultPageNo);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(getTagManagerScript(gtmCode));
    setIsCopied(true);
  };

  const handleCloseBusinessInfo = () => {
    onClick();
  };

  const intro = `Copy the code below and paste it onto every page of your website.
  Paste this code as high in the <head> of the page as possible:`;

  return (
    <div className="BusinessRegistration__wrapper">
      {pageNo === 0 && (
        <Modal sizeL noOverlay gray={gray} sizeS setOpen={onClick}>
          <Frame>
            <div className="BusinessRegistration__page0">
              <TextBlock centred header="Complete Your Setup" />
            </div>
          </Frame>
          <Button onClick={() => setPageNo(1)}>Next</Button>
        </Modal>
      )}

      {pageNo === 1 && gtmCode && (
        <Modal sizeL noOverlay setOpen={onClick}>
          <Frame>
            <TextBlock header="Google Analytics Code">{intro}</TextBlock>
            <TextBlock className={cls.codeBlock}>
              <Button className={cls.copyButton} onClick={handleCopy} icon={IcnCopy} />
              {getTagManagerScript(gtmCode)}
            </TextBlock>
          </Frame>
          <Button onClick={handleCloseBusinessInfo}>Done</Button>
        </Modal>
      )}
      {pageNo === 1 && !gtmCode && (
        <Modal sizeM noOverlay setOpen={onClick}>
          <TextBlock centred frame header="Google Analytics Code">
            Can not provide the tag manager right now. Please contact your service.
          </TextBlock>
          <Button onClick={handleCloseBusinessInfo} primary>
            Finish
          </Button>
        </Modal>
      )}
      <InSiteSnackBar
        alertOpen={isCopied}
        alertMessage={'Tag Manager copied to clipboard.'}
        setAlertOpen={setIsCopied}
      />
    </div>
  );
});

export { GTMTagModal };
