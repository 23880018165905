import { classNames } from '../../../../utils/class-names';

import cls from '../graph.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;

  header?: string;
  total?: string | number;

  empty?: boolean;
  error?: boolean;
  critical?: boolean;
  large?: boolean;
  huge?: boolean;
}

const GraphWrapper = (props: Props) => {
  const { className, children, header, total, empty, error, critical, large, huge } = props;

  const mods = {
    [cls.empty]: empty,
    [cls.error]: error,
    [cls.critical]: critical,
    [cls.large]: large || huge,
  };

  return (
    <div className={classNames(cls.graphCard, mods, className)}>
      {header && <h3 className={cls.header}>{header}</h3>}
      {total && <h4 className={cls.total}>{total}</h4>}
      {children}
    </div>
  );
};

export { GraphWrapper };
