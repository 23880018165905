import { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartData } from 'chart.js';

import { GraphProps } from '../../model/types/graph';
import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

const GraphGA4TrafficAcquisitionPerChannelGroup = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  const data: ChartData<'pie', number[], unknown> = useMemo(() => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const PIE_CHART_COLORS = [
      styles.getPropertyValue('--Accent-warm-Warm'),
      styles.getPropertyValue('--Primary-Primary-300'),
      styles.getPropertyValue('--Secondary-Secondary-300'),
      styles.getPropertyValue('--Accent-cold-Cool'),
    ];

    const returnedDataSet = {
      labels: [] as string[],
      datasets: [
        {
          label: '# of search',
          data: [] as number[],
          backgroundColor: PIE_CHART_COLORS,
        },
      ],
    };

    if (!dataSet) return returnedDataSet;

    const dataArray = dataSet.ga4_data;

    if (!dataArray) {
      console.log('no data array');
      return returnedDataSet;
    }

    let dataDict: { [key: string]: number } = {};

    dataSet.ga4_data.forEach((element: any) => {
      const source = element.sessionDefaultChannelGroup || 'unknown';
      const value = element.activeUsers || 0;

      if (dataDict[source]) {
        dataDict[source] += Number(value) || 0;
      } else {
        dataDict[source] = Number(value) || 0;
      }
    });

    const sources = Object.keys(dataDict);
    const values = Object.values(dataDict);

    returnedDataSet.labels = sources;
    returnedDataSet.datasets[0].data = values;

    return returnedDataSet;
  }, [dataSet]);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Traffic acquisition channel group"
      total={''}>
      <Pie
        data={data}
        style={{
          maxHeight: '380px',
          maxWidth: '100%',
        }}
        options={{
          plugins: {
            legend: {
              position: 'left',
              labels: {
                boxWidth: 20,
                boxHeight: 20,
              },
            },

            datalabels: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              right: 20,
              left: 10,
              bottom: 10,
            },
          },
        }}
      />
    </GraphWrapper>
  );
};

export { GraphGA4TrafficAcquisitionPerChannelGroup };
