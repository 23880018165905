import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import services from '../../../../services';
import { useEffect, useState } from 'react';
import { getNewUsersByDate, setDashboardNewUsersDataByDateAction } from '../../../../store/ducks/dashboardState';
import { Line } from 'react-chartjs-2';
import { LINE_CHART_CONFIG } from '../../../../constants';
import moment from 'moment';

const GraphGA4NewUsersByDate = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const newUsers = useSelector(getNewUsersByDate);
  const userInfo = useSelector(getUserInfo);
  const [isLoading, setLoading] = useState(false);

  const getNewUsersByDateData = async () => {
    try {
      setLoading(true);
      const response = await services.getGA4NewUsersDataByDay(userInfo?.email || 'test@rankworks.com', '0');
      const accumulatedNewUsersByDate: any = {};

      // Iterate through the data array
      response?.ga4_data &&
        response.ga4_data.forEach((entry: any) => {
          const dateKey = `${entry.year}-${entry.month}-${entry.day}`;

          if (!accumulatedNewUsersByDate[dateKey]) {
            accumulatedNewUsersByDate[dateKey] = 0;
          }

          accumulatedNewUsersByDate[dateKey] += parseInt(entry.newUsers);
        });

      const accumulatedNewUsersArray = Object.keys(accumulatedNewUsersByDate).map((dateKey) => ({
        date: dateKey,
        totalNewUsers: accumulatedNewUsersByDate[dateKey],
      }));
      dispatch(setDashboardNewUsersDataByDateAction(accumulatedNewUsersArray));

      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (newUsers.length === 0) getNewUsersByDateData();
  }, []);

  const generateLineGraph = (data: any) => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Accent-cold-Cool'),
      styles.getPropertyValue('--Accent-warm-Warm'),
    ];
    const result = {
      labels: data.map((item: any) => moment(item.date).format('D MMM')) || [],
      datasets: [
        {
          label: 'New Users',
          data: data.map((item: any) => item.totalNewUsers),
          backgroundColor: [styles.getPropertyValue('--Primary-Primary-400')],
          borderColor: [styles.getPropertyValue('--Primary-Primary-400')],
          borderWidth: 1,
          pointBackgroundColor: pointColors,
          pointBorderColor: pointColors,
        },
      ],
    };
    return result;
  };

  return (
    <GraphWrapper className={className} {...rest} header="NEW USERS BY DATE" total={isLoading ? 'Loading...' : ''}>
      <Line
        data={generateLineGraph(newUsers)}
        style={{
          maxHeight: '380px',
          maxWidth: '100%',
          paddingLeft: '10px',
          display: 'flex',
        }}
        options={LINE_CHART_CONFIG}
      />
      <TextBlock className={cls.info} centred>
        <span className={cls.dec}>21%</span>
        <span className={cls.text}>decrease</span>
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphGA4NewUsersByDate };
