import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import services from '../../services';
import * as _ from 'lodash';

interface InstagramInfo {
  totalFollowers: string;
  totalNewFollowers: string;
  totalPagePosts: string;
  totalProfileViews: string;
  newFollowersdataByDate: any;
  engagedUsersDataByDate: any;
}

export interface InstagramAnalyticsState {
  instagram: InstagramInfo | null;
}

export const SET_IG_TOTAL_FOLLOWERS_TYPE = 'SET_IG_TOTAL_FOLLOWERS_TYPE';
export const SET_IG_TOTAL_PAGE_POST_TYPE = 'SET_IG_TOTAL_PAGE_POST_TYPE';
export const SET_IG_TOTAL_PROFILE_VIEWS_TYPE = 'SET_IG_TOTAL_PROFILE_VIEWS_TYPE';
export const SET_IG_NEW_FOLLOWERS_DATA_BY_DATE_TYPE = 'SET_IG_NEW_FOLLOWERS_DATA_BY_DATE_TYPE';
export const SET_TOTAL_IG_NEW_FOLLOWERS_TYPE = 'SET_TOTAL_IG_NEW_FOLLOWERS_TYPE';
export const SET_IG_ENGAGED_USERS_DATA_BY_DATE_TYPE = 'SET_IG_ENGAGED_USERS_DATA_BY_DATE_TYPE';

// Interfaces

export interface InstagramTotalFollowersActionInterface extends AnyAction {
  followers: string;
  type: typeof SET_IG_TOTAL_FOLLOWERS_TYPE;
}
export interface InstagramTotalPagePostsActionInterface extends AnyAction {
  posts: string;
  type: typeof SET_IG_TOTAL_PAGE_POST_TYPE;
}
export interface InstagramProfileViewsActionInterface extends AnyAction {
  views: string;
  type: typeof SET_IG_TOTAL_PROFILE_VIEWS_TYPE;
}
export interface InstagramNewFollowesDataByDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_IG_NEW_FOLLOWERS_DATA_BY_DATE_TYPE;
}

export interface InstagramTotalNewFollowersActionInterface extends AnyAction {
  newFollowers: string;
  type: typeof SET_TOTAL_IG_NEW_FOLLOWERS_TYPE;
}

export interface InstagramEngagedUsersDataByDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_IG_ENGAGED_USERS_DATA_BY_DATE_TYPE;
}

// IG Actions

export const setInstagramTotalFollowersAction = (followers: string): InstagramTotalFollowersActionInterface => ({
  followers,
  type: SET_IG_TOTAL_FOLLOWERS_TYPE,
});

export const setInstagramTotalPagePostsAction = (posts: string): InstagramTotalPagePostsActionInterface => ({
  posts,
  type: SET_IG_TOTAL_PAGE_POST_TYPE,
});

export const setInstagramProfileViewsAction = (views: string): InstagramProfileViewsActionInterface => ({
  views,
  type: SET_IG_TOTAL_PROFILE_VIEWS_TYPE,
});

export const setInstagramNewFollowesDataByDateAction = (data: any): InstagramNewFollowesDataByDateActionInterface => ({
  data,
  type: SET_IG_NEW_FOLLOWERS_DATA_BY_DATE_TYPE,
});

export const setInstagramTotalNewFollowersAction = (
  newFollowers: string
): InstagramTotalNewFollowersActionInterface => ({
  newFollowers,
  type: SET_TOTAL_IG_NEW_FOLLOWERS_TYPE,
});

export const setInstagramEngagedUsersDataByDateAction = (
  data: any
): InstagramEngagedUsersDataByDateActionInterface => ({
  data,
  type: SET_IG_ENGAGED_USERS_DATA_BY_DATE_TYPE,
});

/** Create type for token reducer actions */
export type tokenActionTypes = InstagramNewFollowesDataByDateActionInterface | AnyAction;

/** Create an immutable form state */
export type ImmutableSocialAnalyticsState = SeamlessImmutable.ImmutableObject<InstagramAnalyticsState>;

/** initial form state */
export const initialState: ImmutableSocialAnalyticsState = SeamlessImmutable({
  instagram: {
    totalNewFollowers: '-',
    totalPagePosts: '-',
    newFollowersdataByDate: [],
    totalFollowers: '-',
    totalProfileViews: '-',
    engagedUsersDataByDate: [],
  },
});

// Reducers

/** the form reducer function */
export default function inSiteAdminReducer(
  state: ImmutableSocialAnalyticsState = initialState,
  action: tokenActionTypes
): ImmutableSocialAnalyticsState {
  switch (action.type) {
    case SET_IG_NEW_FOLLOWERS_DATA_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              newFollowersdataByDate: (action as any).data,
            }
          : state.instagram,
      });
    case SET_IG_TOTAL_FOLLOWERS_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              totalFollowers: (action as any).followers,
            }
          : state.instagram,
      });
    case SET_IG_TOTAL_PAGE_POST_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              totalPagePosts: (action as any).posts,
            }
          : state.instagram,
      });
    case SET_TOTAL_IG_NEW_FOLLOWERS_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              totalNewFollowers: (action as any).newFollowers,
            }
          : state.instagram,
      });
    case SET_IG_TOTAL_PROFILE_VIEWS_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              totalProfileViews: (action as any).views,
            }
          : state.instagram,
      });
    case SET_IG_ENGAGED_USERS_DATA_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        instagram: state.instagram
          ? {
              ...state.instagram,
              engagedUsersDataByDate: (action as any).data,
            }
          : state.instagram,
      });
    default:
      return state;
  }
}

// IG selectors

export function getInstagramTotalFollowers(state: Partial<Store>): any {
  return (state as any).instagramAnalyticsInfo?.instagram?.totalFollowers;
}

export function getInstagramTotalPagePosts(state: Partial<Store>): any {
  return (state as any).instagramAnalyticsInfo?.instagram?.totalPagePosts;
}

export function getInstagramNewFollowersDataByDate(state: Partial<Store>): any {
  return (state as any).instagramAnalyticsInfo?.instagram?.newFollowersdataByDate.asMutable({ deep: true });
}

export function getInstagramProfileViews(state: Partial<Store>): any {
  return (state as any).instagramAnalyticsInfo?.instagram?.totalProfileViews;
}

export function getInstagramTotalNewFollowers(state: Partial<Store>): string {
  return (state as any).instagramAnalyticsInfo?.instagram?.totalNewFollowers;
}

export function getInstagramEngagedUsersDataByDate(state: Partial<Store>): any {
  return (state as any).instagramAnalyticsInfo?.instagram?.engagedUsersDataByDate.asMutable({ deep: true });
}
