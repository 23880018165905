import { Flex } from '../../flex';
import cls from './insite-app-plans.module.scss';

interface Props {
  children: React.ReactNode;
}

const PlanWrapper = (props: Props) => {
  const { children } = props;

  return (
    <Flex className={cls.planWrapper} left>
      {children}
    </Flex>
  );
};

export { PlanWrapper };
