import { memo } from 'react';
import cls from './radio.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  children?: React.ReactNode;
}

const Radio: React.FC<Props> = memo((props: Props) => {
  const { className, children, ...rest } = props;

  return (
    <label className={classNames(cls.radio, className)}>
      {children}
      <input className={cls.input} type="radio" {...rest} />
    </label>
  );
});

export { Radio };
