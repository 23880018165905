import { memo } from 'react';

import { Section } from '../../../get-help/ui/components/section';
import { TextBlock } from '../../../../components/text-block';
import { Label } from '../../../../components/label';
import { TextField } from '../../../../components/text-field';
import { Flex } from '../../../../components/flex';
import { GeneralInformation } from '../../model/types/general-information';

import cls from './contact-information.module.scss';

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const ContactInformation = memo((props: Props) => {
  const { className, data, setData } = props;

  const handleFieldChange =
    (key: keyof GeneralInformation) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value });
    };

  return (
    <Section>
      <TextBlock header={'Contact Information'} />
      <Label>
        Business Name
        <TextField value={data.businessName} onChange={handleFieldChange('businessName')} />
      </Label>
      <TextBlock className={cls.mainContact} header={'Main Contact'} />
      <Flex>
        <Label>
          First Name
          <TextField value={data.firstName} onChange={handleFieldChange('firstName')} />
        </Label>
        <Label>
          Last Name
          <TextField value={data.lastName} onChange={handleFieldChange('lastName')} />
        </Label>
      </Flex>
      <Label>
        Job title
        <TextField value={data.jobTitle} onChange={handleFieldChange('jobTitle')} />
      </Label>
      <Label>
        Current Website URL (if applicable)
        <TextField
          value={data.currentWebsiteUrl}
          onChange={handleFieldChange('currentWebsiteUrl')}
        />
      </Label>
      <Label>
        Current Web Hosting Company (if applicable)
        <TextField
          value={data.currentWebHostingCompany}
          onChange={handleFieldChange('currentWebHostingCompany')}
        />
      </Label>
    </Section>
  );
});

export { ContactInformation };
