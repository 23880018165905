import { DOMAttributes, FC } from 'react';

import { AppBar } from '../../app-bar';
import { useAuthContext } from '../../../hooks/useAuthContext/useAuthContext';

import cls from './page-wrapper.module.scss';
import { isMobile } from '../../../utils/is-mobile';

interface Props extends DOMAttributes<HTMLDivElement> {
  className?: string;
}

const PageWrapper: FC<Props> = props => {
  const { children } = props;
  const { isAuthenticated } = useAuthContext();

  if (isMobile()) {
    return <>{children}</>;
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.header}>
        IN-SITE
        {isAuthenticated && <AppBar />}
      </div>
      {children}
    </div>
  );
};

export { PageWrapper };
