import sortUpIcon from '../assets/sort-up.svg';
import sortDownIcon from '../assets/sort-down.svg';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { Skeleton } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 100 }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F6F6F6',
    color: '#808E9E',
    fontWeight: '400',
    fontSize: 14,
    border: '0.5px solid #D2D2D2',
    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
  },
}));

export const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    display: 'flex',
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const SortIcons = () => {
  return (
    <>
      <img src={sortUpIcon} alt="sort-up" height={7} width={8} className="sort" />
      <img
        src={sortDownIcon}
        alt="sort-down"
        height={7}
        width={8}
        className="sort"
        style={{ transform: 'scale(1.1)' }}
      />
    </>
  );
};

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const DummyDiv = () => {
  return (
    <div>
      <Skeleton
        variant="circular"
        width={250}
        height={250}
        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
      />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
};
