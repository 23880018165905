import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { debounce } from "lodash";

interface EmailValidityCheckProps {
  setFn: Dispatch<SetStateAction<string>>;
}

export default function useDebounceValidation({
  setFn,
}: EmailValidityCheckProps) {
  const debouncedServiceCall = useRef(
    debounce(async (value) => {
      setFn(value);
    }, 1000) //in milisec
  ).current;

  useEffect(() => {
    return () => {
      debouncedServiceCall.cancel();
    };
  }, [debouncedServiceCall]);

  return { debouncedServiceCall };
}
