import { ContentWrapper } from '../../../components/content-wrapper';

const ContractPage = () => {
  return (
    <ContentWrapper>
      <h1>fdkjhfjdhg</h1>
    </ContentWrapper>
  );
};

export { ContractPage };
