import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import services from '../../../../services';
import { useSelector } from 'react-redux';
import { setDashboardNewUsersDataByCountryAction, getNewUsersByCountry } from '../../../../store/ducks/dashboardState';
import { useDispatch } from 'react-redux';
import { dateFromTimestamp } from '../../../../utils/timestamp';
import { BarChart } from '../../../bar-chart';
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';

const GraphNewUsersByCountry = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const newUsers = useSelector(getNewUsersByCountry);
  const userInfo = useSelector(getUserInfo);
  const [isLoading, setLoading] = useState(false);

  const getNewUsersByCountryData = async () => {
    try {
      setLoading(true);
      const response = await services.getGA4NewUsersDataByCountry(userInfo?.email || 'test@rankworks.com');
      dispatch(setDashboardNewUsersDataByCountryAction(response.ga4_data));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (newUsers.length === 0) getNewUsersByCountryData();
  }, []);

  const generateGraphData = (tempData: any) => {
    const data = tempData.slice(0, 10);
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Primary-Primary-400'),
      styles.getPropertyValue('--Primary-Primary'),
    ];
    return {
      labels: data.map((item: any) => item.country),
      datasets: [
        {
          label: 'New Users',
          data: data.map((item: any) => item.newUsers),
          backgroundColor: pointColors,
          borderColor: pointColors,
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <GraphWrapper className={className} {...rest} header="NEW USERS BY COUNTRY" total={isLoading ? 'Loading...' : ''}>
      {/* <img className={cls.mockedGraph} src={mockedGraph} alt="Mock" /> */}
      <BarChart data={generateGraphData(newUsers)} />
      <TextBlock className={cls.info} centred>
        <span className={cls.inc}>77%</span>
        <span className={cls.text}>decrease</span>
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphNewUsersByCountry };
