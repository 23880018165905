import { memo, useEffect, useState } from 'react';
import { classNames } from '../../../utils/class-names';
import { Flex } from '../../flex';
import { Section } from '../../../pages/get-help/ui/components/section';
import { Label } from '../../label';
import { TextField } from '../../text-field';
import { loadStripe } from '@stripe/stripe-js';
import cls from './payment.module.scss';
import { HR } from '../../hr';
import { Button } from '../../button';
import services from '../../../services';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkout-form';
import { useSelector } from 'react-redux';
import { getCheckoutInfo } from '../../../store/ducks/paymentState';
import { AppRoute } from '../../../router';
import { ReactComponent as Icnedit } from '../../../assets/icons/icn-edit.svg';

const STRIPE_KEY = process.env.REACT_APP_ENV_STRIPE_PUBLIC_KEY || '';

const Payment = memo(() => {
  const checkoutInfo = useSelector(getCheckoutInfo);
  const { planInfo, priceInfo, userInfo, planType } = checkoutInfo;
  const [customer, setCustomer] = useState<any>(null);
  const [name, setName] = useState<string>(userInfo?.name?.first_name + ' ' + userInfo?.name?.last_name || '');
  const [businessName, setBusinessName] = useState<string>(userInfo?.company?.name || '');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>(userInfo?.company?.primary_service_area?.city || '');
  const [state, setState] = useState<string>(userInfo?.company?.primary_service_area?.state || '');
  const [isLoading, setIsLoading] = useState(false);
  const stripePromise: any = loadStripe(STRIPE_KEY);
  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const response = await services.getStripeCustomerInfo();
      setCustomer(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  if (!planInfo && !priceInfo) {
    if (planType === 'gmb') navigate(AppRoute.GMB_PLANS);
    if (planType === 'seo') navigate(AppRoute.SEO);
    else navigate(AppRoute.DASHBOARD);
  }

  if (!planInfo || !priceInfo || !customer) return null;

  console.log(planInfo, priceInfo);

  const handleSubscribeClick = async () => {
    try {
      setIsLoading(true);
      const data = {
        price_id: priceInfo[planInfo.title].id,
        plan_id: planInfo.id,
        payment_id: customer.invoice_settings.default_payment_method,
        customer_stripe_id: customer.id,
        plan_type: planType,
      };
      await services.createStripeBasicSubscription(data);
      setIsLoading(false);
      navigate('/payment/success?redirect=' + planType);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleAddPymentMethod = () => {
    setCustomer((prevCustomer: any) => ({
      ...prevCustomer,
      default_card: null,
    }));
  };

  const shouldSubscriptionDisabled = !address || !businessName || !city || !state;

  const SubscribeSection = () => {
    return (
      <Section className={cls.review}>
        <h2 className={cls.header}>Review</h2>
        <HR />
        <h3 className={cls.title}>{planInfo.title}</h3>
        <p className={cls.payment}>
          <span className={cls.price}>${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}</span> /{' '}
          {priceInfo[planInfo.title].recurring.interval}
        </p>
        <p className={cls.description}>{planInfo.description}</p>
        <ul className={cls.featureList}>
          {planInfo.features.map((feature: any, index: number) => (
            <li className={cls.feature} key={'feature__' + index}>
              {feature}
            </li>
          ))}
        </ul>
        <HR />
        <Flex className={cls.row}>
          <span>Sub-total</span>
          <span>${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TPS</span>
          <span>$ 0.00</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TVQ</span>
          <span>$ 0.00</span>
        </Flex>
        <HR />
        <Flex className={cls.row}>
          <span>Total with taxes</span>
          <span>${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.buttons} right>
          <Button primary onClick={handleSubscribeClick} disabled={isLoading || shouldSubscriptionDisabled}>
            Subscribe
          </Button>
        </Flex>
      </Section>
    );
  };

  return (
    <Flex className={classNames(cls.wrapper)}>
      {customer && customer?.default_card ? (
        <>
          <Section className={cls.billing}>
            <h2 className={cls.header}>Billing & Payment information</h2>
            <Label>
              Cardholder's full name
              <TextField value={name} onChange={(event: any) => setName(event.target.value)} />
            </Label>
            <Label>
              Business name *
              <TextField value={businessName} onChange={(event: any) => setBusinessName(event.target.value)} />
            </Label>
            <Label>
              Street Address *
              <TextField value={address} onChange={(event: any) => setAddress(event.target.value)} />
            </Label>
            <Flex>
              <Label>
                City *
                <TextField value={city} onChange={(event: any) => setCity(event.target.value)} />
              </Label>
              <Label>
                State / Province / Region *
                <TextField value={state} onChange={(event: any) => setState(event.target.value)} />
              </Label>
            </Flex>
            <Flex>
              <div className={cls.stripeWrapper}>
                <Label>Default Payment Method</Label>
                <div className={cls.stripeCard}>
                  <div className={cls.stripeCardContent}>
                    <div className={cls.cardBrand}>
                      {customer.default_card.brand.toUpperCase()}, {'Expires: ' + customer.default_card.exp_month}/
                      {customer.default_card.exp_year % 100}
                    </div>
                    <div className={cls.cardLast4}>**** **** **** {customer.default_card.last4}</div>
                  </div>
                </div>
              </div>
              <div className={cls.editCard}>
                <div className={cls.editCardContent}>
                  <Icnedit className={cls.editCardIcon} onClick={handleAddPymentMethod} />
                </div>
              </div>
            </Flex>
          </Section>
          <SubscribeSection />
        </>
      ) : (
        customer && (
          <Elements
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc: 'https://fonts.googleapis.com/css2?family=Mulish&display=swap',
                },
              ],
              mode: 'subscription',
              amount: priceInfo[planInfo.title].unit_amount, // N.B: Replace value from price object , Must be 119900 this format without decimal point
              currency: priceInfo[planInfo.title].currency, //N.B: Replace value from price object
              appearance: {
                theme: 'stripe',
                rules: {
                  '.Label': {
                    fontSize: '14px',
                    fontFamily: 'Mulish, sans-serif',
                    marginBottom: '10px',
                  },
                  '.Input': {
                    border: '1px solid #001955',
                  },
                },
                variables: {
                  borderRadius: '4px',
                  colorText: '#00003c',
                  colorTextPlaceholder: '#8c96be',
                },
              },
            }}
          >
            <CheckoutForm
              priceInfo={priceInfo[planInfo.title]}
              customerInfo={customer}
              paymentInfo={planInfo}
              userInfo={userInfo}
              planType={planType}
              shouldSubscriptionDisabled={shouldSubscriptionDisabled}
            />
          </Elements>
        )
      )}
    </Flex>
  );
});

export { Payment };
