import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/content-wrapper';
import services from '../../services';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Filler,
} from 'chart.js/auto';
import { Bar, Line, Pie } from 'react-chartjs-2';
import './home.scss';
import { CircularProgress, Typography } from '@mui/material';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../store/ducks/inSiteAppState';
import getDB from '../../services/db';
import { DummyDiv } from '../../components';
import {
  accumulator,
  daysCount,
  externalTooltipHandler,
  generateLineChartData,
  kFormatter,
  shortEnglishHumanizer,
} from '../../utils';
import { LINE_CHART_CONFIG, LINE_GRAPH_COLOR, monthNames, PIE_CHART_COLORS } from '../../constants';
import useStyles from '../../theme';
import calendarIcon from '../../../src/assets/calendar.svg';
import demoImg from '../../../src/assets/demo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button } from '../../components/button';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
);

export default function Home() {
  let selectedDate = sessionStorage.getItem('selected_date');
  const [pieData, setPieData] = useState<any>(null);
  const [lineData, setLineData] = useState<any>(null);
  const [googleConsoleData, setGoogleConsoleData] = useState<any>(null);
  const [googleConsoleTotalData, setGoogleConsoleTotalData] = useState<any>(null);
  const [defaultChannelGoupLineData, setDefaultChannelGoupLineData] = useState<any>(null);
  const [monitizationData, setMonitizationData] = useState<any>(null);
  const [date, setDate] = useState<any>(selectedDate ? selectedDate : new Date());
  const [dataUpdated, setDataUpdated] = useState<any>(false);
  const [googleConsoleError, setGoogleConsoleError] = useState<any>(null);
  const [open, setOpen] = useState<any>(false);

  const userInfo: any = useSelector(getUserInfo);
  const userEmail: any = userInfo?.email || '';

  let dbClient = getDB();
  const classes = useStyles();

  const getCachedData = async (type: string) => {
    try {
      let doc = await dbClient.get(type);
      return doc.info;
    } catch (error: any) {
      console.log(error);
      let response: any = null;
      if (type === 'pie_data')
        response = await services.getUserDefaultChannelGroupData(userInfo.email);
      if (type === 'landing_page_data')
        response = await services.getLandingPageData(userInfo.email);
      if (type === 'google_console_data') {
        try {
          response = await services.getGoogleConsoleData(userInfo.email);
        } catch (error) {
          setGoogleConsoleError(error);
        }
      }

      if (type === 'monitization_data') {
        response = await services.getMonitizationOverviewData(userInfo.email, date);
      }

      dbClient.put({
        _id: type,
        info: response.ga4_data,
      });
      return response.ga4_data;
    }
  };

  const getLandingPageData = async () => {
    try {
      let res = await getCachedData('landing_page_data');
      let dataSetStorage: any = [];
      let processedLandingPageData = res.map((element: any) => {
        element.landingPagePlusQueryString = element.landingPagePlusQueryString.split('?')[0];

        let index = _.findIndex(dataSetStorage, (o: any) => {
          return o.key === element.landingPagePlusQueryString;
        });
        if (index < 0) {
          dataSetStorage.push({
            key: element.landingPagePlusQueryString,
            value: parseInt(element.sessions),
          });
        } else {
          dataSetStorage[index] = {
            key: element.landingPagePlusQueryString,
            value: dataSetStorage[index].value + parseInt(element.sessions),
          };
        }
        return element;
      });

      let labelArrKeys = _.orderBy(dataSetStorage, ['value'], 'desc').slice(0, 4);
      let lineObjArr: any = [];
      let result = await accumulator(
        processedLandingPageData,
        'landingPagePlusQueryString',
        'sessions',
      );

      labelArrKeys.forEach((element: any) => {
        lineObjArr.push(
          generateLineChartData(result, 'landingPagePlusQueryString', 'sessions', element.key),
        );
      });
      setLineData(lineObjArr);
      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject();
    }
  };

  const getUserEngagementData = async () => {
    try {
      let data = await getCachedData('pie_data');

      // Creating Data for Line chart
      let result = await accumulator(data, 'firstUserDefaultChannelGroup', 'newUsers');
      let defaultChannelGroupLine: any = [];
      let labelArr: any = ['Organic Search', 'Direct', 'Paid Search', 'Organic Social', 'Referral'];
      labelArr.forEach((element: string) => {
        defaultChannelGroupLine.push(
          generateLineChartData(result, 'firstUserDefaultChannelGroup', 'newUsers', element),
        );
      });

      setDefaultChannelGoupLineData(defaultChannelGroupLine);

      // Creating Data for Pie chart
      let pieChartDataSet: any = [];
      let pieChartKeyArr: string[] = [];
      _.filter(data, (content: any) => {
        let key = content.firstUserDefaultChannelGroup;
        if (pieChartKeyArr.includes(key)) {
          let matchedIndex = _.findIndex(pieChartDataSet, {
            firstUserDefaultChannelGroup: key,
          });
          let matchedData = pieChartDataSet[matchedIndex];
          matchedData.newUsers += parseInt(content.newUsers);
          matchedData.engagedSessions += parseInt(content.engagedSessions);
          matchedData.userEngagementDuration += parseInt(content.userEngagementDuration);
          matchedData.engagementRate += parseInt(content.engagementRate);
          pieChartDataSet[matchedIndex] = matchedData;
        } else {
          let customisedContent = {
            firstUserDefaultChannelGroup: content.firstUserDefaultChannelGroup,
            newUsers: parseInt(content.newUsers),
            engagedSessions: parseInt(content.engagedSessions),
            userEngagementDuration: parseInt(content.userEngagementDuration),
            engagementRate: parseInt(content.engagementRate),
          };
          pieChartKeyArr.push(key);
          pieChartDataSet.push(customisedContent);
        }
      });
      setPieData(pieChartDataSet);
      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject();
    }
  };

  const getGoogleConsoleData = async () => {
    let data = await getCachedData('google_console_data');
    let finalData: any = [];
    let calcObj: any = {};
    let googleConsoleKeys = ['Total Clicks', 'Total Impression', 'Average CTR', 'Average position'];

    const getValue = (c: any, e: any) => {
      switch (e) {
        case 'Total Clicks':
          return c.organicGoogleSearchClicks;
        case 'Total Impression':
          return c.organicGoogleSearchImpressions;
        case 'Average CTR':
          return c.organicGoogleSearchClickThroughRate;
        case 'Average position':
          return c.organicGoogleSearchAveragePosition;
        default:
          break;
      }
    };
    googleConsoleKeys.forEach((element: any) => {
      let total = 0;
      let modifiedData = data.map((content: any) => {
        let res = {
          date: content.day + '-' + content.month + '-' + content.year,
          key: element,
          value:
            element === 'Average CTR'
              ? (parseFloat(getValue(content, element)) * 100).toFixed(1)
              : parseInt(getValue(content, element)),
        };
        total += parseFloat(getValue(content, element));
        return res;
      });
      finalData.push(modifiedData);
      calcObj[element] = total;
    });

    setGoogleConsoleData(finalData);
    setGoogleConsoleTotalData(calcObj);
  };

  const CustomInput: any = () => {
    return (
      <span style={{ cursor: 'pointer' }}>
        <img src={calendarIcon} alt="calendar" className="calendar" onClick={handleClick} />
      </span>
    );
  };

  const handleClick = () => {
    setOpen((prev: boolean) => !prev);
  };

  const handleChange = (date: any) => {
    sessionStorage.setItem('selected_date', date);
    let currentMonth = moment(new Date()).month() + 1;
    let selectedDateMonth = moment(date).month() + 1;
    let processedDate =
      currentMonth === selectedDateMonth
        ? moment(new Date()).format('YYYY-MM-DD')
        : moment(date).endOf('month').format('YYYY-MM-DD');
    setDate(processedDate);
    getMonitizationOverviewDataByMonth(processedDate);
    setOpen(false);
  };

  const getMonitizationOverviewDataByMonth = async (endDate: string) => {
    let response = await services.getMonitizationOverviewData(userInfo.email, endDate);
    getDB()
      .get('monitization_data')
      .then((doc: any) => {
        return getDB().put({
          _id: 'monitization_data',
          _rev: doc._rev,
          info: response.ga4_data,
        });
      })
      .then(() => {
        // handle response
        setDataUpdated(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (dataUpdated === true) getMonitizationOverviewData();
    // eslint-disable-next-line
  }, [dataUpdated]);

  const getMonitizationOverviewData = async () => {
    let data = await getCachedData('monitization_data');
    let currMonthArr: any = [];
    let prevMonthArr: any = [];

    let currMonth = moment(date).month() + 1;
    let prevMonth = moment(date).subtract(1, 'month').month() + 1;
    let days = [];

    for (let index = 1; index <= daysCount(); index++) {
      let currMonthobj = {
        averagePurchaseRevenuePerUser: 0,
        day: index,
        month: currMonth,
        totalPurchasers: 0,
        totalRevenue: 0,
      };

      let prevMonthobj = {
        averagePurchaseRevenuePerUser: 0,
        day: index,
        month: prevMonth,
        totalPurchasers: 0,
        totalRevenue: 0,
      };
      prevMonthArr.push(prevMonthobj);
      currMonthArr.push(currMonthobj);
      days.push(index);
    }

    const getModifiedObject = (e: any) => {
      return {
        averagePurchaseRevenuePerUser: parseFloat(e.averagePurchaseRevenuePerUser).toFixed(2),
        day: parseInt(e.day),
        month: parseInt(e.month),
        totalPurchasers: parseInt(e.totalPurchasers),
        totalRevenue: parseFloat(e.totalRevenue),
      };
    };

    data.forEach((element: any) => {
      if (parseInt(element.month) === currMonth) {
        currMonthArr[parseInt(element.day)] = getModifiedObject(element);
      }
      if (parseInt(element.month) === prevMonth) {
        prevMonthArr[parseInt(element.day)] = getModifiedObject(element);
      }
    });

    const getAccumulatedData = (type: any, floatV: boolean) => {
      return (
        prevMonthArr.reduce(
          (accumulator: number, currentValue: any) =>
            accumulator + (floatV ? parseFloat(currentValue[type]) : parseInt(currentValue[type])),
          0,
        ) +
        currMonthArr.reduce(
          (accumulator: number, currentValue: any) =>
            accumulator + (floatV ? parseFloat(currentValue[type]) : parseInt(currentValue[type])),
          0,
        )
      );
    };

    let monitizeObj = {
      prevMonthData: prevMonthArr,
      currentMonthData: currMonthArr,
      dates: days,
      months: [prevMonth, currMonth],
      totalRevenue: getAccumulatedData('totalRevenue', true),
      averagePurchaseRevenuePerUser: getAccumulatedData('averagePurchaseRevenuePerUser', true),
      totalPurchasers: getAccumulatedData('totalPurchasers', false),
    };
    setMonitizationData(monitizeObj);
    setDataUpdated(false);
  };

  const generateLineGraph = (data: any, key: string, accumulatorKey: string) => {
    const result = {
      labels: data[0].map((item: any) => moment(item.date, 'DD-MM-YYYY').format('D MMM')),
      datasets: data.map((content: any, index: any) => ({
        label: content[0][key],
        data: content.map((item: any) => item[accumulatorKey]),
        backgroundColor: LINE_GRAPH_COLOR[index],
        borderColor: LINE_GRAPH_COLOR[index],
      })),
    };
    return result;
  };

  const getNewUserByDefaultChannelGoup = () => {
    return generateLineGraph(
      defaultChannelGoupLineData,
      'firstUserDefaultChannelGroup',
      'newUsers',
    );
  };

  const getSessionsByLandingPage = () => {
    return generateLineGraph(lineData, 'landingPagePlusQueryString', 'sessions');
  };

  const getInfoByGoogleConsole = () => {
    return generateLineGraph(googleConsoleData, 'key', 'value');
  };

  const getPieChartInfo = (type: string) => {
    let dataSet: any = [];
    let labelSet: any = [];
    let key = '';
    switch (type) {
      case 'New Users':
        key = 'newUsers';
        break;
      case 'Engaged Sessions':
        key = 'engagedSessions';
        break;
      case 'Engagement Time':
        key = 'userEngagementDuration';
        break;
      case 'Engagement Rate':
        key = 'engagementRate';
        break;
      default:
        break;
    }

    pieData.forEach((element: any) => {
      dataSet.push(element[key]);
      labelSet.push(element.firstUserDefaultChannelGroup);
    });

    return {
      labels: labelSet,
      datasets: [
        {
          label: type,
          data: dataSet,
          backgroundColor: PIE_CHART_COLORS,
          borderColor: PIE_CHART_COLORS,
          borderWidth: 1,
        },
      ],
    };
  };

  const getBarData = () => {
    return {
      labels: pieData.map((item: any) => item.firstUserDefaultChannelGroup),
      datasets: [
        {
          label: 'User Default Channel Group',
          data: pieData.map((item: any) => item.newUsers),
          backgroundColor: LINE_GRAPH_COLOR,
        },
      ],
    };
  };

  const getMonitazationData = (type: string, title: string) => {
    console.log(monitizationData);
    return {
      labels: monitizationData.dates.map((item: any) => item),
      datasets: [
        {
          label: monthNames[monitizationData.months[0] - 1] + ' ' + title,
          data: monitizationData.prevMonthData.map((item: any) => item[type]),
          borderColor: '#5fa5f2',
          backgroundColor: '#5fa5f2',
        },
        {
          label: monthNames[monitizationData.months[1] - 1] + ' ' + title,
          data: monitizationData.currentMonthData.map((item: any) => item[type]),
          borderColor: '#f33c3f',
          backgroundColor: '#f33c3f',
        },
      ],
    };
  };

  const getComparisonValue = (type: string) => {
    let prevMonthValue = monitizationData.prevMonthData.reduce((accumulator: number, item: any) => {
      accumulator += parseFloat(item[type]);
      return accumulator;
    }, 0);

    let currMonthValue = monitizationData.currentMonthData.reduce(
      (accumulator: number, item: any) => {
        accumulator += parseFloat(item[type]);
        return accumulator;
      },
      0,
    );

    let res = ((currMonthValue - prevMonthValue) / prevMonthValue) * 100;

    if (Number.isNaN(res)) return '';

    let modifiedRes =
      res < 0 ? (
        <>
          <ArrowDownwardIcon fontSize="small" style={{ paddingTop: '3px', color: 'red' }} />
          <span style={{ color: 'red' }}>{Math.abs(res).toFixed(2)}%</span>
        </>
      ) : (
        <>
          <ArrowUpwardIcon fontSize="small" style={{ paddingTop: '3px', color: 'green' }} />
          <span style={{ color: 'green' }}>{Math.abs(res).toFixed(2)}%</span>
        </>
      );

    return modifiedRes;
  };

  const getCurrentMonthTotal = (type: string) => {
    let total = monitizationData?.currentMonthData?.reduce(
      (accumulator: number, currentValue: any) => {
        return (accumulator += parseFloat(currentValue[type]));
      },
      0.0,
    );
    return total;
  };

  const getDashboardData = async () => {
    await Promise.any([
      getUserEngagementData(),
      getLandingPageData(),
      getGoogleConsoleData(),
      getMonitizationOverviewData(),
    ]);
  };

  useEffect(() => {
    if (userEmail) {
      getDashboardData();
    }
    // eslint-disable-next-line
  }, [userEmail]);

  return (
    <ContentWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography className={classes.typographyTitle}>Analytics</Typography>
        </div>
        {userInfo?.company?.is_ecommerce === true && (
          <>
            <div className="admin__table_header_wrapper">
              <Typography
                className={classes.typographyDescription}
                style={{ marginLeft: '10px', padding: '10px', display: 'flex' }}>
                <DatePicker
                  className="date_picker"
                  open={open}
                  selected={new Date(date)}
                  onChange={date => handleChange(date)}
                  customInput={<CustomInput />}
                  maxDate={new Date()}
                  showMonthYearPicker
                  popperProps={{
                    disablePortal: false,
                  }}
                  onClickOutside={() => setOpen(false)}
                />
                <span
                  style={{
                    color: '#089ac5',
                    marginRight: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={handleClick}>
                  {moment(date).format('MMMM') + ', ' + moment(date).format('YYYY')}{' '}
                </span>
                | compare to previous month
              </Typography>
            </div>
            <div className="bar_card_wrapper" style={{ marginBottom: '30px' }}>
              <div className="bar_card">
                {monitizationData && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '350px',
                    }}>
                    <div className="line_header_wrapper">
                      <div className="line_header_wrapper_child">
                        <div>
                          <Typography className="line_header_text">Total Reveneu</Typography>
                          <Typography>
                            ${getCurrentMonthTotal('totalRevenue').toFixed(2)}
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>{getComparisonValue('totalRevenue')}</div>
                      </div>
                    </div>
                    <div id="tooltip"></div>
                    <Bar
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom' as const,
                            labels: {
                              boxWidth: 10,
                              boxHeight: 10,
                            },
                          },
                          datalabels: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                      data={getMonitazationData('totalRevenue', 'Sales Overtime')}
                    />
                  </div>
                )}
              </div>

              <div className="bar_card">
                {monitizationData && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '350px',
                    }}>
                    <div className="line_header_wrapper">
                      <div className="line_header_wrapper_child">
                        <div>
                          <Typography className="line_header_text">Average order value</Typography>
                          <Typography>
                            ${getCurrentMonthTotal('averagePurchaseRevenuePerUser').toFixed(2)}
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {getComparisonValue('averagePurchaseRevenuePerUser')}
                        </div>
                      </div>
                    </div>

                    <Bar
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom' as const,
                            labels: {
                              boxWidth: 10,
                              boxHeight: 10,
                            },
                          },
                          datalabels: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                      data={getMonitazationData(
                        'averagePurchaseRevenuePerUser',
                        'Average order value',
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="bar_card">
                {monitizationData && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '350px',
                    }}>
                    <div className="line_header_wrapper">
                      <div className="line_header_wrapper_child">
                        <div>
                          <Typography className="line_header_text">Total Orders</Typography>
                          <Typography>{getCurrentMonthTotal('totalPurchasers')}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {getComparisonValue('totalPurchasers')}
                        </div>
                      </div>
                    </div>

                    <Bar
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            ticks: { stepSize: 0.5 },
                            grid: {
                              display: false,
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom' as const,
                            labels: {
                              boxWidth: 10,
                              boxHeight: 10,
                            },
                          },
                          datalabels: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler,
                          },
                        },
                      }}
                      data={getMonitazationData('totalPurchasers', 'Total Orders')}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <Typography className={classes.typographyDescription} style={{ fontSize: '15px' }}>
          Data from the last 30 days
        </Typography>
        <div className="line_card_wrapper">
          <div className="line_card_gc">
            {googleConsoleTotalData && (
              <div className="gc">
                <div className="line_card_gc_total" style={{ background: LINE_GRAPH_COLOR[0] }}>
                  <Typography>Total Clicks</Typography>
                  <Typography style={{ fontSize: '35px' }}>
                    {googleConsoleTotalData['Total Clicks']}
                  </Typography>
                </div>
                <div className="line_card_gc_total" style={{ background: LINE_GRAPH_COLOR[1] }}>
                  <Typography>Total Impression</Typography>
                  <Typography style={{ fontSize: '35px' }}>
                    {kFormatter(googleConsoleTotalData['Total Impression'])}
                  </Typography>
                </div>
                <div className="line_card_gc_total" style={{ background: LINE_GRAPH_COLOR[2] }}>
                  <Typography>Average CTR</Typography>
                  <Typography style={{ fontSize: '35px' }}>
                    {(
                      (googleConsoleTotalData['Average CTR'] / googleConsoleData[0].length) *
                      100
                    ).toFixed(1)}
                    %
                  </Typography>
                </div>
                <div className="line_card_gc_total" style={{ background: LINE_GRAPH_COLOR[3] }}>
                  <Typography>Average Position</Typography>
                  <Typography style={{ fontSize: '35px' }}>
                    {(
                      googleConsoleTotalData['Average position'] / googleConsoleData[0].length
                    ).toFixed(2)}
                  </Typography>
                </div>
              </div>
            )}
            {googleConsoleData &&
            (googleConsoleData[0].length > 0 ||
              googleConsoleData[1].length > 0 ||
              googleConsoleData[2].length > 0 ||
              googleConsoleData[3].length > 0) ? (
              <>
                <Line
                  data={getInfoByGoogleConsole()}
                  style={{
                    maxHeight: '380px',
                    maxWidth: '100%',
                    paddingLeft: '10px',
                    display: 'flex',
                  }}
                  options={LINE_CHART_CONFIG}
                />
              </>
            ) : googleConsoleError ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}>
                <Typography className={classes.typographyDescription}>
                  No connected data available...
                </Typography>
              </div>
            ) : (
              <CircularProgress style={{ display: 'flex', margin: 'auto', color: '#e7e7e8' }} />
            )}
          </div>
        </div>
        <Typography
          className={classes.typographyDescription}
          style={{ fontSize: '15px', marginTop: '30px' }}>
          Data from the last 30 days
        </Typography>
        <div className="line_card_wrapper">
          <div className="line_card">
            {lineData && lineData.length > 0 ? (
              <>
                <div className="line_header_wrapper">
                  <Typography className="line_header_text">
                    Sessions by Landing page over time
                  </Typography>
                </div>
                <Line
                  data={getSessionsByLandingPage()}
                  style={{
                    maxHeight: '380px',
                    maxWidth: '100%',
                    paddingLeft: '10px',
                    display: 'flex',
                  }}
                  options={LINE_CHART_CONFIG}
                />
              </>
            ) : (
              <CircularProgress style={{ display: 'flex', margin: 'auto', color: '#e7e7e8' }} />
            )}
          </div>
        </div>
        <Typography
          className={classes.typographyDescription}
          style={{ marginTop: '30px', fontSize: '15px' }}>
          Data from the last 30 days
        </Typography>
        <div className="bar_card_wrapper">
          <div className="bar_card">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginRight: '20px',
                }}>
                {defaultChannelGoupLineData && defaultChannelGoupLineData.length > 0 && (
                  <>
                    <div className="line_header_wrapper">
                      <Typography className="line_header_text">
                        New Users by first user default channel group over time
                      </Typography>
                    </div>
                    <Line
                      data={getNewUserByDefaultChannelGoup()}
                      style={{
                        maxHeight: '380px',
                        maxWidth: '100%',
                        paddingLeft: '10px',
                        display: 'flex',
                      }}
                      options={LINE_CHART_CONFIG}
                    />
                  </>
                )}
              </div>
              {pieData && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="line_header_wrapper">
                    <Typography className="line_header_text">
                      New Users by first user default channel group
                    </Typography>
                  </div>
                  <div style={{ maxHeight: '350px', maxWidth: '600px' }}>
                    <Bar
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        indexAxis: 'y' as const,
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                            position: 'right' as const,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                      data={getBarData()}
                      style={{ height: 400 }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Typography
          className={classes.typographyDescription}
          style={{ marginTop: '30px', fontSize: '15px' }}>
          Data from the last 30 days
        </Typography>
        <div className="pie_card_wrapper">
          <div className="pie_card">
            {pieData ? (
              <>
                <div className="pie_header_wrapper">
                  <Typography className="pie_header_text">User Acquisition</Typography>
                  <Typography className="pie_header_value">
                    New Users{' '}
                    {pieData.reduce((accumulator: number, item: any) => {
                      return accumulator + item.newUsers;
                    }, 0)}
                  </Typography>
                </div>
                <div style={{ height: '275px' }}>
                  <Pie
                    data={getPieChartInfo('New Users')}
                    options={{
                      plugins: {
                        legend: {
                          position: 'left',
                          labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </>
            ) : (
              <DummyDiv />
            )}
          </div>

          <div className="pie_card">
            {pieData ? (
              <>
                <div className="pie_header_wrapper">
                  <Typography className="pie_header_text">Engaged Session</Typography>
                  <Typography className="pie_header_value">
                    Total{' '}
                    {pieData.reduce((accumulator: number, item: any) => {
                      return accumulator + item.engagedSessions;
                    }, 0)}
                  </Typography>
                </div>
                <div style={{ height: '275px' }}>
                  <Pie
                    data={getPieChartInfo('Engaged Sessions')}
                    options={{
                      plugins: {
                        legend: {
                          position: 'left',
                          labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </>
            ) : (
              <DummyDiv />
            )}
          </div>
          <div className="pie_card">
            {pieData ? (
              <>
                <div className="pie_header_wrapper">
                  <Typography className="pie_header_text">Average Engagement Time</Typography>
                  <Typography className="pie_header_value">
                    Total{' '}
                    {shortEnglishHumanizer(
                      pieData.reduce((accumulator: number, item: any) => {
                        return accumulator + item.userEngagementDuration;
                      }, 0),
                      { maxDecimalPoints: 2 },
                    ).replace(' ', '')}
                  </Typography>
                </div>
                <div style={{ height: '275px' }}>
                  <Pie
                    data={getPieChartInfo('Engagement Time')}
                    options={{
                      plugins: {
                        legend: {
                          position: 'left',
                          labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              let label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed !== null) {
                                label += shortEnglishHumanizer(context.parsed, {
                                  maxDecimalPoints: 2,
                                }).replace(' ', '');
                              }
                              return label;
                            },
                          },
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </>
            ) : (
              <DummyDiv />
            )}
          </div>
          <div className="pie_card">
            {pieData ? (
              <>
                <div className="pie_header_wrapper">
                  <Typography className="pie_header_text">Engagement Rate</Typography>
                  <Typography className="pie_header_value">
                    Total{' '}
                    {pieData.reduce((accumulator: number, item: any) => {
                      return accumulator + item.engagementRate;
                    }, 0)}
                    %
                  </Typography>
                </div>
                <div style={{ height: '275px' }}>
                  <Pie
                    data={getPieChartInfo('Engagement Rate')}
                    options={{
                      plugins: {
                        legend: {
                          position: 'left',
                          labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              let label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed !== null) {
                                label += context.parsed + '%';
                              }
                              return label;
                            },
                          },
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </>
            ) : (
              <DummyDiv />
            )}
          </div>
          <div className="pie_card">
            <div className="pie_header_wrapper" style={{ opacity: 0.3 }}>
              <Typography className="pie_header_text">Event Tracking</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '275px',
                width: '100%',
              }}>
              <div className="image-container">
                <img
                  alt="demo"
                  src={demoImg}
                  height={275}
                  style={{ display: 'flex', opacity: 0.2, maxWidth: '100%' }}></img>
                <div>
                  <Button primary>Activate</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="admin__table_header_wrapper">
          <Typography
            style={{
              marginLeft: '10px',
              padding: '10px',
              display: 'flex',
              fontSize: '20px',
              color: '#FF6464',
              fontStyle: 'italic',
              fontWeight: 700,
            }}>
            Do you need to increase sales? Let us show you how,{' '}
            <span
              style={{
                color: '#00A4CB',
              }}>
              Click{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://rankworks.com/get-in-touch/"
                style={{
                  color: '#00A4CB',
                }}>
                Here
              </a>
            </span>
          </Typography>
        </div>
      </div>
    </ContentWrapper>
  );
}
