import { useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/content-wrapper';
import { CardGMBProfileViews } from '../../components/graph/ui/card-gmb-profile-views/card-gmb-profile-views';
import { CardGMBCallsMade } from '../../components/graph/ui/card-gmb-calls-made/card-gmb-calls-made';
import { GraphGMBPlatformAndDevicesUsed } from '../../components/graph/ui/graph-gmb-platform-and-devices-used/graph-gmb-platform-and-devices-used';
import { CardGMBReviews } from '../../components/graph/ui/card-gmb-reviews/card-gmb-reviews';
import { GraphGMBSearchesShowed } from '../../components/graph/ui/graph-gmb-searches-showed/graph-gmb-searches-showed';
import { CardGMBMessagesSent } from '../../components/graph/ui/card-gmb-messages-sent/card-gmb-messages-sent';
import { CardGMBDirectionsRequest } from '../../components/graph/ui/card-gmb-directions-request/card-gmb-directions-request';
import { GraphGMBUsersOverTime } from '../../components/graph/ui/graph-gmb-users-over-time/graph-gmb-users-over-time';
import { CardGMBWebsiteClicks } from '../../components/graph/ui/card-gmb-website-clicks/card-gmb-website-clicks';
import { HR } from '../../components/hr';
import { TextBlock } from '../../components/text-block';
import { PageHeader } from '../../components/page-header';
import { DashboardGrid } from '../../components/dashboard-grid';
import cls from './Admin.module.scss';
import { CardAdminTotalRevenue } from '../../components/graph/ui/card-admin-total-revenue/card-admin-total-revenue';
import { CardGA4ActiveUsers } from '../../components/graph/ui/card-ga4-active-users/card-ga4-active-users';
import { CardGA4NewUsers } from '../../components/graph/ui/card-ga4-new-users/card-ga4-new-users';
import { GraphGA4NewUsersByDate } from '../../components/graph/ui/graph-ga4-new-users-by-date/graph-ga4-new-users-by-date';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../store/ducks/inSiteAppState';
import { CardGA4UserSessions } from '../../components/graph/ui/card-ga4-user-sessions/card-ga4-user-sessions';
import { GraphGA4UserSessionsByDate } from '../../components/graph/ui/graph-ga4-user-sessions-by-date/graph-ga4-user-sessions-by-date';
import { CardAdminTotalUser } from '../../components/graph/ui/card-admin-total-user/card-admin-total-user';
import { CardAdminNewUsers } from '../../components/graph/ui/card-admin-new-users/card-admin-new-users';
import { GraphAdminNewUsersByDate } from '../../components/graph/ui/graph-admin-new-users-by-date/graph-admin-new-users-by-date';
import { CardAdminTotalMRR } from '../../components/graph/ui/card-admin-total-mrr/card-admin-total-mrr';
import { GraphAdminRevenueByProduct } from '../../components/graph/ui/graph-admin-revenue-by-product/graph-admin-revenue-by-product';

export default function Admin() {
  const userInfo = useSelector(getUserInfo);

  return (
    <ContentWrapper>
      <PageHeader>Dashboard</PageHeader>
      <HR />
      {/* <div>
        <h1>Kafka Messages - {userInfo.email}</h1>
        <ul>
          {messages.map((message: any, index: any) => (
            <li key={index}>{JSON.stringify(message)}</li>
          ))}
        </ul>
      </div> */}
      <div className={cls.wrapper}>
        <TextBlock header="Here's your last 30 days activity summary." />
        {/* {userInfo?.company && ( */}
        <DashboardGrid>
          <CardAdminTotalUser />
          <CardAdminNewUsers />
          <GraphAdminRevenueByProduct large />
          <CardGA4UserSessions />
          <CardAdminTotalRevenue />
          <GraphAdminNewUsersByDate large />
          <CardAdminTotalRevenue />
          <CardAdminTotalMRR />
          <GraphGA4UserSessionsByDate large />
        </DashboardGrid>
        {/* )} */}
      </div>
    </ContentWrapper>
  );
}
