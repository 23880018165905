import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { useAuthContext } from '../../../hooks/useAuthContext/useAuthContext';
import {
  getNotifications,
  getUserInfo,
  setUserInfoAction,
  setUserNotificationsAction,
  resetUserNotificationAction,
} from '../../../store/ducks/inSiteAppState';
import { classNames } from '../../../utils/class-names';
import { AppRoute } from '../../../router/model/type/app-route'; // if use public api - storybook not work with AppRoute
import io from 'socket.io-client';
import { ReactComponent as IcnNotification } from '../../../assets/icons/icn-notification.svg';
import { ReactComponent as IcnProfile } from '../../../assets/icons/icn-profile.svg';
import cls from './app-bar.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import services from '../../../services';
import { Avatar } from '@mui/material';

const AppBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  // const [messages, setMessages] = useState<any>([]);
  const [socket, setSocket] = useState<any>(null);
  const dispatch = useDispatch();
  const messages: any = useSelector(getNotifications);
  const [isNofificationPanelopen, setIsNofificationPanelopen] = useState(false);
  const [isNofificationDotInvisible, setIsNofificationDotInvisible] = useState(true);
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const userInfo: any = useSelector(getUserInfo);
  const username: string = userInfo?.name ? userInfo.name.first_name + ' ' + userInfo.name.last_name : '';

  const handleSignOut = () => {
    signOut();
  };

  const redirectToAccountProfile = () => navigate(AppRoute.ACCOUNT_PROFILE);
  const redirectToBusinessProfile = () => navigate(AppRoute.BUSINESS_PROFILE);

  const handleMenuToggle = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const handleNotificationPanelToggle = useCallback(() => {
    setIsNofificationPanelopen(!isNofificationPanelopen);
    setIsNofificationDotInvisible(true);
  }, [isNofificationPanelopen]);

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('click', handleMenuToggle);
    } else {
      document.removeEventListener('click', handleMenuToggle);
    }
    return () => {
      document.removeEventListener('click', handleMenuToggle);
    };
  }, [isMenuOpen, handleMenuToggle]);

  useEffect(() => {
    if (isNofificationPanelopen) {
      document.addEventListener('click', handleNotificationPanelToggle);
      if (userInfo?.notifications && userInfo?.notifications?.bell_amount !== 0) {
        restBellAmount();
      }
    } else {
      document.removeEventListener('click', handleNotificationPanelToggle);
    }
    return () => {
      document.removeEventListener('click', handleNotificationPanelToggle);
    };
  }, [isNofificationPanelopen, handleNotificationPanelToggle]);

  useEffect(() => {
    const s = io('wss://m.rankworks.com');
    //const s = io('http://localhost:8080');
    s.on('connect', () => {
      console.log('Connected to server');
    });

    s.on('message', (message: any) => {
      console.log('Message from server:', message);
      dispatch(setUserNotificationsAction(message));
      //setMessages((prevMessages: any) => [...prevMessages, message]);
      setIsNofificationDotInvisible(false);
    });

    s.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(s);
  }, []);

  useEffect(() => {
    if (userInfo?.email && socket) {
      socket.emit('register', userInfo.email);

      if (userInfo?.notifications && userInfo?.notifications?.messages.length !== 0) {
        userInfo?.notifications?.messages.forEach((element: any) => {
          dispatch(setUserNotificationsAction(element));
        });
        if (userInfo?.notifications?.bell_amount !== 0) setIsNofificationDotInvisible(false);
      }
    }
  }, [userInfo, socket]);

  const handleRedirect = () => {
    navigate(AppRoute.GET_HELP);
  };

  const restBellAmount = async () => {
    await services.resetBellAmount();
    dispatch(resetUserNotificationAction());
  };

  const formatTimestampToDateString = (utcTimestamp: string) => {
    // Convert the timestamp to milliseconds
    const date = new Date(parseFloat(utcTimestamp) * 1000);
    // Options for formatting the date
    const options: any = { day: 'numeric', month: 'short' };
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div>
        <button className={classNames(cls.iconButton, cls.withMenu)} onClick={handleNotificationPanelToggle}>
          <Badge color="secondary" variant="dot" invisible={isNofificationDotInvisible}>
            <IcnNotification />
          </Badge>
          {isNofificationPanelopen && (
            <div className={cls.notificationPanel}>
              <div className={cls.panelHeader}>
                <CloseIcon className={cls.closeIcon} onClick={handleNotificationPanelToggle} />{' '}
                {/* Add the close icon */}
              </div>
              {messages.slice(0, 10).map((message: any, index: any) => (
                <div className={cls.parent} key={index}>
                  <div className={cls.menuItem} onClick={() => handleRedirect()}>
                    You have an update on {JSON.stringify(message?.ticket_id || message?.email)}
                  </div>
                  <div className={cls.timestamp}>
                    <span>{formatTimestampToDateString(message?.utc)}</span>
                  </div>
                </div>
              ))}
              {messages.length === 0 && <p>No Notification availabe at this momement</p>}
            </div>
          )}
        </button>

        <button className={classNames(cls.iconButton, cls.withMenu)} onClick={handleMenuToggle}>
          <IcnProfile />
          {isMenuOpen && (
            <div className={cls.menu}>
              <div className={cls.singleUser}>
                <div className={cls.profileSection}>
                  <Avatar alt="Profile picture" sx={{ width: 75, height: 75 }} src={userInfo?.avatar_url}></Avatar>
                  <div className={cls.profileName}>{username}</div>
                  <div className={cls.profileEmail}>{userInfo?.email}</div>
                </div>
                <div className={cls.menuSection}>
                  <div className={cls.menuWrap}>
                    <div className={cls.menuIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path
                          d="M1 10.4336C1 8.46712 2.64286 6.87302 6 6.87302C9.35714 6.87302 11 8.46712 11 10.4336C11 10.7464 10.7718 11 10.4902 11H1.5098C1.22825 11 1 10.7464 1 10.4336Z"
                          stroke="#001955"
                        />
                        <path
                          d="M7.875 2.875C7.875 3.91053 7.03553 4.75 6 4.75C4.96447 4.75 4.125 3.91053 4.125 2.875C4.125 1.83947 4.96447 1 6 1C7.03553 1 7.875 1.83947 7.875 2.875Z"
                          stroke="#001955"
                        />
                      </svg>
                    </div>
                    <div className={cls.menuItem} onClick={redirectToAccountProfile}>
                      Profile
                    </div>
                  </div>
                  <div className={cls.menuWrap}>
                    <div className={cls.menuIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path
                          d="M4.33333 11V7.19602C4.33333 6.84587 4.63181 6.56202 5 6.56202H7C7.36819 6.56202 7.66667 6.84587 7.66667 7.19602V11M5.61365 1.11732L1.28032 4.04779C1.10445 4.16672 1 4.35925 1 4.56447V10.049C1 10.5742 1.44772 11 2 11H10C10.5523 11 11 10.5742 11 10.049V4.56447C11 4.35925 10.8955 4.16673 10.7197 4.04779L6.38635 1.11732C6.15504 0.960894 5.84496 0.960894 5.61365 1.11732Z"
                          stroke="#001955"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <div className={cls.menuItem} onClick={redirectToBusinessProfile}>
                      Account
                    </div>
                  </div>
                </div>
                <div className={cls.logOutSection} onClick={handleSignOut}>
                  Sign out
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

export { AppBar };
