import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import services from '../../../../services';
import { PlanWrapper } from './plan/plan-wrapper';
import { Plan } from './plan/plan';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { modifiedPlanList } from '../../../../utils';
import { ReactComponent as DiamondIcon } from './diamond.svg';
import DiamondPng from './diamond.png';

interface Props {
  className?: string;

  setData: (data: object) => void;
  setNextStep: () => void;
}

const Plans = memo((props: Props) => {
  const { className, setData, setNextStep } = props;

  const [newSubscriber, setNewSubscriber] = useState(false);
  const [currentPrice, setCurrentPrice] = useState<any>(null);
  const [planList, setPlanList] = useState([]);
  const userInfo: any = useSelector(getUserInfo);

  const handlePlanSelect = (value: any) => {
    const data = {
      planInfo: value,
      priceInfo: currentPrice,
      userInfo: userInfo,
      planType: 'gmb',
    };
    setData(data);
    setNextStep();
  };

  const handlePriceSelect = (planName: string, value: any) => {
    setCurrentPrice((prevPrice: any) => ({
      ...prevPrice,
      [planName]: value,
    }));
  };

  const getPlans = async () => {
    try {
      const response = await services.getAllStripePlan('website');
      const [modifiedList, isNewSubscriber, initialPriceObject] = modifiedPlanList(response);
      setPlanList(modifiedList);
      setCurrentPrice(initialPriceObject);
      setNewSubscriber(isNewSubscriber);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <PlanWrapper>
      {planList &&
        currentPrice &&
        planList.map((planStructure: any) => (
          <Plan
            key={planStructure.id}
            isNewSubscriber={newSubscriber}
            isSubscribed={planStructure.isSubscribed}
            isUpgradable={planStructure.isUpgradable}
            currentPlan={'web'}
            onPlanSelect={handlePlanSelect}
            onPriceSelect={handlePriceSelect}
            planStructure={planStructure}
            currentPrice={currentPrice}
          />
        ))}
      <Plan
        isStatic={true}
        key={'static-plan'}
        isNewSubscriber={newSubscriber}
        isSubscribed={false}
        isUpgradable={true}
        currentPlan={'web'}
        onPlanSelect={handlePlanSelect}
        onPriceSelect={handlePriceSelect}
        planStructure={{
          Icon: DiamondIcon,
          title: 'Advanced',
          prices: [],
          images: [DiamondPng],
          description: 'High competition, local and national campaigns.',
          features: [
            'Advanced customization in selection',
            'Premium integrations',
            'Free redesign every 24 months',
          ],
        }}
        currentPrice={{ Advanced: { recurring: { interval: 'month', amount: 0 } } }}
      />
    </PlanWrapper>
  );
});

export { Plans };
