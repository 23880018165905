import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useEffect, useState } from 'react';
import services from '../../../../services';
import { useSelector } from 'react-redux';
import {
  getFacebookTotalFollowers,
  setFacebookTotalFollowersAction,
} from '../../../../store/ducks/socialAnalyticsState';
import { useDispatch } from 'react-redux';
import { formatSocialValue } from '../../../../utils/format-social-value';

const CardFacebookTotalFollowers = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const totalFollowers = useSelector(getFacebookTotalFollowers);
  const [isLoading, setLoading] = useState(false);

  const getTotalFacebookFollowers = async () => {
    try {
      setLoading(true);
      const response = await services.getTotalFacebookInsights('followers_count');
      dispatch(setFacebookTotalFollowersAction(response.data.toString()));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (totalFollowers == '-') getTotalFacebookFollowers();
  }, []);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Total facebook followers"
      total={totalFollowers == '-' ? totalFollowers : formatSocialValue(Number(totalFollowers))}
    >
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>2%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardFacebookTotalFollowers };
