import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import { GraphProps } from '../../model/types/graph';
import { formatSocialValue } from '../../../../utils/format-social-value';

import cls from '../graph.module.scss';
import { Flex } from '../../../flex';
import { TextBlock } from '../../../text-block';

const GraphGA4TopSellingProducts = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  let itemsData: any[] = [];
  let dataDict: { [key: string]: number } = {};

  if (dataSet?.ga4_data) {
    dataSet.ga4_data.forEach((element: any) => {
      if (!element.itemsPurchased || element.itemsPurchased === '0') return;

      const itemName = element.itemName;
      const itemsPurchased = Number(element.itemsPurchased);

      if (dataDict[itemName]) {
        dataDict[itemName] += itemsPurchased;
      } else {
        dataDict[itemName] = itemsPurchased;
      }
    });

    itemsData = Object.keys(dataDict)
      .map(key => ({
        itemName: key,
        itemValue: dataDict[key],
      }))
      .sort((a, b) => b.itemValue - a.itemValue);
  }

  return (
    <GraphWrapper className={className} {...rest} header="Top selling products" total={''}>
      <div className={cls.textFlex} style={{ height: '100%', paddingTop: '20px' }}>
        {itemsData.length &&
          itemsData.slice(0, 10).map((item: any) => (
            <Flex spaceBetween>
              <TextBlock>{item.itemName}</TextBlock>
              <TextBlock className={cls.textRight}>{formatSocialValue(item.itemValue)}</TextBlock>
            </Flex>
          ))}
      </div>
    </GraphWrapper>
  );
};

export { GraphGA4TopSellingProducts };
