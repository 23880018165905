import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useState } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';

interface GraphProps1 extends GraphProps {
  dataSet1?: any;
}

const CardGA4AbandonedCarts = (props: GraphProps1) => {
  const { className, dataSet, dataSet1, ...rest } = props;
  const [isLoading, setLoading] = useState(false);

  const totalAddToCart = dataSet?.ga4_data?.reduce((acc: number, item: any) => {
    const totalUsers = Number(item?.totalUsers) || 0;
    return acc + totalUsers;
  }, 0);

  const totalPurchases = dataSet1?.ga4_data?.reduce((acc: number, item: any) => {
    const totalUsers = Number(item?.totalUsers) || 0;
    return acc + totalUsers;
  }, 0);

  const total = totalAddToCart - totalPurchases || '-';

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Abandoned carts"
      total={formatSocialValue(total)}>
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardGA4AbandonedCarts };
