import { Flex } from '../flex';
import cls from './plan.module.scss';

interface Props {
  children: React.ReactNode;
}

const PlanWrapper = (props: Props) => {
  const { children } = props;

  return (
    <>
      <h2 className={cls.planHeader}>Monthly plans</h2>
      <p className={cls.planHelp}>
        For any questions regarding pricing plans, dont hesitate to{' '}
        <a href="https://rankworks.com/design-services/">
          <b>contact us</b>
        </a>
        .
      </p>
      <Flex className={cls.planWrapper} left>
        {children}
      </Flex>
    </>
  );
};

export { PlanWrapper };
