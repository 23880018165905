import { useState } from 'react';
import { Line } from 'react-chartjs-2';

import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import { GraphProps } from '../../model/types/graph';

import { LINE_CHART_CONFIG } from '../../../../constants';

const GraphGA4SalesByDays = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  const [isLoading, setLoading] = useState(false);

  const generateLineGraph = () => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Accent-cold-Cool'),
      styles.getPropertyValue('--Accent-warm-Warm'),
    ];

    const returnedDataSet = {
      labels: [] as string[],
      datasets: [
        {
          label: 'Sales by days $',
          data: [] as number[],
          backgroundColor: [styles.getPropertyValue('--Primary-Primary-400')],
          borderColor: [styles.getPropertyValue('--Primary-Primary-400')],
          borderWidth: 1,
          pointBackgroundColor: pointColors,
          pointBorderColor: pointColors,
        },
      ],
    };

    if (!dataSet) return returnedDataSet;

    const dataArray = dataSet.ga4_data;

    if (!dataArray) {
      console.log('no data array');
      return returnedDataSet;
    }

    let dataDict: { [key: string]: number } = {};

    dataSet.ga4_data.forEach((element: any) => {
      const date = new Date(
        `${element.year}-${element.month.padStart(2, '0')}-${element.day.padStart(2, '0')}`,
      );
      const key =
        `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleString('en-US', {
          month: 'short',
        })}` || 'unknown-date';
      const value = element.totalRevenue || 0;

      if (dataDict[key]) {
        dataDict[key] += Number(value);
      } else {
        dataDict[key] = Number(value);
      }
    });

    let dates = Object.keys(dataDict);
    let values = Object.values(dataDict);

    returnedDataSet.labels = dates;
    returnedDataSet.datasets[0].data = values;

    return returnedDataSet;
  };

  return (
    <GraphWrapper className={className} {...rest} header="Sales by days $" total={''}>
      <Line
        data={generateLineGraph()}
        style={{
          maxHeight: '380px',
          maxWidth: '100%',
          paddingLeft: '10px',
          display: 'flex',
        }}
        options={LINE_CHART_CONFIG}
      />
    </GraphWrapper>
  );
};

export { GraphGA4SalesByDays };
