import { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../flex';
import { Section } from '../../../pages/get-help/ui/components/section';
import { Label } from '../../label';
import { TextField } from '../../text-field';
import cls from './payment.module.scss';
import { HR } from '../../hr';
import { Button } from '../../button';
import services from '../../../services';

export default function CheckoutForm(props: any) {
  const { userInfo, planType, paymentInfo } = props;
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements: any = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>(userInfo?.name?.first_name + ' ' + userInfo?.name?.last_name || '');
  const [businessName, setBusinessName] = useState<string>(userInfo?.company?.name || '');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>(userInfo?.company?.primary_service_area?.city || '');
  const [state, setState] = useState<string>(userInfo?.company?.primary_service_area?.state || '');

  const shouldCheckoutSubscriptionDisabled = !address || !businessName || !city || !state;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      setIsLoading(true);
      // Trigger form validation and wallet collection
      await elements.submit();

      const data = {
        price_id: props.priceInfo.id,
        plan_id: paymentInfo.id,
        customer_stripe_id: props.customerInfo.id,
        plan_type: planType,
      };

      const res = await services.createSubscription(data);

      const { type, clientSecret } = res.data;
      const confirmIntent: any = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      let paymentIntentResponse = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: { return_url: 'http://localhost:3000/payment/success' },
        redirect: 'if_required',
      });

      if (paymentIntentResponse && paymentIntentResponse?.paymentIntent?.status == 'succeeded') {
        const customerObj = {
          customer_id: props.customerInfo.id,
          payment_id: paymentIntentResponse.paymentIntent.payment_method,
          company_name: businessName,
          street_address: address,
          city: city,
          state: state,
        };
        const res = await services.stripeCustomerUpdate(customerObj);
        setIsLoading(false);
        if (res.status === 200) {
          navigate('/payment/success?redirect=' + planType);
        } else {
          navigate('/payment/error');
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const SubscribeSection = () => {
    return (
      <Section className={cls.review}>
        <h2 className={cls.header}>Review</h2>
        <HR />
        <h3 className={cls.title}>{props.paymentInfo.title}</h3>
        <p className={cls.payment}>
          <span className={cls.price}>${(props.priceInfo.unit_amount / 100).toFixed(2)}</span> /{' '}
          {props.priceInfo.recurring.interval}
        </p>
        <p className={cls.description}>{props.paymentInfo.description}</p>
        <ul className={cls.featureList}>
          {props.paymentInfo.features.map((feature: any, index: number) => (
            <li className={cls.feature} key={'feature__' + index}>
              {feature}
            </li>
          ))}
        </ul>
        <HR />
        <Flex className={cls.row}>
          <span>Sub-total</span>
          <span>${(props.priceInfo.unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TPS</span>
          <span>$ 0.00</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TVQ</span>
          <span>$ 0.00</span>
        </Flex>
        <HR />
        <Flex className={cls.row}>
          <span>Total with taxes</span>
          <span>${(props.priceInfo.unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.buttons} right>
          <Button primary onClick={handleSubmit} disabled={isLoading || shouldCheckoutSubscriptionDisabled}>
            Subscribe
          </Button>
        </Flex>
      </Section>
    );
  };

  return (
    <>
      <Section className={cls.billing}>
        <h2 className={cls.header}>Billing & Payment information</h2>
        <Label>
          Cardholder's full name
          <TextField value={name} onChange={(event: any) => setName(event.target.value)} />
        </Label>
        <Label>
          Business name *
          <TextField value={businessName} onChange={(event: any) => setBusinessName(event.target.value)} />
        </Label>
        <Label>
          Street Address *
          <TextField value={address} onChange={(event: any) => setAddress(event.target.value)} />
        </Label>
        <Flex>
          <Label>
            City *
            <TextField value={city} onChange={(event: any) => setCity(event.target.value)} />
          </Label>
          <Label>
            State / Province / Region *
            <TextField value={state} onChange={(event: any) => setState(event.target.value)} />
          </Label>
        </Flex>
        <PaymentElement
          id="payment-element"
          className={cls.paymentElement}
          options={{
            layout: 'tabs',
            terms: {
              card: 'never',
            },
          }}
        />
      </Section>
      <SubscribeSection />
    </>
  );
}
