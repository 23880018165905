import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import services from '../../../../services';
import { useSelector } from 'react-redux';
import {
  setDashboardActiveUsersDataByDeviceAction,
  getActiveUsersByDevice,
} from '../../../../store/ducks/dashboardState';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { Pie, Doughnut } from 'react-chartjs-2';

const GraphTrafficByDevice = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const users = useSelector(getActiveUsersByDevice);
  const userInfo = useSelector(getUserInfo);
  const [isLoading, setLoading] = useState(false);

  const getActiveUsers = async () => {
    try {
      setLoading(true);
      const response = await services.getGA4ActiveUsersByDevice(userInfo?.email || 'test@rankworks.com');
      dispatch(setDashboardActiveUsersDataByDeviceAction(response.ga4_data));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (users.length === 0) getActiveUsers();
  }, []);

  const getPieChartInfo = (type: string) => {
    let dataSet: any = [];
    let labelSet: any = [];
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const PIE_CHART_COLORS = [
      styles.getPropertyValue('--Accent-cold-Cool-600'),
      styles.getPropertyValue('--Accent-warm-Warm'),
      styles.getPropertyValue('--Secondary-Secondary'),
    ];

    users.forEach((element: any) => {
      dataSet.push(element['activeUsers']);
      labelSet.push(element.deviceCategory.toUpperCase());
    });

    return {
      labels: labelSet,
      datasets: [
        {
          label: type,
          data: dataSet,
          backgroundColor: PIE_CHART_COLORS,
          borderColor: PIE_CHART_COLORS,
          borderWidth: 1,
        },
      ],
    };
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetDraw(chart: any, args: any, pluginOptions: any) {
      let total = 0;
      const { ctx, data } = chart;
      data.datasets[0].data.forEach((element: any) => (total += parseInt(element, 10)));
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      if (data?.datasets[0]?.data.length !== 0)
        ctx.fillText(
          'Total: ' + total,
          chart.getDatasetMeta(0)?.data[0]?.x || '',
          chart.getDatasetMeta(0)?.data[0]?.y || ''
        );
    },
  };

  return (
    <GraphWrapper className={className} {...rest} header="TRAFFIC BY DEVICE" total={isLoading ? 'Loading...' : ''}>
      {/* <img className={cls.mockedGraph} src={mockedGraph} alt="Mock" /> */}
      <Doughnut
        data={getPieChartInfo('New Users')}
        plugins={[textCenter]}
        options={{
          plugins: {
            legend: {
              position: 'left',
              labels: {
                boxWidth: 20,
                boxHeight: 20,
              },
            },

            datalabels: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              right: 20,
              left: 10,
              bottom: 10,
            },
          },
        }}
      />
    </GraphWrapper>
  );
};

export { GraphTrafficByDevice };
