import { memo } from 'react';

import { Flex } from '../../../../components/flex';
import { ContactInformation } from '../contact-information/contact-information';
import { Address } from '../address/address';
import { GeneralInformation } from '../../model/types/general-information';

import cls from './additional-information.module.scss';
import { Section } from '../../../get-help/ui/components/section';
import { TextArea } from '../../../../components/text-area';
import { Label } from '../../../../components/label';
import { classNames } from '../../../../utils/class-names';

const MAX_LENGTH = 300;

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const AdditionalInformation = memo((props: Props) => {
  const { className, data, setData } = props;

  const handleFieldChange =
    (key: keyof GeneralInformation) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length > MAX_LENGTH) return;
      setData({ ...data, [key]: e.target.value });
    };

  return (
    <Flex style={{ alignItems: 'stretch' }}>
      <Section>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>Please describe your organization in a few sentences</span>
            <span className={cls.counter}>
              {data.description?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.description}
            onChange={handleFieldChange('description')}
          />
        </Label>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>What do you want a new website? What are your priorities?</span>
            <span className={cls.counter}>
              {data.wishes?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.wishes}
            onChange={handleFieldChange('wishes')}
          />
        </Label>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>Describe the desired look and feel using adjectives and short phrases</span>
            <span className={cls.counter}>
              {data.desired?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.desired}
            onChange={handleFieldChange('desired')}
          />
        </Label>
      </Section>
      <Section>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>Describe your target audience. What do you want your site to convey?</span>
            <span className={cls.counter}>
              {data.audience?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.audience}
            onChange={handleFieldChange('audience')}
          />
        </Label>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>Who are your direct competitors?</span>
            <span className={cls.counter}>
              {data.competitors?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.competitors}
            onChange={handleFieldChange('competitors')}
          />
        </Label>
        <Label>
          <span className={cls.labelWithCounter}>
            <span>Name some organizations that are similar to yours</span>
            <span className={cls.counter}>
              {data.similar?.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
            </span>
          </span>
          <TextArea
            className={cls.areaField}
            value={data.similar}
            onChange={handleFieldChange('similar')}
          />
        </Label>
      </Section>
    </Flex>
  );
});

export { AdditionalInformation };
