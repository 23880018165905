import { NavBar } from '../side-menu';
import { NavBarIc } from '../side-menu';
import { PageWrapper } from '../page-wrapper';
import cls from './content-wrapper.module.scss';
import { classNames } from '../../utils/class-names';
import { isMobile } from '../../utils/is-mobile';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../store/ducks/inSiteAppState';

interface Props {
  children: React.ReactNode;
  noPadding?: boolean;
  menuOpen?: boolean;
  setMenuOpen?: (isOpen: boolean) => void;
}

const ContentWrapper = ({ children, noPadding = false, menuOpen, setMenuOpen }: Props) => {
  const userInfo: any = useSelector(getUserInfo);
  const isShopifyUser = userInfo?.plugin === 'shopify' ? true : false;

  if (isMobile()) {
    return (
      <div className={cls.contentWrapper}>
        {!isShopifyUser && menuOpen && <NavBar setMenuOpen={setMenuOpen} />}
        {isShopifyUser && menuOpen && <NavBarIc setMenuOpen={setMenuOpen} />}
        {children}
      </div>
    );
  }

  return (
    <PageWrapper>
      <div className={cls.wrapper}>
        {!isShopifyUser ? <NavBar /> : <NavBarIc />}
        <div className={classNames(cls.contentWrapper, noPadding ? cls.noPadding : '')}>{children}</div>
      </div>
    </PageWrapper>
  );
};

export { ContentWrapper };
