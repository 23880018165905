import { useState } from 'react';

import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { formatSocialValue } from '../../../../utils/format-social-value';

const CardGA4NumbersOfFormCompletion = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  const [isLoading, setLoading] = useState(false);

  const total =
    dataSet?.ga4_data?.reduce((acc: number, item: any) => {
      const totalUsers = Number(item?.totalUsers) || 0;
      return acc + totalUsers;
    }, 0) || '-';

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Number of form completions"
      total={formatSocialValue(total)}>
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardGA4NumbersOfFormCompletion };
