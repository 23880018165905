import axios from 'axios';
import { getAxiosConfig } from '../utils';

const URL_GMB = process.env.REACT_APP_ENV_GMB_API_DOMAIN || '';

export const getProfileViews = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    // const response = await axios.request(getAxiosConfig(URL_GMB, '/', 'GET', headers, data));

    // return Promise.resolve(response?.data);
    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCallsMade = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/get_daily_metrics_time_series?metric=CALL_CLICKS&start_date=${dataStart}&end_date=${dataEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPlatformAndDevicesUsers = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/fetch_multi_daily_metrics_time_series?metrics_read_mask=BUSINESS_IMPRESSIONS_DESKTOP_MAPS,BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,BUSINESS_IMPRESSIONS_MOBILE_MAPS,BUSINESS_IMPRESSIONS_MOBILE_SEARCH&start_date=${dataStart}&end_date=${dataEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getWebsiteClicks = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/get_daily_metrics_time_series?metric=WEBSITE_CLICKS&start_date=${dataStart}&end_date=${dataEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getReviews = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    // const response = await axios.request(getAxiosConfig(URL_GMB, '/', 'GET', headers, data));

    // return Promise.resolve(response?.data);
    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSearchesShowed = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  const monthStart = dataStart.slice(0, -3);
  const monthEnd = dataEnd.slice(0, -3);

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/get_monthly_searchkeywords_impressions?start_month=${monthStart}&end_month=${monthEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMessagesSent = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/get_daily_metrics_time_series?metric=BUSINESS_CONVERSATIONS&start_date=${dataStart}&end_date=${dataEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getDirectionRequests = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/performance/locations/${locationId}/get_daily_metrics_time_series?metric=BUSINESS_DIRECTION_REQUESTS&start_date=${dataStart}&end_date=${dataEnd}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUsersOverTime = async (
  locationId: string,
  dataStart: string,
  dataEnd: string,
): Promise<any> => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    // const response = await axios.request(getAxiosConfig(URL_GMB, '/', 'GET', headers, data));

    // return Promise.resolve(response?.data);
    return Promise.resolve(0);
  } catch (err) {
    return Promise.reject(err);
  }
};
