import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { TextBlock } from '../../../../components/text-block';

import { CardGA4TotalSales } from '../../../../components/graph/ui/card-ga4-total-sales/card-ga4-total-sales';
import { CardGA4TotalOrders } from '../../../../components/graph/ui/card-ga4-total-orders/card-ga4-total-orders';
import { GraphGA4StoreSessionByDays } from '../../../../components/graph/ui/graph-ga4-store-session-by-days/graph-ga4-store-session-by-days';
import { CardGA4TotalCustomers } from '../../../../components/graph/ui/card-ga4-total-customers/card-ga4-total-customers';
import { CardGA4ReturningCustomers } from '../../../../components/graph/ui/card-ga4-returning-customers/card-ga4-returning-customers';
import { GraphGA4OrdersByDays } from '../../../../components/graph/ui/graph-ga4-orders-by-days/graph-ga4-orders-by-days';
import { CardGA4AddedToCart } from '../../../../components/graph/ui/card-ga4-added-to-cart/card-ga4-added-to-cart';
import { CardGA4InitiatedCheckout } from '../../../../components/graph/ui/card-ga4-initiated-checkout/card-ga4-initiated-checkout';
import { GraphGA4CustomersOverTime } from '../../../../components/graph/ui/graph-ga4-customers-over-time/graph-ga4-customers-over-time';
import { CardGA4AbandonedCarts } from '../../../../components/graph/ui/card-ga4-abandoned-carts/card-ga4-abandoned-carts';
import { CardGA4TotalPurchases } from '../../../../components/graph/ui/card-ga4-total-purchases/card-ga4-total-purchases';
import { GraphGA4SalesByDays } from '../../../../components/graph/ui/graph-ga4-sales-by-days/graph-ga4-sales-by-days';
import ga4Service from '../../../../services/GA4-service';

import cls from './e-commerce.module.scss';
import { GraphGA4SessionsByTrafficSource } from '../../../../components/graph/ui/graph-ga4-sessions-by-traffic-source/graph-ga4-sessions-by-traffic-source';
import { GraphGA4TopSellingProducts } from '../../../../components/graph/ui/graph-ga4-top-selling-products/graph-ga4-top-selling-products';

const ECommerce = () => {
  const userInfo: any = useSelector(getUserInfo);
  const email = userInfo?.email;

  const [monetizationOverview, setMonetizationOverview] = useState(null);
  const [monetizationPurchasesByItems, setMonetizationPurchasesByItems] = useState(null);
  const [userSource, setUserSource] = useState(null);
  const [addToCart, setAddToCart] = useState(null);
  const [eventPurchase, setEventPurchase] = useState(null);
  const [eventBeginCheckout, setEventBeginCheckout] = useState(null);

  const getData = async (email: string) => {
    Promise.all([
      ga4Service.getMonetizationOverview(email),
      ga4Service.getMonetizationPurchasesByItems(email),
      ga4Service.getUserSource(email),
      ga4Service.getAddToCart(email),
      ga4Service.getEventPurchase(email),
      ga4Service.getEventBeginCheckout(email),
    ]).then(data => {
      setMonetizationOverview(data[0]);
      setMonetizationPurchasesByItems(data[1]);
      setUserSource(data[2]);
      setAddToCart(data[3]);
      setEventPurchase(data[4]);
      setEventBeginCheckout(data[5]);
    });
  };

  useEffect(() => {
    console.log('-> email', email);
    if (email) getData(email);
  }, [email]);

  return (
    <div className={cls.wrapper}>
      <TextBlock header="Here's your last 30 days activity summary." />
      <div className={cls.dashboard}>
        <CardGA4TotalSales dataSet={monetizationOverview} />
        <CardGA4TotalOrders dataSet={monetizationOverview} />
        <GraphGA4StoreSessionByDays dataSet={userSource} large />
        <CardGA4TotalCustomers dataSet={userSource} />
        <CardGA4ReturningCustomers dataSet={userSource} />
        <GraphGA4OrdersByDays dataSet={monetizationOverview} large />
        <CardGA4AddedToCart dataSet={addToCart} />
        <CardGA4InitiatedCheckout dataSet={eventBeginCheckout} />
        <GraphGA4CustomersOverTime dataSet={userSource} large />
        <GraphGA4TopSellingProducts dataSet={monetizationPurchasesByItems} huge />
        <CardGA4AbandonedCarts dataSet={addToCart} dataSet1={eventPurchase} />
        <CardGA4TotalPurchases dataSet={eventPurchase} />
        <GraphGA4SessionsByTrafficSource dataSet={userSource} huge />
        <GraphGA4SalesByDays dataSet={monetizationOverview} large />
      </div>
    </div>
  );
};

export { ECommerce };
