import { ContentWrapper } from '../../components/content-wrapper';
import img from '../../assets/Google Advertising .png';
import metaImg from '../../assets/meta.png';
import influencerImg from '../../assets/influencer.png';
import metricsImg from '../../assets/metrics.jpg';
import seoPlansImg from '../../assets/SEO-Plans.png';
import analyzerImg from '../../assets/SEO-site analyzer.png';
import consultingImg from '../../assets/Pro Services Consulting.png';
import webDesignImg from '../../assets/webdesign Pro Services.jpg';
import graphicDesignImg from '../../assets/graphic-design.jpg';
import advertiseHomeImg from '../../assets/advertise-home.png';
import socialHomeImg from '../../assets/social-home.png';
import gmbHomeImg from '../../assets/Gogle_My_Business_homepage.png';
import psHome from '../../assets/Professoinal_Services_homepage.svg';
//import DashboardGraphs from '../dashboard/DashboardGraphs/DashboardGraphs';
import { googleConsoleData, LINE_CHART_CONFIG } from '../../constants';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Select, Typography } from '@mui/material';
import CountUp from 'react-countup';
import useStyles from '../../theme';
import { TextBlock } from '../../components/text-block';

export function AdvertiseHomePage() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={advertiseHomeImg} style={{ width: '70%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function GoogleAdvertise() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={img} style={{ width: '80%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

export function MetaAdvertise() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={metaImg} style={{ width: '70%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

export function YelpAdvertise() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <TextBlock>Yellow pages</TextBlock>
      </div>
    </ContentWrapper>
  );
}

export function InfluencerAdvertise() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={influencerImg} style={{ width: '100%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

export function SEOMetrics() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={metricsImg} style={{ width: '100%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function SEOSiteAnalyzer() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={analyzerImg} style={{ width: '100%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function SEOPlans() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={seoPlansImg} style={{ width: '100%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function ProfessionalConsultingServices() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={consultingImg} style={{ width: '80%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

export function ProfessionalGraphicsServices() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={graphicDesignImg} style={{ width: '80%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function ProfessionalWebDesignServices() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img src={webDesignImg} style={{ width: '80%' }} alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

export function SocialHome() {
  const classes = useStyles();
  const dataReadings = {
    labels: ['Post A', 'Post B', 'Post C', 'Post D', 'Post E'],
    datasets: [
      {
        label: 'No of Readings per post',
        data: [10, 15, 7, 12, 18],
        borderColor: '#00A4CB',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: false,
      },
    ],
  };
  const dataMentions = {
    labels: ['Post A', 'Post B', 'Post C', 'Post D', 'Post E'],
    datasets: [
      {
        label: 'No of Mentions per post',
        data: [45, 1, 16, 23, 7],
        borderColor: '#00A4CB',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: false,
      },
    ],
  };
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography className={classes.typographyTitle}>Social Media Dashboard</Typography>
        </div>
        <div style={{ width: '300px', marginBottom: '20px' }}>
          <Select
            className="selec"
            value={'facebook'}
            style={{
              width: '100%',
              height: '40px',
            }}
            disabled>
            <MenuItem selected value={'facebook'}>
              Facebook
            </MenuItem>
            <MenuItem value={'instagram'}>Instagram</MenuItem>
          </Select>
        </div>
        <div className="line_graph_card_wrapper_db">
          <div className="line_graph_card_db">
            <div className="pie_header_wrapper_dummy">
              <Typography className="pie_header_text_dummy">No of Posts</Typography>
              <Typography className="pie_header_value_dummy">
                <CountUp duration={2} className="counter" end={121} />
              </Typography>
            </div>
          </div>
          <div className="line_graph_card_db">
            <div className="pie_header_wrapper_dummy">
              <Typography className="pie_header_text_dummy">No of Followers</Typography>
              <Typography className="pie_header_value_dummy">
                <CountUp duration={2} className="counter" end={56} />
              </Typography>
            </div>
          </div>
          <div className="line_graph_card_db">
            <div className="pie_header_wrapper_dummy">
              <Typography className="pie_header_text_dummy">No of Likes</Typography>
              <Typography className="pie_header_value_dummy">
                <CountUp duration={2} className="counter" end={89} />
              </Typography>
            </div>
          </div>
        </div>
        <div className="line_graph_card_wrapper_db">
          <div className="line_graph_card_db">
            <div style={{ height: '200px' }}>
              <Line
                data={dataReadings}
                style={{
                  maxHeight: '200px',
                  maxWidth: '100%',
                  paddingLeft: '10px',
                  display: 'flex',
                }}
                options={LINE_CHART_CONFIG}
              />
            </div>
          </div>
          <div className="line_graph_card_db">
            <div style={{ height: '200px' }}>
              <Line
                data={dataMentions}
                style={{
                  maxHeight: '200px',
                  maxWidth: '100%',
                  paddingLeft: '10px',
                  display: 'flex',
                }}
                options={LINE_CHART_CONFIG}
              />
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}

export function GMBHome() {
  const navigate = useNavigate();
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        <img
          src={gmbHomeImg}
          style={{ width: '60%', cursor: 'pointer' }}
          onClick={() => navigate('/gmb/profile')}
          alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}
export function GMBProfile() {
  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginLeft: '20px',
        }}>
        {/* <DashboardGraphs /> */}
        <Line
          data={googleConsoleData}
          style={{
            maxHeight: '380px',
            maxWidth: '100%',
            paddingLeft: '10px',
            display: 'flex',
          }}
          options={LINE_CHART_CONFIG}
        />
      </div>
    </ContentWrapper>
  );
}

export function ProfessionalServicesHome() {
  return (
    <ContentWrapper noPadding={true}>
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <img
          src={psHome}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="Logo"></img>
      </div>
    </ContentWrapper>
  );
}

const scaffold = {
  GoogleAdvertise,
  MetaAdvertise,
  InfluencerAdvertise,
};

export default scaffold;
