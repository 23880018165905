import { memo, useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { RgbaColorPicker } from 'react-colorful';

import { Flex } from '../../../../components/flex';
import { GeneralInformation } from '../../model/types/general-information';

import cls from './aesthetics.module.scss';
import { Section } from '../../../get-help/ui/components/section';
import { TextBlock } from '../../../../components/text-block';
import { Radio } from '../../../../components/radio';
import { classNames } from '../../../../utils/class-names';
import { Button } from '../../../../components/button';
import { TextField } from '../../../../components/text-field';

const PRINT_MATERIAL_LIST = [
  { id: 1, name: 'No, I dont have established material' },
  { id: 2, name: 'Brochure' },
  { id: 3, name: 'Business Cards' },
  { id: 4, name: 'Letterheads' },
  { id: 5, name: 'Event material' },
  { id: 6, name: 'Flyers' },
  { id: 7, name: 'Posters' },
  { id: 8, name: 'Stickers' },
];

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const Aesthetics = memo((props: Props) => {
  const { className, data, setData } = props;

  const [fontList, setFontList] = useState<any[]>([]);
  const [currentColor, setCurrentColor] = useState(0);
  const [keyWord, setKeyWord] = useState('');
  const [fontName, setFontName] = useState('');

  // useEffect(() => {
  //   fetch(
  //     'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDc_K3Y8z4jR_-KOpU9gBHaMS1zR6gB0Jc',
  //   )
  //     .then(response => response.json())
  //     .then(data => {
  //       const fonts = data.items.map((item: any) => ({
  //         id: item.family,
  //         name: item.family,
  //       }));
  //       setFontList(fonts);
  //     });
  // }, []);

  // bcs could be restored from localstorage and we need to match them with the list
  useEffect(() => {
    if (data.printMaterial.length !== 0) {
      const matchingElements = PRINT_MATERIAL_LIST.filter(material =>
        data.printMaterial.some((element: any) => element.id === material.id),
      );

      setData({ ...data, printMaterial: matchingElements });
    }
  }, []);

  // bcs could be restored from localstorage and we need to match them with the list
  useEffect(() => {
    if (fontList.length === 0) return;
    if (data.usedFonts.length !== 0) {
      const matchingElements = fontList.filter(font =>
        data.usedFonts.some((element: any) => element.id === font.id),
      );

      setData({ ...data, usedFonts: matchingElements });
    }
  }, [fontList]);

  const handlePrintMaterialChange = (materials: any[]) => {
    if (materials.includes(PRINT_MATERIAL_LIST[0])) {
      setData({ ...data, printMaterial: [] });
      return;
    }

    setData({ ...data, printMaterial: materials });
  };

  const handleRemoveMaterial = (id: number) => {
    setData({ ...data, printMaterial: data.printMaterial.filter(material => material.id !== id) });
  };

  const handleFontAdd = () => {
    if (fontName === '') return;
    setData({ ...data, usedFonts: [...data.usedFonts, fontName] });
    setFontName('');
  };

  const handleRemoveFont = (id: number) => {
    const fonts = [...data.usedFonts];
    fonts.splice(id, 1);
    setData({ ...data, usedFonts: fonts });
  };

  const handleColorChange = (color: any) => {
    const colorArray = [...data.colors];
    colorArray[currentColor] = color;

    setData({ ...data, colors: colorArray });
  };

  const getHexColor = (color: { a: number; r: number; g: number; b: number }) => {
    return `#${((1 << 24) + (color.r << 16) + (color.g << 8) + color.b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`;
  };

  const getRGBColor = (color: { a: number; r: number; g: number; b: number }) => {
    return `R${color.r} G${color.g} B${color.b} ${Math.round(color.a * 100)}%`;
  };

  const getCMYKColor = (color: { a: number; r: number; g: number; b: number }) => {
    let c = 1 - color.r / 255;
    let m = 1 - color.g / 255;
    let y = 1 - color.b / 255;
    let k = Math.min(c, m, y);

    if (k === 1) {
      c = 0; // If K=100%, then C, M, Y = 0
      m = 0;
      y = 0;
    } else {
      c = (c - k) / (1 - k);
      m = (m - k) / (1 - k);
      y = (y - k) / (1 - k);
    }

    return `C${Math.round(c * 100)} M${Math.round(m * 100)} Y${Math.round(y * 100)} K${Math.round(
      k * 100,
    )} ${Math.round(color.a * 100)}%`;
  };

  const handleRemoveColor = (index: number) => {
    const colors = [...data.colors];
    colors.splice(index, 1);
    setData({ ...data, colors });
  };

  const handleAddColor = () => {
    setData({ ...data, colors: [...data.colors, { a: 1, r: 255, g: 255, b: 255 }] });
    setCurrentColor(data.colors.length);
  };

  const handleColorClick = (index: number) => {
    setCurrentColor(index);
  };

  const handleAddKeyWord = () => {
    if (keyWord === '') return;
    setData({ ...data, keyWords: [...data.keyWords, keyWord] });
    setKeyWord('');
  };

  const handleRemoveKeyWord = (index: number) => {
    const keyWords = [...data.keyWords];
    keyWords.splice(index, 1);
    setData({ ...data, keyWords });
  };

  return (
    <Flex style={{ alignItems: 'stretch' }}>
      <Section className={cls.flex}>
        <TextBlock header={'Brand Guidelines'}>
          Do you have a styleguide or brand standards?
        </TextBlock>
        <Flex>
          <Radio
            checked={data.hasStyleGuide}
            onChange={() => setData({ ...data, hasStyleGuide: true })}>
            Yes
          </Radio>
          <Radio
            checked={!data.hasStyleGuide}
            onChange={() => setData({ ...data, hasStyleGuide: false })}>
            No
          </Radio>
        </Flex>
        <TextBlock>Do you have established print material?</TextBlock>
        <div className={cls.listWrapper}>
          <Listbox value={data.printMaterial} onChange={handlePrintMaterialChange} multiple>
            <Listbox.Button className={cls.openButton}>Make your selection</Listbox.Button>
            <Listbox.Options className={classNames(cls.list, cls.markFirst)}>
              {PRINT_MATERIAL_LIST.map(material => (
                <Listbox.Option className={cls.element} key={material.id} value={material}>
                  {({ selected, active }) => (
                    <div className={` ${active ? cls.active : cls.notActive}`}>
                      <span className={` ${selected ? cls.selected : cls.notSelected}`}>
                        {material.name}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <Flex className={cls.bubbles}>
          {data.printMaterial.map(material => (
            <div className={cls.bubble} key={material.id}>
              {material.name}
              <button onClick={() => handleRemoveMaterial(material.id)}>x</button>
            </div>
          ))}
        </Flex>

        <TextBlock>What font do you typically use?</TextBlock>
        <Flex className={cls.keyWords} left>
          <TextField
            value={fontName}
            placeholder="Add font name here"
            onChange={evt => setFontName(evt.target.value)}
          />
          <Button primary onClick={handleFontAdd}>
            Add
          </Button>
        </Flex>
        {/* <div className={cls.listWrapper}>
          <Listbox value={data.usedFonts} onChange={handleFontChange} multiple>
            <Listbox.Button className={cls.openButton}>Make your selection</Listbox.Button>
            <Listbox.Options className={cls.list}>
              {fontList.map(material => (
                <Listbox.Option className={cls.element} key={material.id} value={material}>
                  {({ selected, active }) => (
                    <div className={` ${active ? cls.active : cls.notActive}`}>
                      <span className={` ${selected ? cls.selected : cls.notSelected}`}>
                        {material.name}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div> */}
        <Flex className={cls.bubbles}>
          {data.usedFonts.map((font, index) => (
            <div className={cls.bubble} key={index}>
              {font}
              <button onClick={() => handleRemoveFont(index)}>x</button>
            </div>
          ))}
        </Flex>
      </Section>

      <Section className={cls.flex}>
        <TextBlock header={'Colors'}>
          Are there any colors you want to incorporated to the website?
        </TextBlock>

        <div className="colorPicker">
          <RgbaColorPicker color={data.colors[currentColor]} onChange={handleColorChange} />
        </div>

        <div className={cls.header}>
          <div className={cls.colorCircle} />
          <div className={cls.column}>Hex code</div>
          <div className={cls.column}>RGB</div>
          <div className={cls.column}>CMYK</div>
        </div>
        {data.colors.map((color, index) => (
          <div
            className={classNames(cls.rows, { [cls.current]: index === currentColor })}
            key={color}
            onClick={() => handleColorClick(data.colors.indexOf(color))}>
            <div className={cls.colorCircle} style={{ backgroundColor: getHexColor(color) }} />
            <div className={cls.column}>{`${getHexColor(color)} ${Math.round(
              color.a * 100,
            )}%`}</div>
            <div className={cls.column}>{getRGBColor(color)}</div>
            <div className={cls.column}>{getCMYKColor(color)}</div>
            {data.colors.length > 1 && (
              <button
                className={cls.clearButton}
                onClick={() => handleRemoveColor(data.colors.indexOf(color))}>
                x
              </button>
            )}
          </div>
        ))}
        <button onClick={handleAddColor}>Add color</button>

        <TextBlock header="Search Engine Optimisation">Do you use google Analytics?</TextBlock>
        <Flex>
          <Radio
            checked={data.hasGoogleAnalytics}
            onChange={() => setData({ ...data, hasGoogleAnalytics: true })}>
            Yes
          </Radio>
          <Radio
            checked={!data.hasGoogleAnalytics}
            onChange={() => setData({ ...data, hasGoogleAnalytics: false })}>
            No
          </Radio>
        </Flex>
        <TextBlock>
          If you are using search engine, what words or phrase would you use to find your site?
        </TextBlock>
        <Flex>
          <Flex className={cls.keyWords} left vertical>
            <TextField
              value={keyWord}
              placeholder="Add keywords here"
              onChange={evt => setKeyWord(evt.target.value)}
            />
            <Button primary onClick={handleAddKeyWord}>
              Add
            </Button>
          </Flex>
          <Flex className={cls.keyWords} left vertical>
            {data.keyWords.map((word, index) => (
              <div className={cls.keyWord} key={index}>
                {word}
                <button
                  className={cls.deleteKeyWordButton}
                  onClick={() => handleRemoveKeyWord(index)}>
                  x
                </button>
              </div>
            ))}
          </Flex>
        </Flex>
      </Section>
    </Flex>
  );
});

export { Aesthetics };
