import { AnyAction } from 'redux';
import services from '../../services';

interface GMBState {
  totalUser: any;
  newUsers: any;
  usersBydate: any[];
  revenueByProduct: any[];
  totalRevenue: any;
  mrrRevenue: any;
}

export const SET_TOTAL_USER = 'SET_TOTAL_USER';
export const SET_NEW_USERS = 'SET_NEW_USERS';
export const SET_USERS_BY_DATE = 'SET_USERS_BY_DATE';
export const SET_REVENUE_BY_PRODUCT = 'SET_REVENUE_BY_PRODUCT';
export const SET_TOTAL_APP_REVENUE = 'SET_TOTAL_APP_REVENUE';
export const SET_MRR_APP_REVENUE = 'SET_MRR_APP_REVENUE';

const initialState: GMBState = {
  totalUser: null,
  newUsers: null,
  usersBydate: [],
  revenueByProduct: [],
  totalRevenue: null,
  mrrRevenue: null,
};

/* -------------------------------------------------------------------------- */
/*                           interfaces for actions                           */
/* -------------------------------------------------------------------------- */
export interface SetTotalUserInterface extends AnyAction {
  type: typeof SET_TOTAL_USER;
  totalUser: any;
}

export interface SetNewUsersInterface extends AnyAction {
  type: typeof SET_NEW_USERS;
  newUsers: any;
}
export interface SetTotalRevenueInterface extends AnyAction {
  type: typeof SET_TOTAL_APP_REVENUE;
  totalRevenue: any;
}
export interface SetMrrRevenueInterface extends AnyAction {
  type: typeof SET_MRR_APP_REVENUE;
  mrrRevenue: any;
}

export interface SetUsersByDateInterface extends AnyAction {
  type: typeof SET_USERS_BY_DATE;
  usersBydate: any[];
}
export interface SetRevenueByProductInterface extends AnyAction {
  type: typeof SET_REVENUE_BY_PRODUCT;
  revenueByProduct: any[];
}

export type ActionType =
  | SetTotalUserInterface
  | SetNewUsersInterface
  | SetUsersByDateInterface
  | SetMrrRevenueInterface
  | SetRevenueByProductInterface
  | SetTotalRevenueInterface;

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export const setTotalUserAction = (totalUser: any): SetTotalUserInterface => ({
  totalUser,
  type: SET_TOTAL_USER,
});

export const setTotalRevenueAction = (totalRevenue: any): SetTotalRevenueInterface => ({
  totalRevenue,
  type: SET_TOTAL_APP_REVENUE,
});

export const setMrrRevenueAction = (mrrRevenue: any): SetMrrRevenueInterface => ({
  mrrRevenue,
  type: SET_MRR_APP_REVENUE,
});

export const setNewUsersAction = (newUsers: any): SetNewUsersInterface => ({
  newUsers,
  type: SET_NEW_USERS,
});

export const setUsersByDateAction = (usersBydate: any): SetUsersByDateInterface => ({
  usersBydate,
  type: SET_USERS_BY_DATE,
});

export const setRevenueByProductAction = (revenueByProduct: any): SetRevenueByProductInterface => ({
  revenueByProduct,
  type: SET_REVENUE_BY_PRODUCT,
});

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
export default function adminDashboardReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_TOTAL_USER:
      return { ...state, totalUser: action.totalUser };
    case SET_TOTAL_APP_REVENUE:
      return { ...state, totalRevenue: action.totalRevenue };
    case SET_MRR_APP_REVENUE:
      return { ...state, mrrRevenue: action.mrrRevenue };
    case SET_NEW_USERS:
      return { ...state, newUsers: action.newUsers };
    case SET_USERS_BY_DATE:
      return { ...state, usersBydate: action.usersBydate };
    case SET_REVENUE_BY_PRODUCT:
      return { ...state, revenueByProduct: action.revenueByProduct };
    default:
      return state;
  }
}

/* -------------------------------------------------------------------------- */
/*                               Thunk Actions                                */
/* -------------------------------------------------------------------------- */

export const fetchTotalUser = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await services.getTotalUser();
      if (!response) return Promise.reject('No user found');
      dispatch(setTotalUserAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchTotalRevenue = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await services.getTotalRevenue();
      if (!response) return Promise.reject('No revenue found');
      dispatch(setTotalRevenueAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchRevenueByProduct = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await services.getRevenueByProduct();
      if (!response) return Promise.reject('No revenue found');
      dispatch(setRevenueByProductAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchMrrRevenue = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const currentDate = new Date();
      const date30DaysAgo = new Date();
      date30DaysAgo.setDate(currentDate.getDate() - 30);

      const formatDate = (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const currentDateString = formatDate(currentDate);
      const date30DaysAgoString = formatDate(date30DaysAgo);

      const data = {
        start_timestamp: date30DaysAgoString,
        end_timestamp: currentDateString,
      };
      const response = await services.getMrrRevenue(data);
      if (!response) return Promise.reject('No revenue found');
      dispatch(setMrrRevenueAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchUsersByDate = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const currentDate = new Date();
      const date30DaysAgo = new Date();
      date30DaysAgo.setDate(currentDate.getDate() - 30);

      const formatDate = (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const currentDateString = formatDate(currentDate);
      const date30DaysAgoString = formatDate(date30DaysAgo);

      const data = {
        start_timestamp: date30DaysAgoString,
        end_timestamp: currentDateString,
      };

      const response = await services.getTotalUser(data);
      if (!response) return Promise.reject('No user found');
      dispatch(setNewUsersAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchUserListByDate = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const currentDate = new Date();
      const date30DaysAgo = new Date();
      date30DaysAgo.setDate(currentDate.getDate() - 30);

      const formatDate = (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const currentDateString = formatDate(currentDate);
      const date30DaysAgoString = formatDate(date30DaysAgo);

      const data = {
        start_timestamp: date30DaysAgoString,
        end_timestamp: currentDateString,
      };

      const response = await services.getUserListByDate(data);
      if (!response) return Promise.reject('No user found');
      dispatch(setUsersByDateAction(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

/* -------------------------------------------------------------------------- */
/*                                  selectors                                 */
/* -------------------------------------------------------------------------- */

export const selectTotalUser = (state: any) => state.adminDashboardInfo?.totalUser;
export const selectTotalRevenue = (state: any) => state.adminDashboardInfo?.totalRevenue;
export const selectRevenueByProductList = (state: any) => state.adminDashboardInfo?.revenueByProduct;
export const selectMrrRevenue = (state: any) => state.adminDashboardInfo?.mrrRevenue;
export const selectNewlUser = (state: any) => state.adminDashboardInfo?.newUsers;
export const selectUserList = (state: any) => state.adminDashboardInfo?.usersBydate;
