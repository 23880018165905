import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';

import {
  allRouteList,
  publicRoteList,
  mobileRouteList,
  adminRouteList,
} from '../model/config/route-config';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import { isMobile } from '../../utils/is-mobile';

const Private = (Component: React.ReactElement) => <PrivateRoute>{Component}</PrivateRoute>;

const AppRouter = ({ role }: { role: string }) => {
  const { isAuthenticated } = useAuthContext();

  let currentRouteList = publicRoteList;

  if (isAuthenticated) {
    if (role !== 'customer') {
      currentRouteList = adminRouteList;
    } else {
      if (isMobile()) {
        currentRouteList = mobileRouteList;
      } else {
        currentRouteList = allRouteList;
      }
    }
  } else {
    currentRouteList = publicRoteList;
  }

  return (
    <Routes>
      {currentRouteList.map(route => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={isAuthenticated ? Private(route.element) : route.element}
          />
        );
      })}
    </Routes>
  );
};

export { AppRouter };
