export enum AppRoute {
  PAY = '/pay',
  ACCOUNT_PROFILE = '/account-profile',
  BUSINESS_PROFILE = '/business-profile',
  LOGIN = '/',
  REGISTRATION = '/registration',
  VERIFICATION = '/verification',
  PASSWORD_RESET = '/password-reset',
  FORGOT_PASSWORD = '/forgot-password-request',
  FORGOT_PASSWORD_SENT = '/forgot-password-sent',
  EMAIL_VALIDATION_SUCCESS = '/email-validation-success',
  BUSINESS = '/business',
  BUSINESS_PAYMENT = '/business/payment',
  EXPIRED = '/expired',
  MARKETING = '/marketing',
  SOCIAL_MEDIA = '/social-media',
  SOCIAL_MEDIA_NEW_POST = '/social-media/new-post',
  SEM = '/sem',

  PAYMENT_SUCCESS = '/payment/success',
  PAYMENT_CANCEL = '/payment/cancel',

  //menu
  IN_STORE = '/in-store',
  DASHBOARD = '/dashboard',
  HOME = '/home',
  ADVERTISING = '/advertising',
  ADVERTISING_GOOGLE = '/advertising/google',
  ADVERTISING_META = '/advertising/meta',
  ADVERTISING_YELP = '/advertising/yelp',
  ADVERTISING_INFLUENCER = '/advertising/influencer',
  SOCIAL = '/social',
  BASIC_PLAN_PAYMENT = '/business-profile/payment',
  // SOCIAL_ANALYTICS = '/social/analytics',
  SOCIAL_CALENDAR = '/social/calendar',
  SOCIAL_NEW_POST = '/social/new-post',
  SOCIAL_CONNECTIONS = '/social/connections',
  GMB = '/gmb',
  GMB_CALENDAR = '/gmb/calendar',
  GMB_NEW_POST = '/gmb/new-post',
  GMB_PROFILE = '/gmb/profile',
  GMB_REVIEWS = '/gmb/reviews',
  GMB_PLANS = '/gmb/plans',
  GMB_PAYMENT = '/gmb/payment',
  GMB_CONNECTIONS = '/gmb/connections',
  GOOGLE_ADS_CUSTOMER_ACCOUNTS = '/google-ads/customer-accounts',
  SEO = '/seo',
  SEO_METRICS = '/seo/metrics',
  SEO_ANALYSER = '/seo/site-analyser',
  SEO_PLANS = '/seo/plans',
  SEO_PAYMENT = '/seo/payment',
  SERVICES = '/services',
  SERVICES_GRAPHICS = '/services/graphics',
  SERVICES_DESIGN = '/services/design',
  SERVICES_CONSULTING = '/services/consulting',
  INTEGRATION = '/integration',
  GET_HELP = '/get-help',
  PLUGINS = '/plugins',

  EXECUTIVE_DASHBOARD = '/executive-dashboard',
  DASHBOARD_BY_ID = '/dashboard/:id',
  CONTRACT = '/contract/:id',
  ALL = '*',
  CLOSE_PAGE = '/close-page',
  ADMIN_DASHBOARD = '/',
  ADMIN_CLIENTS = '/clients',
  ADMIN_USERS = '/users',
}
