import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { getAxiosConfig } from '../utils';
import { Event as EventType } from '../components/calendar/types/event';

const URL = process.env.REACT_APP_ENV_SSO_API_DOMAIN || '';
const URL_PAY = process.env.REACT_APP_ENV_PAY_API_DOMAIN || '';
const URL_BI = process.env.REACT_APP_ENV_BI_API_DOMAIN || '';
const BASE_URL = process.env.REACT_APP_ENV_WEB_DOMAIN || '';
const URL_SMP = process.env.REACT_APP_ENV_SMP_API_DOMAIN || '';
const URL_OPENAI = process.env.REACT_APP_ENV_OPENAI_API_DOMAIN || '';
const URL_FILE = process.env.REACT_APP_ENV_FILE_API_DOMAIN || '';
const URL_TASK = process.env.REACT_APP_ENV_TASK_API_DOMAIN || '';
const URL_TAG = process.env.REACT_APP_ENV_TAG_API_DOMAIN || '';
const URL_G_ADS = process.env.REACT_APP_ENV_GOOGLE_ADS_API_DOMAIN || '';

const activateUser = async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const data = {};
    const response = await axios.request(getAxiosConfig(URL, '/user/activate?token=' + token, 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const checkUserStatusByToken = async (email_token: string = '', pwd_reset_token: string = '') => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const data = null;
    const response = await axios.request(
      getAxiosConfig(
        URL,
        pwd_reset_token ? '/user/status?pwd_reset_token=' + pwd_reset_token : '/user/status?email_token=' + email_token,
        'POST',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const checkUserStatusByEmail = async (email: string = '') => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const data = email ? { email: email } : null;
    const response = await axios.request(getAxiosConfig(URL, '/user/status', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const changePassword = async (password: string, emailToken: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    const data = {
      password,
    };

    let uri = emailToken === '' ? '/user/password/change' : '/user/password/change?email_token=' + emailToken;
    const response = await axios.request(getAxiosConfig(URL, uri, 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const verifyOldPassword = async (password: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const data = {};
    const response = await axios.request(
      getAxiosConfig(URL, '/user/password/verify?old_password=' + password, 'GET', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const stripePay = async (priceId: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const data = {};
    const successUrl = BASE_URL + '/payment/success';
    const cancelUrl = BASE_URL + '/payment/cancel';
    const response = await axios.request(
      getAxiosConfig(
        URL_PAY,
        '/stripe/checkout_session/' + priceId + '?success_url=' + successUrl + '&cancel_url=' + cancelUrl,
        'POST',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const login = async (username: string, password: string) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const data = {
    username: username,
    password: password,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/login', 'PUT', headers, data));
    let res: any = {};
    res['status'] = response.status;
    res['headers'] = response.headers;
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const refresh = async () => {
  const headers = {
    accept: '*/*',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL, '/refresh', 'PUT', headers, data));
    let res: any = {};
    res['status'] = response.status;
    res['headers'] = response.headers;
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const logout = async () => {
  const headers = {
    accept: '*/*',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL, '/logout', 'PUT', headers, data));

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const register = async (data: any) => {
  const headers = {
    accept: '*/*',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/customer', 'POST', headers, data));

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const sendUserVerificationEmail = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = { email: email };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/verification', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPasswordResetToken = async (emailToken: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(URL, '/user/pwd/t?email_token=' + emailToken, 'PUT', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const sendResetPasswordRequestEmail = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = { email: email };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/password/reset', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const checkEmailValidity = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {
    email: email,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/verify/email', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createCustomer = async (formData: any) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = formData;
  try {
    const response = await axios.request(getAxiosConfig(URL, '/customer', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllCustomer = async (query: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {};
  let uri = query ? '/customers/list?s=' + query : '/customers/list';
  try {
    const response = await axios.request(getAxiosConfig(URL, uri, 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllCustomerByPagination = async (query: string, offset: any) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {};
  const limit = 10;
  let uri = query
    ? '/customers/pagination-list?s=' + query + '&offset=' + offset.toString() + '&limit=' + limit.toString()
    : '/customers/pagination-list?offset=' + offset.toString() + '&limit=' + limit.toString();
  try {
    const response = await axios.request(getAxiosConfig(URL, uri, 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateCustomer = async (formData: any) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = formData;
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/info', 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateCompanyAddress = async (formData: any) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = formData;
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/company_address', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const terminateCustomer = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {
    email: email,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/terminate', 'DELETE', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const revokeCustomer = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {
    email: email,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/revoke', 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createUser = async (formData: any, role: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = formData;
  try {
    const response = await axios.request(
      getAxiosConfig(URL, role === 'admin' ? '/admin' : '/operation', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllUser = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {};
  let uri = '/users/list';
  try {
    const response = await axios.request(getAxiosConfig(URL, uri, 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const changeUserRole = async (role: string, email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const data = {
    email: email,
  };
  let uri = role === 'operation' ? '/user/ato' : '/user/ota';
  try {
    const response = await axios.request(getAxiosConfig(URL, uri, 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadUserAvatar = async (file: any) => {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.request(getAxiosConfig(URL_FILE, '/user/a/u', 'PUT', headers, formData));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAccountInfo = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/me', 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserDefaultChannelGroupData = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/user/first_user_default_channel_group', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLandingPageData = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/engagement/landing_page', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGoogleConsoleData = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/search_console/overtime', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMonitizationOverviewData = async (email: string, endDate: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: '0',
    start_date: moment(endDate).subtract(2, 'months').format('YYYY-MM-DD'),
    end_date: moment(endDate).format('YYYY-MM-DD'),
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/monetization/overview', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateCustomerDetail = async (userId: string, formData: any) => {
  const headers = {
    accept: 'application/json',
  };
  const data = formData;
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/info/' + userId, 'PUT', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllProducts = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL_PAY, '/stripe/products/search', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBCategories = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, 'google_my_business/categories', 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateSMPPostText = async (value: any) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    prompt: value,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_OPENAI, 'texts', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateGMBReviewText = async (value: any) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    prompt: value,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_OPENAI, 'texts/review', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateSMPPostImage = async (value: any) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    prompt: value,
    size: '1024x1024',
  };
  try {
    const response = await axios.request(
      //getAxiosConfig(URL_OPENAI, 'insite-custom-ai-image', 'POST', headers, data)
      getAxiosConfig(URL_FILE, 'images', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateFreeStockPostImage = async (value: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, 'freestock/get-freestock-images/' + value, 'GET', headers, value)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadPostImage = async (file: any) => {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.request(getAxiosConfig(URL_FILE, '/upload_post_image', 'PUT', headers, formData));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateFacebookAccessToken = async (facebookId: string, shortLivedAccessToken: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    fb_user_id: facebookId,
    access_token: shortLivedAccessToken,
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_SMP, '/facebook/connect', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateInstagramAccessToken = async (InstagramId: string, shortLivedAccessToken: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    fb_user_id: InstagramId,
    access_token: shortLivedAccessToken,
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_SMP, '/instagram/connect', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateMetaAdsAccessToken = async (id: string, shortLivedAccessToken: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    fb_user_id: id,
    access_token: shortLivedAccessToken,
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_SMP, '/meta-ads/connect', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateMetaAdsConnectionInfo = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, '/meta-ads/save-user-account-info', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const publishPostToScheduler = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_TASK, '/schedulers', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getEventList = async (startDate: number, endDate: number) => {
  const headers = {
    accept: 'application/json',
  };

  try {
    const response: AxiosResponse<EventType[]> = await axios.request(
      getAxiosConfig(URL_TASK, `schedulers?start_timestamp=${startDate}&end_timestamp=${endDate}`, 'GET', headers, '')
    );

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTotalFacebookInsights = async (query: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, '/facebook/get-page-info?query=' + query, 'GET', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getFacebookNewFollowersData = async (startDate: string, endDate: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_SMP,
        '/facebook/get-new-followers-by-date?start_date=' + startDate + '&end_date=' + endDate,
        'GET',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getFacebookEngagedUsersData = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, '/facebook/get-page-engaged-user-info', 'GET', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInstagramEngagedUsersData = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, '/instagram/get-page-engaged-user-info', 'GET', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInstagramProfileViewsData = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL_SMP, '/instagram/get-profile-views', 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInstagramPageInfo = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL_SMP, '/instagram/get-page-info', 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInstagramNewFollowersData = async (startDate: string, endDate: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_SMP,
        '/instagram/get-new-followers-by-date?start_date=' + startDate + '&end_date=' + endDate,
        'GET',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4NewUsersDataByCountry = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: 'alipu@rankworks.com',
    is_cumulative: '1',
    start_date: '30daysAgo',
    end_date: 'today',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/demographics/country', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getGA4ActiveUsersByDevice = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: '1',
    start_date: '30daysAgo',
    end_date: 'today',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/tech/device_category', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4NewUsersDataByDay = async (email: string, cumulative: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: cumulative,
    start_date: '30daysAgo',
    end_date: 'today',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/user/first_user_default_channel_group', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4EngagementData = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: '1',
    start_date: '28daysAgo',
    end_date: 'yesterday',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/engagement/pages_and_screens/content_group', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4NewUsersSessions = async (email: string, is_cumulative: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    email: email,
    is_cumulative: is_cumulative,
    start_date: '30daysAgo',
    end_date: 'yesterday',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/traffic/session_default_channel_group', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getServiceArea = async (searchText: string, limit: number) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL,
        '/user/service_area?search=' + searchText + '&limit=' + limit.toString(),
        'GET',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getCompetitorsByIndustryType = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/customer/competitors', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getCompetitorsBySerpId = async (id: string) => {
  const headers = {
    accept: 'application/json',
  };
  const data = {};
  try {
    const response = await axios.request(getAxiosConfig(URL, '/customer/competitors?id=' + id, 'GET', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const checkWebUrlValidity = async (url: string) => {
  const headers = {
    accept: 'application/json',
  };

  if (!url.startsWith('http://') && !url.startsWith('https://')) url = 'https://' + url;

  const data = {
    url: url,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_TAG, '/accounts/verify/gtm_url', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createGTMContainer = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL, '/customer/company', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGTMTagSnippetStatus = async (url: string, tagId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    url: url,
    code: tagId,
  };
  try {
    const response = await axios.request(getAxiosConfig(URL_TAG, '/accounts/verify/snippet', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getAllStripePlan = async (category: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_PAY,
        '/stripe/custom-subscriptions/get-stripe-plans?category=' + category,
        'GET',
        headers,
        data
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getStripeCustomerInfo = async () => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/get-customer-stripe-info', 'GET', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createStripeBasicSubscription = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/create-basic-subscription', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createSubscription = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/create-subscription', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const stripeCustomerUpdate = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/update-stripe-customer', 'POST', headers, data)
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllTransaction = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/transactions', 'GET', headers, {}));
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllContracts = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/user/contracts', 'GET', headers, {}));
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createFreePlanSubscription = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/free-plan-subscription', 'POST', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const createFreePlan = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/free-plan-subscription-by-id', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserPlan = async (id: string) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/get-user-plan/' + id, 'GET', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const modifyUserCredit = async (id: string, data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/modify-user-plan-credit/' + id, 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getTotalUser = async (data: any = null) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/users/count', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserListByDate = async (data: any = null) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(getAxiosConfig(URL, '/users/by-date', 'POST', headers, data));
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTotalRevenue = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/get-total-revenue', 'GET', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMrrRevenue = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/get-mrr-revenue-by-date', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getRevenueByProduct = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_PAY, '/stripe/custom-subscriptions/get-revenue-by-product', 'POST', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateAdminCompanyInfo = async (customerId: any) => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL, '/replace-company-info/' + customerId, 'GET', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const resetBellAmount = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL, '/user/notifications/bell-amount?amount=0', 'POST', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getMetaCampaigns = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_SMP, '/meta-ads/get-campaigns-by-account', 'GET', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGoogleCampaigns = async () => {
  const headers = {
    accept: 'application/json',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_G_ADS, '/campaign_management/get_campaigns', 'POST', headers, {})
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGoogleCampaignsDataByDate = async () => {
  const headers = {
    accept: 'application/json',
  };
  const end_date = moment().format('YYYY-MM-DD'); // Current date formatted as YYYY-MM-DD
  const start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  try {
    const response = await axios.request(
      getAxiosConfig(URL_G_ADS, '/campaign_management/get_campaigns_metrics', 'POST', headers, {
        start_date: start_date,
        end_date: end_date,
      })
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMetaCampaignDataById = async (id: string) => {
  const headers = {
    accept: 'application/json',
  };

  const end_date = moment().format('YYYY-MM-DD'); // Current date formatted as YYYY-MM-DD
  const start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_SMP,
        '/meta-ads/get-campaign-data?campaign_id=' + id + '&start_date=' + start_date + '&end_date=' + end_date,
        'GET',
        headers,
        {}
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMetaCampaignDataByIdAndByDay = async (id: string) => {
  const headers = {
    accept: 'application/json',
  };

  const end_date = moment().format('YYYY-MM-DD'); // Current date formatted as YYYY-MM-DD
  const start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_SMP,
        '/meta-ads/get-campaign-data-by-date?campaign_id=' + id + '&start_date=' + start_date + '&end_date=' + end_date,
        'GET',
        headers,
        {}
      )
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGoogleAdsAccounts = async () => {
  const headers = {
    accept: 'application/json',
  };
  const data = {
    manager_account_id: '',
  };
  try {
    const response = await axios.request(
      getAxiosConfig(URL_G_ADS, '/accounts_management/get_account_hierarchy', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const setGoogleAdsCustomerAccount = async (data: any) => {
  const headers = {
    accept: 'application/json',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_G_ADS, '/accounts_management/set_default_customer_account', 'POST', headers, data)
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const scaffold = {
  login,
  logout,
  register,
  refresh,
  stripePay,
  activateUser,
  changePassword,
  verifyOldPassword,
  getPasswordResetToken,
  checkUserStatusByEmail,
  checkUserStatusByToken,
  sendUserVerificationEmail,
  sendResetPasswordRequestEmail,
  checkEmailValidity,
  getAllCustomer,
  createCustomer,
  updateCustomer,
  terminateCustomer,
  revokeCustomer,
  createUser,
  getAllUser,
  changeUserRole,
  uploadUserAvatar,
  getAccountInfo,
  updateCustomerDetail,
  getUserDefaultChannelGroupData,
  getLandingPageData,
  getGoogleConsoleData,
  getMonitizationOverviewData,
  getAllProducts,
  getGMBCategories,
  generateSMPPostText,
  generateGMBReviewText,
  generateSMPPostImage,
  uploadPostImage,
  generateFacebookAccessToken,
  generateInstagramAccessToken,
  publishPostToScheduler,
  generateFreeStockPostImage,
  getEventList,
  getTotalFacebookInsights,
  getFacebookNewFollowersData,
  getFacebookEngagedUsersData,
  getInstagramNewFollowersData,
  getInstagramPageInfo,
  getInstagramEngagedUsersData,
  getInstagramProfileViewsData,
  getGA4NewUsersDataByCountry,
  getGA4ActiveUsersByDevice,
  getGA4NewUsersDataByDay,
  getGA4EngagementData,
  getServiceArea,
  getCompetitorsByIndustryType,
  getCompetitorsBySerpId,
  checkWebUrlValidity,
  createGTMContainer,
  getGTMTagSnippetStatus,
  getAllStripePlan,
  getStripeCustomerInfo,
  createStripeBasicSubscription,
  createSubscription,
  stripeCustomerUpdate,
  getAllTransaction,
  getAllContracts,
  createFreePlanSubscription,
  updateCompanyAddress,
  getUserPlan,
  modifyUserCredit,
  createFreePlan,
  getGA4NewUsersSessions,
  getTotalUser,
  getUserListByDate,
  getTotalRevenue,
  getMrrRevenue,
  getRevenueByProduct,
  getAllCustomerByPagination,
  updateAdminCompanyInfo,
  resetBellAmount,
  generateMetaAdsAccessToken,
  updateMetaAdsConnectionInfo,
  getMetaCampaigns,
  getGoogleCampaigns,
  getMetaCampaignDataById,
  getMetaCampaignDataByIdAndByDay,
  getGoogleAdsAccounts,
  setGoogleAdsCustomerAccount,
  getGoogleCampaignsDataByDate,
};

export default scaffold;
