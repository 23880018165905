import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paper: {
    background: "#f3f3f3 !important",
    border: "1px solid #e4eff2",
    height: "100%",
    borderLeft: 0,
    borderBottom: 0,
    borderTop: 0,
  },
  icon: {
    color: "white !important",
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#e6e9ed",
    },
  },
  appBar: {
    boxShadow: "none !important",
    background: "#f3f3f3 !important",
    height: "65px !important",
  },
  typographyTitle: {
    color: "#00A4CB",
    fontStyle: "italic",
    fontWeight: "700 !important",
    fontSize: "35px !important",
    marginTop: "0px !important",
    marginBottom: "20px !important",
  },
  typographyCardTitleItalic: {
    color: "#808E9E",
    fontStyle: "italic",
    fontWeight: "700 !important",
    fontSize: "24px !important",
  },
  typographyCardTitle: {
    color: "#808E9E",
    fontWeight: "700 !important",
    fontSize: "24px !important",
  },
  typographyError: {
    color: "#FF3636",
    marginBottom: "10px !important",
    marginTop: "10px",
    fontSize: "14px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  typographySubTitle: {
    color: "#808E9E",
    marginBottom: "10px !important",
  },
  typographySubTitleBold: {
    color: "#808E9E",
    marginBottom: "10px !important",
    fontWeight: "700 !important",
  },
  typographyCardSubTitle: {
    color: "#FF6464",
    fontSize: "18px !important",
    fontWeight: "700 !important",
  },
  typographyCardSubTitlePriceDescription: {
    color: "#FF6464",
    fontSize: "11px !important",
    fontWeight: "600 !important",
  },
  typographyDescription: {
    color: "#808E9E",
    fontSize: "14px",
  },
  containedButton: {
    borderRadius: "5px",
    backgroundColor: "#00a4cb !important",
    boxShadow: "none !important",
    height: "45px",
    maxWidth: "425px",
    maxHeight: "45px",
    textTransform: "none",
  },
  containedButtonSmall: {
    borderRadius: "5px",
    backgroundColor: "#00a4cb !important",
    boxShadow: "none !important",
    height: "30px",
    maxWidth: "425px",
    maxHeight: "45px",
    textTransform: "none",
  },
  outlinedButton: {
    borderRadius: "5px",
    boxShadow: "none !important",
    height: "45px",
    maxWidth: "425px",
    maxHeight: "45px",
    color: "#00a4cb !important",
    borderColor: "#00a4cb !important",
    textTransform: "none",
  },
  outlinedButtonSmall: {
    borderRadius: "5px",
    boxShadow: "none !important",
    height: "30px",
    maxWidth: "425px",
    maxHeight: "45px",
    color: "#00a4cb !important",
    borderColor: "#00a4cb !important",
    textTransform: "none",
  },
  hoverIcon: {
    "&:hover": {
      color: "red",
    },
  },
});

export default useStyles;
