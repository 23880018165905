import { classNames } from '../../../utils/class-names';

import cls from './hr.module.scss';

interface Props extends React.HTMLAttributes<HTMLHRElement> {
  className?: string;
}

const HR = (props: Props) => {
  const { className, ...rest } = props;

  return <hr className={classNames(cls.hr, className)} {...rest} />;
};

export { HR };
