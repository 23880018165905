import { FC, InputHTMLAttributes } from 'react';

import cls from './text-field.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  children?: React.ReactNode;

  iconSuccess?: boolean;
  iconError?: boolean;
}

const TextField: FC<Props> = props => {
  const {
    className,
    children,

    iconSuccess,
    iconError,

    ...rest
  } = props;

  const mods = {
    [cls.iconSuccess]: iconSuccess,
    [cls.iconError]: iconError,
  };

  const inputMods = {
    [cls.error]: iconError,
  };

  return (
    <div className={classNames(cls.textField, className, mods)}>
      <input className={classNames(cls.input, inputMods)} {...rest} />
      {children}
    </div>
  );
};

export { TextField };
