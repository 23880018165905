import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import mockedGraph from './mock.png';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useEffect, useState } from 'react';
import services from '../../../../services';
import { useSelector } from 'react-redux';
import {
  setFacebookNewFollowesDataByDateAction,
  getFacebookNewFollowersDataByDate,
} from '../../../../store/ducks/socialAnalyticsState';
import { useDispatch } from 'react-redux';
import { dateFromTimestamp } from '../../../../utils/timestamp';
import { BarChart } from '../../../bar-chart';

const GraphFacebookNewFollowers = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const newFollowersData = useSelector(getFacebookNewFollowersDataByDate);
  const [isLoading, setLoading] = useState(false);

  const getNewFollowersData = async () => {
    try {
      setLoading(true);
      const startDate = dateFromTimestamp((new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toString());
      const currentDate = dateFromTimestamp(new Date().getTime().toString());
      const response = await services.getFacebookNewFollowersData(startDate, currentDate);
      dispatch(setFacebookNewFollowesDataByDateAction(response));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (newFollowersData.length === 0) getNewFollowersData();
  }, []);

  const formattedLabel = (inputDateString: string) => {
    const date = new Date(inputDateString);
    const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
    const day = date.getDate();
    const formattedDate = `${month} ${day}`;
    return formattedDate;
  };

  const getMonitazationData = (data: any) => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Primary-Primary-400'),
      styles.getPropertyValue('--Primary-Primary'),
    ];
    return {
      labels: data.map((item: any) => formattedLabel(item.date)),
      datasets: [
        {
          label: 'New Followers',
          data: data.map((item: any) => item.data.new_followers_count),
          backgroundColor: pointColors,
          borderColor: pointColors,
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="FACEBOOK NEW FOLLOWERS BY DATE"
      total={isLoading ? 'Loading...' : ''}
    >
      <BarChart data={getMonitazationData(newFollowersData)} />
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>2%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphFacebookNewFollowers };
