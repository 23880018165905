import React, { memo } from 'react';
import { classNames } from '../../../utils/class-names';

import cls from './grid.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;

  inverted?: boolean;
}

const DashboardGrid: React.FC<Props> = memo((props: Props) => {
  const { children, className, inverted, ...rest } = props;

  const mods = {
    [cls.inverted]: inverted,
  };

  return (
    <div className={classNames(cls.grid, mods, className)} {...rest}>
      {children}
    </div>
  );
});

export { DashboardGrid };
