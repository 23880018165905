// ClosePage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../components/content-wrapper';
import services from '../../services';
import { getUserSocialConnections, updateRankworkConnectionAction } from '../../store/ducks/inSiteAppState';
import { classNames } from '../../utils/class-names';
import { Section } from '../get-help/ui/components/section';
import cls from './g-list.module.scss';
import { useNavigate } from 'react-router-dom';
// import emitter from '../../utils/emitter';

const GoogleAdsAccountSelectionPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentAccount, setCurrentAccount] = useState<any | null>(null);
  const [accounts, setAccounts] = useState<any | null>(null);

  const userSocialConnections: any = useSelector(getUserSocialConnections);

  const getAllAccs = async () => {
    try {
      setIsLoading(true);
      const response = await services.getGoogleAdsAccounts();
      console.log(response);
      setAccounts(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const connectGAdAccount = async () => {
    try {
      await services.setGoogleAdsCustomerAccount(currentAccount);
      dispatch(updateRankworkConnectionAction('google_ads', true));
    } catch (err) {
      console.log(err);
    }
  };
  const formatIDNumber = (str: string): string => {
    return str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  useEffect(() => {
    getAllAccs();

    return () => {
      setCurrentAccount(null);
    };
  }, []);

  useEffect(() => {
    if (currentAccount) connectGAdAccount();
  }, [currentAccount]);

  if (userSocialConnections && userSocialConnections?.google_ads === true) navigate('/integration');

  return (
    <ContentWrapper>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          marginLeft: 'auto',
        }}
      >
        <Section
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '30px',
            cursor: 'pointer',
            maxHeight: '100vh', // Set a maximum height
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          {!isLoading &&
            accounts &&
            accounts.map((item: any, index: any) => {
              return (
                <Section
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    marginBottom: '15px',
                    padding: '10px',
                    fontSize: '16px',
                    color: 'var(--Primary-Primary-500)',
                  }}
                  className={classNames(cls.ss)}
                  onClick={(e) => setCurrentAccount(item)}
                >
                  {item.descriptive_name} <br></br>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'var(--Primary-Primary-400)',
                    }}
                  >
                    {formatIDNumber(item.customer_account_id)}
                  </span>
                </Section>
              );
            })}
          {!isLoading && !accounts && <p>You dont have any google ads account.</p>}
          {isLoading && <p>Collecting your accounts...</p>}
        </Section>
      </div>
    </ContentWrapper>
  );
};

export default GoogleAdsAccountSelectionPage;
