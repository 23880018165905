const toTimestamp = (date: string, time: string) => {
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');

  return new Date(+year, +month - 1, +day, +hours, +minutes).getTime().toString();
};

const fromTimestamp = (timestamp: string) => {
  const date = new Date(Number(timestamp) || new Date().getTime());

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return { year, month, day, hours, minutes };
};

const timeFromTimestamp = (timestamp: string) => {
  const dateParts = fromTimestamp(timestamp);
  const { hours, minutes } = dateParts;

  return `${hours}:${minutes}`;
};

const dateFromTimestamp = (timestamp: string) => {
  const dateParts = fromTimestamp(timestamp);
  const { year, month, day } = dateParts;

  return `${year}-${month}-${day}`;
};

const howLongAgo = (timestamp: string) => {
  const date = new Date(Number(timestamp) || new Date().getTime());
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  if (seconds < 60) return `${seconds} seconds ago`;

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} minutes ago`;

  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hours ago`;

  const days = Math.floor(hours / 24);
  if (days < 7) return `${days} days ago`;

  const weeks = Math.floor(days / 7);
  if (weeks < 4) return `${weeks} weeks ago`;

  const months = Math.floor(weeks / 4);
  if (months < 12) return `${months} months ago`;

  const years = Math.floor(months / 12);
  return `${years} years ago`;
};

export { toTimestamp, fromTimestamp, timeFromTimestamp, dateFromTimestamp, howLongAgo };
