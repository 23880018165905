import { FC, TextareaHTMLAttributes } from 'react';

import cls from './text-area.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;

  value?: string;
  readOnly?: boolean;
}

const TextArea: FC<Props> = props => {
  const {
    className,

    value,
    readOnly,

    ...rest
  } = props;

  return (
    <div className={classNames(cls.textArea, className)}>
      <textarea className={classNames(cls.area)} readOnly={readOnly} {...rest} value={value} />
    </div>
  );
};

export { TextArea };
