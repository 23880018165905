// ClosePage.js
import React, { useEffect } from 'react';

const ClosePage = () => {
  useEffect(() => {
    // Attempt to close the window
    if (window.opener) {
      // Close the tab if it was opened by another window
      window.close();
    } else {
      // Optionally provide feedback if the tab cannot be closed
      alert('Unable to close this tab automatically. Please close it manually.');
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Attempting to Close Page</h1>
      <p>If this page does not close automatically, please close the tab manually.</p>
    </div>
  );
};

export default ClosePage;
