import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { Button } from '../../../button';

const GraphLinkedinPostImpressions = (props: GraphProps) => {
  const { className, ...rest } = props;

  return (
    <GraphWrapper className={className} {...rest} header="Linkedin post impressions" empty>
      {/* <TextBlock className={cls.info} centred>
        <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span>
      </TextBlock> */}
      <Button>Connect</Button>
    </GraphWrapper>
  );
};

export { GraphLinkedinPostImpressions };
