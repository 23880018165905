import { useEffect, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import cls from './payment.module.scss';
import services from '../../../../services';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkout-form';
import { ReactComponent as Icnedit } from '../../../../assets/icons/icn-edit.svg';
import { Section } from '../../../get-help/ui/components/section';
import { HR } from '../../../../components/hr';
import { Flex } from '../../../../components/flex';
import { Button } from '../../../../components/button';
import { classNames } from '../../../../utils/class-names';
import { Label } from '../../../../components/label';
import { TextField } from '../../../../components/text-field';

const STRIPE_KEY = process.env.REACT_APP_ENV_STRIPE_PUBLIC_KEY || '';

interface Props {
  setNextStep: () => void;
  data: any;
}

const Payment = (props: Props) => {
  const { setNextStep, data } = props;

  const { planInfo, priceInfo, userInfo, planType } = data;
  const [customer, setCustomer] = useState<any>(null);
  const [businessName, setBusinessName] = useState<string>(userInfo?.company?.name || '');
  const [address, setAddress] = useState<string>(userInfo?.company?.address?.line1 || '');
  const [city, setCity] = useState<string>(userInfo?.company?.address?.city || '');
  const [state, setState] = useState<string>(userInfo?.company?.address?.state || '');
  const [isLoading, setIsLoading] = useState(false);
  const stripePromise: any = loadStripe(STRIPE_KEY);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const response = await services.getStripeCustomerInfo();
      setCustomer(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(planInfo, priceInfo);
  if (!planInfo || !priceInfo || !customer) return null;

  const handleSubscribeClick = async () => {
    try {
      setIsLoading(true);
      const data = {
        price_id: priceInfo[planInfo.title].id,
        plan_id: planInfo.id,
        payment_id: customer.invoice_settings.default_payment_method,
        customer_stripe_id: customer.id,
        plan_type: planType,
      };
      await services.createStripeBasicSubscription(data);
      setIsLoading(false);
      setNextStep();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleAddPymentMethod = () => {
    setCustomer((prevCustomer: any) => ({
      ...prevCustomer,
      default_card: null,
    }));
  };

  const shouldSubscriptionDisabled = !address || !businessName || !city || !state;

  const SubscribeSection = () => {
    return (
      <Section className={cls.review}>
        <h2 className={cls.header}>Review</h2>
        <HR />
        <h3 className={cls.title}>{planInfo.title}</h3>
        <p className={cls.payment}>
          <span className={cls.price}>
            ${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}
          </span>{' '}
          / {priceInfo[planInfo.title].recurring.interval}
        </p>
        <p className={cls.description}>{planInfo.description}</p>
        <ul className={cls.featureList}>
          {planInfo.features.map((feature: any, index: number) => (
            <li className={cls.feature} key={'feature__' + index}>
              {feature}
            </li>
          ))}
        </ul>
        <HR />
        <Flex className={cls.row}>
          <span>Sub-total</span>
          <span>${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TPS</span>
          <span>$ 0.00</span>
        </Flex>
        <Flex className={cls.row}>
          <span>TVQ</span>
          <span>$ 0.00</span>
        </Flex>
        <HR />
        <Flex className={cls.row}>
          <span>Total with taxes</span>
          <span>${(priceInfo[planInfo.title].unit_amount / 100).toFixed(2)}</span>
        </Flex>
        <Flex className={cls.buttons} right>
          <Button
            primary
            onClick={handleSubscribeClick}
            disabled={isLoading || shouldSubscriptionDisabled}>
            Subscribe
          </Button>
        </Flex>
      </Section>
    );
  };

  return (
    <Flex className={classNames(cls.wrapper)}>
      {customer && customer?.default_card ? (
        <>
          <Section className={cls.billing}>
            <h2 className={cls.header}>Billing & Payment information</h2>
            <Label>
              Cardholder's full name
              <TextField
                value={userInfo?.name?.first_name + ' ' + userInfo?.name?.last_name || ''}
              />
            </Label>
            <Label>
              Business name *
              <TextField
                value={businessName}
                onChange={(event: any) => setBusinessName(event.target.value)}
              />
            </Label>
            <Label>
              Street Address *
              <TextField
                value={address}
                onChange={(event: any) => setAddress(event.target.value)}
              />
            </Label>
            <Flex>
              <Label>
                City *
                <TextField value={city} onChange={(event: any) => setCity(event.target.value)} />
              </Label>
              <Label>
                State / Province / Region *
                <TextField value={state} onChange={(event: any) => setState(event.target.value)} />
              </Label>
            </Flex>
            <Flex>
              <div className={cls.stripeWrapper}>
                <Label>Default Payment Method</Label>
                <div className={cls.stripeCard}>
                  <div className={cls.stripeCardContent}>
                    <div className={cls.cardBrand}>
                      {customer.default_card.brand.toUpperCase()},{' '}
                      {'Expires: ' + customer.default_card.exp_month}/
                      {customer.default_card.exp_year % 100}
                    </div>
                    <div className={cls.cardLast4}>
                      **** **** **** {customer.default_card.last4}
                    </div>
                  </div>
                </div>
              </div>
              <div className={cls.editCard}>
                <div className={cls.editCardContent}>
                  <Icnedit className={cls.editCardIcon} onClick={handleAddPymentMethod} />
                </div>
              </div>
            </Flex>
          </Section>
          <SubscribeSection />
        </>
      ) : (
        customer && (
          <Elements
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc: 'https://fonts.googleapis.com/css2?family=Mulish&display=swap',
                },
              ],
              mode: 'subscription',
              amount: priceInfo[planInfo.title].unit_amount, // N.B: Replace value from price object , Must be 119900 this format without decimal point
              currency: priceInfo[planInfo.title].currency, //N.B: Replace value from price object
              appearance: {
                theme: 'stripe',
                rules: {
                  '.Label': {
                    fontSize: '14px',
                    fontFamily: 'Mulish, sans-serif',
                    marginBottom: '10px',
                  },
                  '.Input': {
                    border: '1px solid #001955',
                  },
                },
                variables: {
                  borderRadius: '4px',
                  colorText: '#00003c',
                  colorTextPlaceholder: '#8c96be',
                },
              },
            }}>
            <CheckoutForm
              priceInfo={priceInfo[planInfo.title]}
              customerInfo={customer}
              paymentInfo={planInfo}
              userInfo={userInfo}
              planType={planType}
              shouldSubscriptionDisabled={shouldSubscriptionDisabled}
              setNextStep={setNextStep}
            />
          </Elements>
        )
      )}
    </Flex>
  );
};

export { Payment };
