import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem as IMenuItem } from '../../model/type/menu-item';
import { classNames } from '../../../../utils/class-names';

import cls from './sub-menu-item.module.scss';

interface Props {
  item: IMenuItem;
  currentItem?: IMenuItem;
  children?: React.ReactNode;
}

const SubmenuItem = memo((props: Props) => {
  const { item, currentItem } = props;
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(item.path);
  };

  const mods = {
    [cls.current]: currentItem === item,
  };

  return (
    <div className={classNames(cls.item, mods)} onClick={handleItemClick}>
      {item.name}
    </div>
  );
});

export { SubmenuItem };
