import { memo } from 'react';

import { Section } from '../../../get-help/ui/components/section';
import { TextBlock } from '../../../../components/text-block';
import { Label } from '../../../../components/label';
import { TextField } from '../../../../components/text-field';

import cls from './address.module.scss';
import { GeneralInformation } from '../../model/types/general-information';

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const Address = memo((props: Props) => {
  const { className, data, setData } = props;

  const handleFieldChange =
    (key: keyof GeneralInformation) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value });
    };

  return (
    <Section>
      <TextBlock header={'Address'} />
      <Label>
        Street Address
        <TextField value={data.streetAddress} onChange={handleFieldChange('streetAddress')} />
      </Label>
      <Label>
        Address Line 2
        <TextField value={data.addressLine2} onChange={handleFieldChange('addressLine2')} />
      </Label>
      <div className={cls.grid}>
        <Label>
          City
          <TextField value={data.city} onChange={handleFieldChange('city')} />
        </Label>
        <Label>
          State Province / Region
          <TextField value={data.state} onChange={handleFieldChange('state')} />
        </Label>
        <Label>
          Country
          <TextField value={data.country} onChange={handleFieldChange('country')} />
        </Label>
        <Label>
          ZIP / Postal Code
          <TextField value={data.zip} onChange={handleFieldChange('zip')} />
        </Label>
        <Label>
          Phone
          <TextField value={data.phone} onChange={handleFieldChange('phone')} />
        </Label>
        <Label>
          Email
          <TextField value={data.email} onChange={handleFieldChange('email')} />
        </Label>
      </div>
    </Section>
  );
});

export { Address };
