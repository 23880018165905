import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import services from '../../../../services';
import {
  getInstagramTotalPagePosts,
  setInstagramTotalPagePostsAction,
} from '../../../../store/ducks/instagramAnalyticsState';
import { formatSocialValue } from '../../../../utils/format-social-value';

const CardInstagramNumberOfPosts = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const totalPosts = useSelector(getInstagramTotalPagePosts);
  const [isLoading, setLoading] = useState(false);

  const getTotalInstagramFollowers = async () => {
    try {
      setLoading(true);
      const response = await services.getInstagramPageInfo();
      const count = response.data.business_discovery.media_count.toString();
      dispatch(setInstagramTotalPagePostsAction(count));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (totalPosts == '-') getTotalInstagramFollowers();
  }, []);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Number of posts"
      total={totalPosts == '-' ? totalPosts : formatSocialValue(Number(totalPosts))}
    >
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardInstagramNumberOfPosts };
