import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';
import { fetchTotalRevenue, selectTotalRevenue } from '../../../../store/ducks/admin-dashboard-state';

const CardAdminTotalRevenue = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const callsMadeData = useSelector(selectTotalRevenue);

  useEffect(() => {
    dispatch(fetchTotalRevenue());
  }, [dispatch]);

  const callsMade = useMemo(() => {
    if (!callsMadeData) return '-';
    return callsMadeData;
  }, [callsMadeData]);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Total Revenue"
      total={callsMadeData ? formatSocialValue(callsMade / 100) : '-'}
    >
      <TextBlock className={cls.info} centred></TextBlock>
    </GraphWrapper>
  );
};

export { CardAdminTotalRevenue };
