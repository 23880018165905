import { memo } from 'react';

import { TextBlock } from '../../../../components/text-block';

import cls from './splash.module.scss';
import FirstIMG from './assets/first.png';
import SecondIMG from './assets/second.png';
import { Button } from '../../../../components/button';

interface Props {
  className?: string;

  setNextStep: () => void;
}

const Splash = memo((props: Props) => {
  const { className, setNextStep } = props;

  return (
    <div className={cls.wrapper}>
      <div className={cls.color}>
        <TextBlock centred sizeXXL header="Web Design Service" />
        <TextBlock centred className={cls.textBlock}>
          Welcome to RankWorks innovative online multi-choice Web Design Service, where you have the
          power to craft a website that’s uniquely yours.
        </TextBlock>
        <img src={FirstIMG} alt="first" />
        <img src={SecondIMG} alt="second" />
        <Button onClick={setNextStep}>START BUILDING</Button>
      </div>
    </div>
  );
});

export { Splash };
