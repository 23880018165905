import axios from 'axios';
import { getAxiosConfig } from '../../../utils';

const URL = process.env.REACT_APP_ENV_BI_API_DOMAIN || '';

const postNewRequest = async (data: any) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL, '/web-design-services/survey', 'POST', headers, data),
    );
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export { postNewRequest };
