import { FC, ReactNode } from 'react';

import cls from './frame.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
}

const Frame: FC<Props> = props => {
  const { className, children, ...rest } = props;

  return (
    <div className={classNames(cls.frame, className)} {...rest}>
      {children}
    </div>
  );
};

export { Frame };
