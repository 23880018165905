import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { StarRating } from "../../components/star-rating";
import { TextBlock } from "../../components/text-block";
import cls from "./competitor-list.module.scss";
import { Icon } from "leaflet";
import CustomIcon from "../../assets/icons/icn-location.svg";
import { useEffect, useState } from "react";
import services from "../../services";

import "leaflet/dist/leaflet.css";
import { useDispatch } from "react-redux";
import {
  getCompetitorList,
  setDashboardCompetitotListAction,
} from "../../store/ducks/dashboardState";
import { classNames } from "../../utils/class-names";
import { isMobile } from "../../utils/is-mobile";
import { useSelector } from "react-redux";
interface CompetitorListProps {
  userInfo: any;
}

const customIcon = new Icon({
  iconUrl: CustomIcon,
});

const CompetitorList = (props: CompetitorListProps) => {
  const { userInfo } = props;
  const dispatch = useDispatch();
  let competitorList = useSelector(getCompetitorList);

  const defaultCoordinates: any = [
    userInfo?.company?.primary_service_area?.geo?.lat || 40.7128,
    userInfo?.company?.primary_service_area?.geo?.lng || -73.935242,
  ];

  const getCompetitors = async () => {
    const data = {
      primary_category: userInfo.company.primary_category,
      primary_service_area: userInfo.company.primary_service_area,
    };

    const response = await services.getCompetitorsByIndustryType(data);

    if (response.hasOwnProperty("id")) {
      const serpId = response.id;
      const serpResponse = await services.getCompetitorsBySerpId(serpId);
      //setCompetitorList(serpResponse.competitors);
      dispatch(setDashboardCompetitotListAction(serpResponse.competitors));
    } else {
      //setCompetitorList(response.competitors);
      dispatch(setDashboardCompetitotListAction(response.competitors));
    }
  };

  useEffect(() => {
    if (userInfo?.company && competitorList.length === 0) getCompetitors();
  }, [userInfo]);

  const checkIfCurrentCompany = (companyName: string) => {
    if (userInfo?.company?.name?.toLowerCase() === companyName.toLowerCase()) {
      return true;
    }
    return false;
  };

  const renderCompetitorInner = (item: any) => {
    return (
      <>
        <div
          className={classNames(
            cls.name,
            classNames(
              checkIfCurrentCompany(item.title) ? cls.currentCompany : ""
            )
          )}
        >
          {item.title}
        </div>
        <div
          className={classNames(
            cls.rating,
            classNames(
              checkIfCurrentCompany(item.title) ? cls.currentCompany : ""
            )
          )}
        >
          {item.rating}
        </div>
        <div
          className={classNames(
            cls.star,
            classNames(
              checkIfCurrentCompany(item.title) ? cls.currentCompany : ""
            )
          )}
        >
          <StarRating value={item.rating} id="1" />
        </div>
        <div
          className={classNames(
            cls.review,
            classNames(
              checkIfCurrentCompany(item.title) ? cls.currentCompany : ""
            )
          )}
        >
          ({item.review})
        </div>
      </>
    );
  };

  const renderCompetitor = (item: any) => {
    if (!isMobile()) return renderCompetitorInner(item);

    return (
      <div className={cls.mobileWrapper} key={item.name}>
        {renderCompetitorInner(item)}
      </div>
    );
  };

  return (
    <div className={cls.competitorsWrapperRoot}>
      <TextBlock header="Here’s the top 10 competitors within your category." />
      <div className={cls.competitorsWrapper}>
        <div className={cls.competitors}>
          <div className={cls.innerWrapper}>
            <div className={cls.competitorList}>
              {competitorList.map((item: any) => renderCompetitor(item))}
              {competitorList.length === 0 && (
                <div className={cls.competitorsWrapper}>
                  <TextBlock className={cls.myRanking}>
                    We are collecting your competitor data. Please check back
                    later.
                  </TextBlock>
                </div>
              )}
            </div>
          </div>
        </div>
        <MapContainer
          className={cls.map}
          center={defaultCoordinates}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer url="https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=xcvFjvkJ3KHSA4Q3IlQVbeqgHXvK7sa3-wlFKX7-SFc" />
          <Marker position={defaultCoordinates} icon={customIcon}>
            <Popup>{userInfo?.company?.name || "You"}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export { CompetitorList };
