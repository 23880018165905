import { AnyAction } from 'redux';
import services from '../../services';

interface ADSDashboardState {
  metaCampaigns: any;
  googleCampaigns: any;
}

export const SET_META_CAMPAIGNS = 'SET_META_CAMPAIGNS';
export const SET_GOOGLE_CAMPAIGNS = 'SET_GOOGLE_CAMPAIGNS';

const initialState: ADSDashboardState = {
  metaCampaigns: null,
  googleCampaigns: null,
};

/* -------------------------------------------------------------------------- */
/*                           interfaces for actions                           */
/* -------------------------------------------------------------------------- */
export interface SetMetaCampaignInterface extends AnyAction {
  type: typeof SET_META_CAMPAIGNS;
  metaCampaigns: any;
}
export interface SetGoogleCampaignInterface extends AnyAction {
  type: typeof SET_GOOGLE_CAMPAIGNS;
  googleCampaigns: any;
}

export type ActionType = SetMetaCampaignInterface | SetGoogleCampaignInterface;

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export const setMetaCampaignAction = (metaCampaigns: any): SetMetaCampaignInterface => ({
  metaCampaigns,
  type: SET_META_CAMPAIGNS,
});
export const setGoogleCampaignAction = (googleCampaigns: any): SetGoogleCampaignInterface => ({
  googleCampaigns,
  type: SET_GOOGLE_CAMPAIGNS,
});

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
export const adsDashboardReducer = (state = initialState, action: ActionType): any => {
  switch (action.type) {
    case SET_META_CAMPAIGNS:
      return { ...state, metaCampaigns: action.metaCampaigns };
    case SET_GOOGLE_CAMPAIGNS:
      return { ...state, googleCampaigns: action.googleCampaigns };
    default:
      return state;
  }
};

/* -------------------------------------------------------------------------- */
/*                               Thunk Actions                                */
/* -------------------------------------------------------------------------- */
export const getMetaAdsCampaigns = (): any => async (dispatch: any, getState: any) => {
  try {
    const response = await services.getMetaCampaigns();
    const metaCampaignData = response?.data || null;
    // const metaCampaignData: any = [
    //   {
    //     name: 'Investiprise New Leads Campaign',
    //     status: 'ACTIVE',
    //     objective: 'OUTCOME_LEADS',
    //     start_time: '2024-01-29T10:30:28-0500',
    //     id: '120204134672130491',
    //   },
    //   {
    //     name: 'TT',
    //     status: 'ACTIVE',
    //     objective: 'OUTCOME_LEADS',
    //     start_time: '2024-01-29T10:30:28-0500',
    //     id: '120204134672130492',
    //   },
    // ];
    if (metaCampaignData) {
      // Use map to create an array of promises
      const promises = metaCampaignData.map(async (element: any) => {
        if (element.status === 'ACTIVE') {
          try {
            const res = await services.getMetaCampaignDataById(element.id);
            const res_date = await services.getMetaCampaignDataByIdAndByDay(element.id);
            element['data'] = res?.data || [];
            element['data_by_date'] = res_date?.data || [];
          } catch (error) {
            element['data'] = [];
            element['data_by_date'] = [];
          }
        } else {
          element['data'] = [];
          element['data_by_date'] = [];
        }
      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      // Dispatch action after all async operations are completed
      await dispatch(setMetaCampaignAction(metaCampaignData));
    }

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getGoogleAdsCampaigns = (): any => async (dispatch: any, getState: any) => {
  try {
    const response = await services.getGoogleCampaigns();
    const response_data = await services.getGoogleCampaignsDataByDate();

    response_data.forEach((element: any) => {
      const index = response.findIndex((responseElement: any) => responseElement.id === element.id);
      if (index !== -1) {
        response[index].data_by_date = element; // Add the whole element as the value of `data_by_date`
      } else {
        response[index].data_by_date = {};
      }
    });
    dispatch(setGoogleCampaignAction(response));
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

/* -------------------------------------------------------------------------- */
/*                                  selectors                                 */
/* -------------------------------------------------------------------------- */

export const selectMetaCampaigns = (state: any) => state.adsDashboard.metaCampaigns;
export const selectGoogleCampaigns = (state: any) => state.adsDashboard.googleCampaigns;
