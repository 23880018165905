import { memo } from 'react';

import { Flex } from '../../../../components/flex';
import { GeneralInformation } from '../../model/types/general-information';

import cls from './inspiration.module.scss';
import { Section } from '../../../get-help/ui/components/section';
import { TextArea } from '../../../../components/text-area';
import { Label } from '../../../../components/label';
import { TextBlock } from '../../../../components/text-block';
import { TextField } from '../../../../components/text-field';
import { Button } from '../../../../components/button';

const MAX_LENGTH = 300;

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const Inspiration = memo((props: Props) => {
  const { className, data, setData } = props;

  const handleThingsChange = (value: string, index: number) => {
    const thingsYouLike = [...data.thingsYouLike];
    thingsYouLike[index] = value;
    setData({ ...data, thingsYouLike });
  };

  const handleAddInspiration = () => {
    setData({
      ...data,
      inspirations: [...data.inspirations, { companyName: '', comments: '', url: 'https://' }],
    });
  };

  return (
    <>
      <TextBlock header="Who is your inspiration? Add as many as you’d like." />
      <Flex className={cls.inspirationList}>
        {data.inspirations.map((inspiration, index) => (
          <Section className={cls.inspiration} key={index}>
            <TextField
              value={inspiration.companyName}
              onChange={e => {
                const inspirations = [...data.inspirations];
                inspirations[index].companyName = e.target.value;
                setData({ ...data, inspirations });
              }}
              placeholder="Company name"
            />
            <Label>
              <span className={cls.labelWithCounter}>
                <span>Any comments to share with us?</span>
                <span className={cls.counter}>
                  {inspiration.comments.length || 0}&nbsp;/&nbsp;{MAX_LENGTH}
                </span>
              </span>
              <TextArea
                className={cls.areaField}
                value={inspiration.comments}
                onChange={e => {
                  const inspirations = [...data.inspirations];
                  inspirations[index].comments = e.target.value;

                  if (e.target.value.length > MAX_LENGTH) return;

                  setData({ ...data, inspirations });
                }}
              />
            </Label>
            <Label>
              Website URL
              <TextField
                value={inspiration.url}
                onChange={e => {
                  const inspirations = [...data.inspirations];
                  inspirations[index].url = e.target.value;
                  setData({ ...data, inspirations });
                }}
              />
            </Label>
          </Section>
        ))}
      </Flex>

      <Button className={cls.addMore} onClick={handleAddInspiration}>
        Add more
      </Button>

      <Section className={cls.thingsYouLike}>
        <TextBlock>What type of things do you not want to see on your site?</TextBlock>
        {data.thingsYouLike.map((thing, index) => (
          <TextField
            key={index}
            value={thing}
            onChange={evt => handleThingsChange(evt.target.value, index)}
          />
        ))}
        <Button onClick={() => setData({ ...data, thingsYouLike: [...data.thingsYouLike, ''] })}>
          Add more
        </Button>
      </Section>
    </>
  );
});

export { Inspiration };
