interface Props {
  value: number | string;
  id: string;
}

const StarRating = (props: Props) => {
  const { value, id } = props;

  const percent = ((Number(value) || 0) / 5) * 100;

  return (
    <div>
      <svg width="80" height="16" viewBox="0 0 80 16">
        <defs>
          <mask id={'value' + id}>
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <rect x={`${percent}%`} y="0" width="100%" height="100%" fill="black" />
          </mask>
          <mask id={'inverted' + id}>
            <rect x="0" y="0" width="100%" height="100%" fill="black" />
            <rect x={`${percent}%`} y="0" width="100%" height="100%" fill="white" />
          </mask>

          <symbol viewBox="0 0 16 16" id="star">
            <path d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z" />
          </symbol>
          <symbol viewBox="0 0 80 16" id="stars">
            <use xlinkHref="#star" x="-32" y="0"></use>
            <use xlinkHref="#star" x="-16" y="0"></use>
            <use xlinkHref="#star" x="0" y="0"></use>
            <use xlinkHref="#star" x="16" y="0"></use>
            <use xlinkHref="#star" x="32" y="0"></use>
          </symbol>
        </defs>

        <use xlinkHref="#stars" fill="none" stroke="gray" mask={`url(#inverted${id})`}></use>
        <use xlinkHref="#stars" fill="#FFB900" stroke="#FFB900" mask={`url(#value${id})`}></use>
      </svg>
    </div>
  );
};

export { StarRating };
