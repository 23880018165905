import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import infoLogo from '../../assets/info.png';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import './InSiteInformationDialouge.scss';

interface InSiteInformationDialougeProps {
  openStatus: boolean;
  title: string;
  content: any;
  setInformationDialouge: any;
}

export default function InSiteInformationDialouge({
  openStatus,
  content,
  title,
  setInformationDialouge,
}: InSiteInformationDialougeProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = async () => {
    setOpen(false);
    setInformationDialouge(false);
  };

  React.useEffect(() => {
    if (openStatus) setOpen(true);
    return () => {
      setOpen(false);
    };
  }, [openStatus]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ marginLeft: '5px', marginTop: '5px' }}>
          <img src={infoLogo} alt="Logo" height={25} width={25}></img>
        </div>
        <div className="info_close_icon_wrapper">
          <CloseIcon onClick={handleClose} className="info_close_icon" />
        </div>
      </div>
      <DialogContent style={{ marginBottom: '30px' }}>
        <div
          style={{
            display: 'flex',
          }}>
          <Typography
            style={{
              color: '#4C505F',
              fontWeight: 700,
              marginBottom: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {title}
          </Typography>
        </div>
        {content}
      </DialogContent>
    </Dialog>
  );
}
