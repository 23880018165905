export interface GeneralInformation {
  businessName: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  currentWebsiteUrl: string;
  currentWebHostingCompany: string;

  streetAddress: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  email: string;
  // additional information
  description: string;
  wishes: string;
  desired: string;
  audience: string;
  competitors: string;
  similar: string;
  // aesthetics
  hasStyleGuide: boolean;
  printMaterial: any[];
  usedFonts: any[];
  // colors
  colors: any[];

  hasGoogleAnalytics: boolean;

  keyWords: string[];

  inspirations: { companyName: string; comments: string; url: string }[];
  thingsYouLike: string[];
}

export const initialState: GeneralInformation = {
  businessName: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  currentWebsiteUrl: 'http://',
  currentWebHostingCompany: '',

  streetAddress: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  phone: '',
  email: '',

  description: '',
  wishes: '',
  desired: '',
  audience: '',
  competitors: '',
  similar: '',

  hasStyleGuide: true,
  printMaterial: [],
  usedFonts: [],

  colors: [{ a: 1, b: 80, g: 27, r: 25 }],

  hasGoogleAnalytics: false,

  keyWords: [],

  inspirations: [{ companyName: '', comments: '', url: 'https://' }],
  thingsYouLike: [''],
};
