import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import { fetchUserListByDate, selectUserList } from '../../../../store/ducks/admin-dashboard-state';
import { useDispatch } from 'react-redux';
import { BarChart } from '../../../bar-chart';
import { useEffect, useState } from 'react';

const GraphAdminNewUsersByDate = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const newUsers = useSelector(selectUserList);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (newUsers.length === 0) dispatch(fetchUserListByDate());
  }, []);

  const generateGraphData = (tempData: any) => {
    const formattedData = tempData.map((item: any) => {
      const date = new Date(item.created_at);
      const options: any = { day: 'numeric', month: 'short' };
      const formattedDate = date.toLocaleDateString('en-GB', options);
      return { ...item, created_at: formattedDate };
    });

    const getLast30DaysObject = () => {
      const result: any = {};
      const today = new Date();

      for (let i = 0; i < 30; i++) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        const options: any = { day: 'numeric', month: 'short' };
        const formattedDate = pastDate.toLocaleDateString('en-GB', options);
        result[formattedDate] = 0;
      }

      return result;
    };

    const last30DaysObject = getLast30DaysObject();

    formattedData.forEach((element: any) => {
      last30DaysObject[element.created_at] = last30DaysObject[element.created_at] + 1;
    });

    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Primary-Primary-400'),
      styles.getPropertyValue('--Primary-Primary'),
    ];

    return {
      labels: Object.entries(last30DaysObject).map(([date, value]) => date),
      datasets: [
        {
          label: 'New Users',
          data: Object.entries(last30DaysObject).map(([date, value]) => value),
          backgroundColor: pointColors,
          borderColor: pointColors,
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <GraphWrapper className={className} {...rest} header="NEW USERS BY DATE" total={isLoading ? 'Loading...' : ''}>
      {/* <img className={cls.mockedGraph} src={mockedGraph} alt="Mock" /> */}
      <BarChart data={generateGraphData(newUsers)} />
      <TextBlock className={cls.info} centred>
        <span className={cls.inc}>77%</span>
        <span className={cls.text}>decrease</span>
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphAdminNewUsersByDate };
