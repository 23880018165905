import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { getUserRole } from '../../../../store/ducks/inSiteAppState';
import { MenuItem as IMenuItem } from '../../model/type/menu-item';
import { menuList, adminMenuList, mobileMenuList, shopifyMenuList } from '../../model/config/menu-list';
import { MenuItem } from '../menu-item/menu-item';
import { SubmenuItem } from '../sub-menu-item/sub-menu-item';
import cls from './nav-bar-ic.module.scss';
import { isMobile } from '../../../../utils/is-mobile';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import { Divider } from '@mui/material';

interface Props {
  setMenuOpen?: (isOpen: boolean) => void;
}

const NavBarIc = memo((props: Props) => {
  const { setMenuOpen } = props;

  let pathName = window.location.pathname.split('/');
  const role = useSelector(getUserRole);

  let currentMenuList = shopifyMenuList;
  if (role === 'customer' && isMobile()) {
    currentMenuList = mobileMenuList;
  }
  if (role === 'admin') {
    currentMenuList = adminMenuList;
  }

  const currentItem =
    currentMenuList.find((item) => item.path === '/' + pathName[1]) ||
    (role === 'customer' ? menuList[1] : menuList[0]);
  const currentSubItem = currentItem.subMenuItems?.find((item) => item.path === '/' + pathName[1] + '/' + pathName[2]);

  const handleMenuClose = () => {
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  };

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const theme = useTheme();
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />

      {currentMenuList.map((item: IMenuItem) => {
        return (
          <MenuItem key={item.path} item={item} currentItem={currentItem}>
            {item.subMenuItems?.map((subItem: IMenuItem) => {
              return <SubmenuItem key={subItem.path} item={subItem} currentItem={currentSubItem} />;
            })}
          </MenuItem>
        );
      })}
    </Box>
  );

  return (
    <>
      <div className={cls.listIcons} onClick={toggleDrawer(true)}>
        {currentMenuList.map((item: IMenuItem) => {
          const Icon = item.Icon;
          return Icon && <Icon />;
        })}
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)} BackdropProps={{ invisible: true }}>
        {DrawerList}
      </Drawer>
    </>
  );
});

export { NavBarIc };
