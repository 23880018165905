import { memo } from 'react';

import cls from './section.module.scss';
import { classNames } from '../../../../../utils/class-names';

interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  children: React.ReactNode;
}

const Section = memo((props: Props) => {
  const { className, children, ...rest } = props;

  return (
    <section className={classNames(cls.section, className)} {...rest}>
      {children}
    </section>
  );
});

export { Section };
