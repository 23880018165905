import { classNames } from '../../../utils/class-names';

import cls from './breadcrumbs.module.scss';

interface Props {
  className?: string;

  crumbs: Array<{
    label: string;
    isCurrent?: boolean;

    href?: string;
  }>;
}

const Breadcrumbs = (props: Props) => {
  const { className, crumbs } = props;

  if (!crumbs.length) return null;

  return (
    <ul className={classNames(cls.breadcrumbs, className)}>
      {crumbs.map((crumb, i) => {
        const { isCurrent, label, href } = crumb;

        return (
          <li key={i} className={classNames(cls.item, { [cls.current]: isCurrent })}>
            {label}
          </li>
        );
      })}
    </ul>
  );
};

export { Breadcrumbs };
