import React, { memo } from 'react';
import cls from './button.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  transparent?: boolean;
  closeButton?: boolean;
  icon?: React.FC;
}

const Button: React.FC<Props> = memo((props: Props) => {
  const { children, className, primary, transparent, closeButton, icon: Icon, ...rest } = props;

  const mods = {
    [cls.primary]: primary,
    [cls.transparent]: transparent,
    [cls.closeButton]: closeButton,
    [cls.icon]: !!Icon,
  };

  return (
    <button className={classNames(cls.button, mods, className)} {...rest}>
      {!closeButton && children}
      {Icon && <Icon />}
    </button>
  );
});

export { Button };
