import { useEffect, useState } from "react";
import { ContentWrapper } from "../../components/content-wrapper";
import { HR } from "../../components/hr";
import { TextBlock } from "../../components/text-block";
import { PageHeader } from "../../components/page-header";
import cls from "./dashboard-by-id.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfo,
  setUserInfoAction,
} from "../../store/ducks/inSiteAppState";
import { useParams } from "react-router-dom";
import services from "../../services";
import { Default } from "../dashboard-page/ui/default/default";
import { ECommerce } from "../dashboard-page/ui/e-commerc/e-commerce";
import {
  resetDashboardAction,
  setDashboardCompetitotListAction,
} from "../../store/ducks/dashboardState";

export default function DashboardById() {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const { id } = useParams(); // customer id from url
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const copyUserCompanyInfo = async () => {
    try {
      setIsLoading(true);
      const res = await services.updateAdminCompanyInfo(id);
      setIsCopied(true);
      setIsLoading(false);
      return Promise.resolve();
    } catch (e: any) {
      setErrorMessage(e?.response?.data?.detail || "An error occured!");
      setIsLoading(false);
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      let response = await services.getAccountInfo();
      dispatch(setUserInfoAction(response));
    };
    if (isCopied === true) getInfo();
  }, [isCopied]);

  useEffect(() => {
    dispatch(resetDashboardAction());
    dispatch(setDashboardCompetitotListAction([]));
    copyUserCompanyInfo();
  }, []);

  return (
    <ContentWrapper>
      <PageHeader>Dashboard</PageHeader>
      <HR />
      <div className={cls.wrapper}>
        {isCopied && userInfo?.company?.is_ecommerce === false && <Default />}
      </div>
      <div className={cls.wrapper}>
        {isCopied && userInfo?.company?.is_ecommerce === true && <ECommerce />}
      </div>
    </ContentWrapper>
  );
}
