import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '../../../components/page-header';
import { ContentWrapper } from '../../../components/content-wrapper';
import { Breadcrumbs } from '../../../components/breadcrumbs';
import { HR } from '../../../components/hr';
import { Plan } from '../../../components/plan/plan';
import { Payment } from '../../../components/payment';
import { PlanWrapper } from '../../../components/plan/plan-wrapper';
import services from '../../../services';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/ducks/inSiteAppState';
import { setCheckoutInfoAction } from '../../../store/ducks/paymentState';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../router';
import { modifiedPlanList } from '../../../utils';

const Seo = () => {
  const [newSubscriber, setNewSubscriber] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPrice, setCurrentPrice] = useState<any>(null);
  const [seoPlanList, setseoPlanList] = useState([]);
  const [step, setStep] = useState(1);
  const userInfo: any = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath === AppRoute.SEO_PAYMENT) {
      const data = {
        planInfo: currentPlan,
        priceInfo: currentPrice,
        userInfo: userInfo,
        planType: 'seo',
      };
      dispatch(setCheckoutInfoAction(data));
      setStep(2);
    } else {
      setStep(1);
    }
  }, [currentPath]);

  const handlePlanSelect = (value: any) => {
    setCurrentPlan(value);
    navigate(AppRoute.SEO_PAYMENT);
  };

  const handlePriceSelect = (planName: string, value: any) => {
    setCurrentPrice((prevPrice: any) => ({
      ...prevPrice,
      [planName]: value,
    }));
  };

  const crumbs = useMemo<{ label: string; isCurrent?: boolean }[]>(
    () => [
      { label: 'Search Engine Optimization' },
      { label: 'Plans', isCurrent: step === 1 },
      { label: 'Summary & review', isCurrent: step === 2 },
    ],
    [step]
  );

  const getSEOPlans = async () => {
    try {
      const response = await services.getAllStripePlan('seo');
      const [modifiedList, isNewSubscriber, initialPriceObject] = modifiedPlanList(response);
      setseoPlanList(modifiedList);
      setCurrentPrice(initialPriceObject);
      setNewSubscriber(isNewSubscriber);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSEOPlans();
  }, []);

  return (
    <ContentWrapper>
      <Breadcrumbs crumbs={crumbs} />
      <PageHeader>
        {step === 1 && 'Search Engine Optimization'}
        {step === 2 && 'Payment'}
      </PageHeader>
      <HR />
      {step === 1 && (
        <PlanWrapper>
          {seoPlanList &&
            currentPrice &&
            seoPlanList.map((planStructure: any) => (
              <Plan
                key={planStructure.id}
                isNewSubscriber={newSubscriber}
                isSubscribed={planStructure.isSubscribed}
                isUpgradable={planStructure.isUpgradable}
                currentPlan={'seo'}
                onPlanSelect={handlePlanSelect}
                onPriceSelect={handlePriceSelect}
                planStructure={planStructure}
                currentPrice={currentPrice}
              />
            ))}
        </PlanWrapper>
      )}
      {step === 2 && <Payment />}
    </ContentWrapper>
  );
};

export { Seo };
