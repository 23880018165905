import { memo } from 'react';

import cls from './flex.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;

  center?: boolean;
  left?: boolean;
  right?: boolean;
  vertical?: boolean;
  spaceBetween?: boolean;

  children?: React.ReactNode;
}

const Flex = memo((props: Props) => {
  const { className, children, center, left, right, vertical, spaceBetween, ...rest } = props;

  const mods = {
    [cls.center]: center,
    [cls.left]: left,
    [cls.right]: right,
    [cls.vertical]: vertical,
    [cls.spaceBetween]: spaceBetween,
  };

  return (
    <div className={classNames(cls.flex, className, mods)} {...rest}>
      {children}
    </div>
  );
});

export { Flex };
