import axios from 'axios';
import { getAxiosConfig } from '../utils';

const URL_BI = process.env.REACT_APP_ENV_BI_API_DOMAIN || '';

const getGA4NewUsersDataByCountry = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '1',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/demographics/country', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4ActiveUsersByDevice = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '1',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/tech/device_category', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4NewUsersDataByDay = async (email: string, cumulative: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: cumulative,
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/user/first_user_default_channel_group', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGA4EngagementData = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '1',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/engagement/pages_and_screens/content_group', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
//-----------------------------------------------
const getMonetizationOverview = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/monetization/overview', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMonetizationPurchasesByItems = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };
  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/monetization/ecommerce_purchases/item_name', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserSource = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/user/first_user_source', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAddToCart = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/engagement/events/add_to_cart', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getEventPurchase = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/engagement/events/purchase', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getEventBeginCheckout = async (email: string, enc: any, iv: any) => {
  const headers = {
    accept: 'application/json',
    enc: enc,
    iv: iv,
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/engagement/events/begin_checkout', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// -------------------------------------------------
// paid dashboard
const getSessionSource = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/traffic/session_source', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPhoneCalls = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/engagement/call_button', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getSessionChannels = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(
      getAxiosConfig(URL_BI, '/acquisition/traffic/session_default_channel_group', 'POST', headers, data)
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getFormCompletion = async (email: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    email: email,
    is_cumulative: '0',
    start_date: '30daysAgo',
    end_date: 'today',
  };

  try {
    const response = await axios.request(getAxiosConfig(URL_BI, '/engagement/form_completion', 'POST', headers, data));

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ga4Service = {
  getGA4NewUsersDataByCountry,
  getGA4ActiveUsersByDevice,
  getGA4NewUsersDataByDay,
  getGA4EngagementData,
  // e-commerce dashboard
  getMonetizationOverview,
  getMonetizationPurchasesByItems,
  getUserSource,
  getAddToCart,
  getEventPurchase,
  getEventBeginCheckout,
  // paid dashboard
  getSessionSource,
  getPhoneCalls,
  getSessionChannels,
  getFormCompletion,
};

export default ga4Service;

// -----------------------------------------------
// for e-commerce dashboard
// 1 total sales $
// 2 total orders
// 2 store sessions by day
// 3 total customers : activeUsers
// 3 returning customers : activeUsers - newUsers
// 2 orders by day
// 4 added to cart : ?
// 6 initiated checkout
// 3 customers over time : activeUsers
// 2 top selling products
// abandoned carts : 4 - 5
// 5 total purchases
// 3 session by traffic source : group by firstUserSource activeUsers
// 1 sales by day $

// 1 /monetization/overview
// 2 /monetization/ecommerce_purchases/item_name
// 3 /acquisition/user/first_user_source
// 4 /engagement/events/add_to_cart
// 5 /engagement/events/purchase
// 6 /engagement/events/begin_checkout

// -----------------------------------------------
// for paid dashboard
// 1 website visits: totalUsers
// 2 phone calls: totalUsers
// 3 traffic acquisition per channel group: sessionDefaultChannelGroup, totalUsers
// bounce rate: <<read below>>
// error card
// 1 session source: sessionSource, totalUsers
// - google rating - already implemented
// 4 numbers of form completions: totalUsers
// 1 users over time: totalUsers

// 1 /acquisition/traffic/session_source
// 2 /engagement/call_button
// 3 acquisition/traffic/session_default_channel_group
// 4 /engagement/form_completion

// bounce rate is the percentage of sessions that were not engaged
// (sum(sessions)-sum(engagedSessions))/sum(sessions)*100
