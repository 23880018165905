import { GeneralInformation } from '../types/general-information';

const adoptInfoToServer = (info: GeneralInformation) => {
  const adoptedInfo = {
    contact_information: '',
    address: '',
    additional_information: '',
    asthetics: '',
    inspiration: '',
  };

  adoptedInfo.contact_information = JSON.stringify({
    business_name: info.businessName,
    first_name: info.firstName,
    last_name: info.lastName,
    job_title: info.jobTitle,
    current_website_url: info.currentWebsiteUrl,
    current_web_hosting_company: info.currentWebHostingCompany,
  });
  adoptedInfo.address = JSON.stringify({
    street_address: info.streetAddress,
    address_line_2: info.addressLine2,
    city: info.city,
    state: info.state,
    country: info.country,
    zip: info.zip,
    phone: info.phone,
    email: info.email,
  });
  adoptedInfo.additional_information = JSON.stringify({
    description: info.description,
    wishes: info.wishes,
    desired: info.desired,
    audience: info.audience,
    competitors: info.competitors,
    similar: info.similar,
  });
  adoptedInfo.asthetics = JSON.stringify({
    has_style_guide: info.hasStyleGuide,
    print_material: info.printMaterial,
    used_fonts: info.usedFonts,
    colors: info.colors,
    has_google_analytics: info.hasGoogleAnalytics,
    key_words: info.keyWords,
  });
  adoptedInfo.inspiration = JSON.stringify({
    inspirations: info.inspirations,
    things_you_like: info.thingsYouLike,
  });

  return adoptedInfo;
};

export { adoptInfoToServer };
