import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';

import { ReactComponent as IconError } from '../../../../assets/icons/icn-error.svg';

const GraphCriticalErrors = (props: GraphProps) => {
  const { className, ...rest } = props;

  return (
    <GraphWrapper className={className} critical {...rest}>
      <IconError />
      <TextBlock bold>29 critical errors</TextBlock>
      <TextBlock>Lorem ipsum dolor sit amet, consectetur adipiscing.</TextBlock>
    </GraphWrapper>
  );
};

export { GraphCriticalErrors };
