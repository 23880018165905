import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../components/button';
import services from '../../services';
import { getUserInfo, setUserInfoAction } from '../../store/ducks/inSiteAppState';
import { getTagManagerScript } from '../../utils';
import { TextBlock } from '../../components/text-block';
import Creatable from 'react-select/creatable';
import { Radio } from '../../components/radio';
import { TextField } from '../../components/text-field';
import { Label } from '../../components/label';
import cls from './business-registration.module.scss';
import { Frame } from '../../components/frame';
import { Modal } from '../../components/modal';
import { ReactComponent as IcnCopy } from '../../assets/icons/icn-copy.svg';
import { SelectOption } from '../../components/select/ui/select';
import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { categories } from '../../constants';
import AsyncSelect from 'react-select/async';
import useDebounceValidation from '../../hooks/useDebounceValidation/useDebounceValidation';
import InSiteSnackBar from '../InSIteSnackBar/InSiteSnackBar';
import { InsiteAppPlans } from '../insite-app-plans';

interface Props {
  onClick: () => void;
  defaultPageNo: number;
  businessCategories?: SelectOption<string>[];
  noOverlay: boolean;
  gray: boolean;
}

interface OptionType {
  value: string;
  label: string;
}

const BusinessRegistrationModal = memo((props: Props) => {
  const { defaultPageNo, businessCategories, onClick, noOverlay, gray } = props;
  // const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSkipped = localStorage.getItem('isSkipped');
  const userInfo: any = useSelector(getUserInfo);
  const [value, setValue] = useState('e-commerce');
  const [companyName, setCompanyName] = useState<string>('');
  const [webUrl, setWebUrl] = useState<string>('');
  const [gtmCode, setGtmCode] = useState<string>('');
  const [industryType, setIndustryType] = useState<OptionType | null>(null);
  const [serviceArea, setServiceArea] = useState<any | null>(null);
  const [pageNo, setPageNo] = useState(defaultPageNo);
  const [isCopied, setIsCopied] = useState(false);
  const [serviceAreaList, setServiceAreaList] = useState([]);
  const [isValidURL, setIsValidURL] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  let { debouncedServiceCall } = useDebounceValidation({
    setFn: setWebUrl,
  });

  let industryTypeList: any = [];
  categories.forEach((element: string) => {
    const item = {
      value: element,
      label: element,
    };
    industryTypeList.push(item);
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleSubmitBusinessInfo = async () => {
    try {
      setIsLoading(true);
      let modifiedWebUrl = webUrl;
      if (!webUrl.startsWith('http://') && !webUrl.startsWith('https://')) modifiedWebUrl = 'https://' + webUrl;
      let data = {
        name: companyName,
        is_ecommerce: value === 'e-commerce' ? true : false,
        website: modifiedWebUrl,
        primary_category: industryType?.value || '',
        primary_service_area: serviceArea,
      };

      const res = await services.createGTMContainer(data);
      if (res.hasOwnProperty('gtm_code')) {
        setGtmCode(res.gtm_code);
      } else {
        setErrorMessage('An error occured!');
      }
      setIsLoading(false);
      userInfo?.app_plan ? setPageNo(2) : setPageNo(3);
      return Promise.resolve();
    } catch (e: any) {
      setErrorMessage(e?.response?.data?.detail || 'An error occured!');
      setIsLoading(false);
      return Promise.reject(e);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getTagManagerScript(userInfo));
    setIsCopied(true);
  };

  const handleSkipBusinessInfo = () => {
    localStorage.setItem('isSkipped', 'true');
    userInfo?.app_plan ? navigate('/') : setPageNo(3);
  };

  const handleCloseBusinessInfo = () => {
    onClick();
    navigate('/');
  };

  const fetchOptions = async (inputValue: string, callback: (options: any) => void) => {
    try {
      let serviceAreas: any = [];
      const response = await services.getServiceArea(inputValue, 10);
      response.forEach((element: any) => {
        const item = {
          label: element.city + ', ' + element.state,
          value: element._id,
        };
        serviceAreas.push(item);
      });
      setServiceAreaList(response);
      return serviceAreas;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  };

  const handleIndustryTypeChange = (selectedOption: OptionType | null) => {
    setIndustryType(selectedOption);
  };

  const handleServiceAreaChange = (selectedOption: OptionType | null) => {
    if (selectedOption === null) {
      setServiceArea(null);
    } else {
      serviceAreaList.forEach((item: any) => {
        if (selectedOption && item._id === selectedOption.value) setServiceArea(item);
      });
    }
  };

  const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedServiceCall(event.target.value);
  };

  const checkWebUrl = async (url: string) => {
    try {
      await services.checkWebUrlValidity(url);
      setIsValidURL(true);
      return Promise.resolve();
    } catch (error: any) {
      setIsValidURL(false);
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (webUrl) checkWebUrl(webUrl);
  }, [webUrl]);

  useEffect(() => {
    const getUpdatedUserInfo = async () => {
      setTimeout(async () => {
        let res = await services.getAccountInfo();
        dispatch(setUserInfoAction(res));
      }, 2000); // 2 seconds
    };
    if (pageNo == 2) getUpdatedUserInfo();
  }, [pageNo]);

  let intro = `Copy the code below and paste it onto every page of your website.
  Paste this code as high in the <head> of the page as possible:`;

  return (
    <div className="BusinessRegistration__wrapper">
      {pageNo === 0 && (
        <Modal sizeL noOverlay gray={gray} sizeS setOpen={onClick}>
          <Frame>
            <div className="BusinessRegistration__page0">
              <TextBlock centred header="Complete Your Setup" />
            </div>
          </Frame>
          <Button onClick={() => setPageNo(1)}>Next</Button>
        </Modal>
      )}
      {pageNo === 1 && (
        <Modal noOverlay={noOverlay} gray={gray} sizeM setOpen={onClick}>
          <Frame>
            <TextBlock header="Tell us about your business" />
            <Radio
              className={cls.radioButtonGap}
              value="e-commerce"
              name="group"
              onChange={handleChange}
              checked={value === 'e-commerce'}
            >
              eCommence
            </Radio>
            <Radio
              className={cls.radioGap}
              value="other"
              name="group"
              onChange={handleChange}
              checked={value === 'other'}
            >
              Service Website
            </Radio>
            <Label>
              Company name *
              <TextField value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
            </Label>
            <Label>
              Website url *
              <TextField
                iconSuccess={isValidURL}
                iconError={webUrl !== '' && !isValidURL}
                onChange={handleWebUrlChange}
              />
            </Label>
            <Label>
              Industry type
              <Creatable
                createOptionPosition="first"
                allowCreateWhileLoading={true}
                className="industry_type"
                classNamePrefix="industry_type-select"
                isSearchable={true}
                isClearable={true}
                name="industry_type"
                options={industryTypeList}
                value={industryType}
                onChange={handleIndustryTypeChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#00003c',
                    height: '40px',
                    '&:hover': {
                      borderColor: '#00003c',
                    },
                  }),
                }}
              />
            </Label>
            <Label>
              Service Area
              <AsyncSelect
                className="service_area"
                classNamePrefix="service_area-select"
                isClearable={true}
                isSearchable={true}
                name="service_area"
                loadOptions={fetchOptions}
                placeholder="Type your area..."
                onChange={handleServiceAreaChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#00003c',
                    height: '40px',
                    '&:hover': {
                      borderColor: '#00003c',
                    },
                  }),
                }}
              />
            </Label>
            {errorMessage && <TextBlock className={cls.errorMessage} sizeS header={errorMessage} errorMessage={true} />}
          </Frame>

          <div className={cls.skipNextBlock}>
            {(!isSkipped || isSkipped === 'false') && !userInfo?.company && (
              <Button onClick={handleSkipBusinessInfo}>Skip</Button>
            )}
            <Button
              disabled={!companyName || !webUrl || !industryType || !serviceArea || !isValidURL}
              primary
              onClick={handleSubmitBusinessInfo}
            >
              Next
            </Button>
          </div>
        </Modal>
      )}

      {pageNo === 2 && gtmCode && (
        <Modal sizeL noOverlay setOpen={onClick}>
          <Frame>
            <TextBlock header="Google Analytics Code">{intro}</TextBlock>
            <TextBlock className={cls.codeBlock}>
              <Button className={cls.copyButton} onClick={handleCopy} icon={IcnCopy} />
              {getTagManagerScript(gtmCode)}
            </TextBlock>
          </Frame>
          <Button onClick={handleCloseBusinessInfo} disabled={loading}>
            Done
          </Button>
        </Modal>
      )}

      {pageNo === 2 && !gtmCode && (
        <Modal sizeM noOverlay setOpen={onClick}>
          <TextBlock centred frame header="Google Analytics Code">
            Can not provide the tag manager right now. Please contact your service.
          </TextBlock>
          <Button onClick={handleCloseBusinessInfo} primary>
            Finish
          </Button>
        </Modal>
      )}

      {pageNo === 3 && !userInfo?.app_plan && (
        <div className={cls.plans}>
          <InsiteAppPlans />
        </div>
      )}

      <InSiteSnackBar
        alertOpen={isCopied}
        alertMessage={'Tag Manager copied to clipboard.'}
        setAlertOpen={setIsCopied}
      />
    </div>
  );
});

export { BusinessRegistrationModal };
