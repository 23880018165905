import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import { getFacebookTotalLikes, setFacebookTotalLikesAction } from '../../../../store/ducks/socialAnalyticsState';
import { useDispatch } from 'react-redux';
import services from '../../../../services';
import { useEffect, useState } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';

const CardFacebookTotalNumberOfLikes = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const totalLikes = useSelector(getFacebookTotalLikes);
  const [isLoading, setLoading] = useState(false);

  const getTotalFacebookLikes = async () => {
    try {
      setLoading(true);
      const response = await services.getTotalFacebookInsights('fan_count');
      dispatch(setFacebookTotalLikesAction(response.data.toString()));
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (totalLikes == '-') getTotalFacebookLikes();
  }, []);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Facebook Number of likes"
      total={totalLikes == '-' ? totalLikes : formatSocialValue(Number(totalLikes))}
    >
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardFacebookTotalNumberOfLikes };
