import { useEffect, useState } from 'react';

import { Flex } from '../flex';
import { Section } from '../../pages/get-help/ui/components/section';
import { Button } from '../button';
import { classNames } from '../../utils/class-names';

import cls from './plan.module.scss';
import { TextBlock } from '../text-block';

interface Props {
  isNewSubscriber: boolean;
  isSubscribed: boolean;
  isUpgradable: boolean;
  currentPlan: any;
  currentPrice: any;
  onPlanSelect: (value: any) => void;
  onPriceSelect: any;
  planStructure: {
    Icon: React.FC;
    title: string;
    prices: any;
    images: any;
    description: string;
    features: string[];
  };
}

const Plan = (props: Props) => {
  const {
    isNewSubscriber,
    isSubscribed,
    isUpgradable,
    currentPlan,
    onPlanSelect,
    planStructure,
    onPriceSelect,
    currentPrice,
  } = props;

  const buttonName = (isSubscribed: boolean, isUpgradable: boolean) => {
    if (!isSubscribed && isUpgradable) {
      return 'Upgrade';
    } else {
      return 'Select';
    }
  };

  const getPlanStatus = (isSubscribed: boolean, isUpgradable: boolean) => {
    if (isSubscribed) {
      return <TextBlock className={cls.currentPlan} bold header="CURRENT PLAN" />;
    } else {
      return (
        <Button primary onClick={() => {}} disabled={true}>
          Select
        </Button>
      );
    }
  };

  const handleSwitch = () => {
    let selectedPrice = planStructure.prices.find(
      (price: any) => price.recurring.interval !== currentPrice[planStructure.title].recurring.interval
    );
    if (!selectedPrice)
      selectedPrice = planStructure.prices.find(
        (price: any) => price.metadata.term !== currentPrice[planStructure.title].metadata.term
      );
    onPriceSelect(planStructure.title, selectedPrice);
  };

  const handleSelect = () => {
    onPlanSelect(planStructure);
  };

  const mods =
    currentPlan === 'seo'
      ? {
          [cls.sixMonths]: currentPrice[planStructure.title].metadata.term === '6' ? false : true,
        }
      : {
          [cls.sixMonths]: currentPrice[planStructure.title].recurring.interval === 'year' ? false : true,
        };

  console.log(isNewSubscriber);

  return (
    <Section className={classNames(cls.section, isSubscribed ? cls.isCurrentPlan : '')}>
      <img src={planStructure.images[0] || ''} width={200} />
      <h3 className={cls.header}>{planStructure.title}</h3>
      <p className={cls.payment}>
        <span className={cls.price}>${(currentPrice[planStructure.title].unit_amount / 100).toFixed(2)}</span> /{' '}
        {currentPrice[planStructure.title].recurring.interval}
      </p>
      {currentPlan === 'seo' ? (
        <div className={cls.paymentType} id={currentPrice[planStructure.title].id}>
          <span>12-Month</span>
          <span className={classNames(cls.switch, mods)} onClick={handleSwitch}></span>
          <span> 6-Month</span>
        </div>
      ) : currentPlan !== 'plans' ? (
        <div className={cls.paymentType} id={currentPrice[planStructure.title].id}>
          <span>Monthly</span>
          <span className={classNames(cls.switch, mods)} onClick={handleSwitch}></span>
          <span>Yearly</span>
        </div>
      ) : null}

      <p className={cls.description}>{planStructure.description}</p>
      <ul className={cls.featureList}>
        {planStructure.features.map((feature, index) => (
          <li className={cls.feature} key={index}>
            {feature}
          </li>
        ))}
      </ul>
      {currentPrice[planStructure.title].unit_amount !== 0 && (
        <Flex className={cls.buttons} right>
          {isUpgradable || isNewSubscriber ? (
            <Button primary onClick={handleSelect} disabled={false}>
              {buttonName(isSubscribed, isUpgradable)}
            </Button>
          ) : (
            getPlanStatus(isSubscribed, isUpgradable)
          )}
        </Flex>
      )}
    </Section>
  );
};

export { Plan };
