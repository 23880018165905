import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import useStyles from '../../theme';
import { Button } from '../button';
import './InSIteSmallCard.scss';
import DoneIcon from '@mui/icons-material/Done';

interface InSIteSmallCardProps {
  title: string;
  subTitle: string;
  contentArray?: any;
  contentDiv?: any;
  activeBtnFn?: any;
  unitAmount?: any;
  // contactBtnFn: any;
  tag?: boolean;
  type: string;
  isDisabled?: boolean;
  priceDescription?: any;
}

const InSIteSmallCard: FunctionComponent<InSIteSmallCardProps> = ({
  title,
  subTitle,
  contentArray,
  contentDiv,
  unitAmount,
  activeBtnFn = () => {},
  priceDescription = null,
  // contactBtnFn,
  isDisabled = false,
  tag,
  type,
}) => {
  const classes = useStyles();

  const getProcessedSubTitle = (title: string) => {
    let part1 = title.split('/')[0];
    let part2 = null;
    let part3 = title.split('/')[1];

    let splitArr = part1.split('.');
    if (splitArr.length > 1) {
      part1 = splitArr[0];
      part2 = splitArr[1];
    }
    return (
      <>
        <span>{part1}</span>
        {part2 && (
          <span>
            .<span style={{ fontSize: '12px' }}>{part2}</span>
          </span>
        )}
        <span style={{ fontSize: '12px' }}>/{part3}</span>
      </>
    );
  };

  const handleContactUsClick = () => {
    document?.getElementById('open-olark-chat')?.click();
  };

  return (
    <>
      <div className="insite_small_card__wrapper">
        <div className="insite_small_card">
          <div className="insite_small_card_content">
            {tag === true && (
              <div className="tag">
                <div className="tag_text">Most Popular!</div>
              </div>
            )}
            <Typography className={classes.typographyCardTitle}>{title}</Typography>
            <Typography
              className={classes.typographyCardSubTitle}
              style={{
                marginTop: '10px',
                marginBottom: priceDescription ? '0px' : '15px',
              }}>
              {unitAmount !== '0' ? getProcessedSubTitle(subTitle) : 'Custom Price'}
            </Typography>
            {priceDescription && (
              <Typography
                className={classes.typographyCardSubTitlePriceDescription}
                style={{
                  marginBottom: '10px',
                }}>
                {priceDescription}
              </Typography>
            )}
            {type === 'regular' && (
              <div style={{ marginBottom: '15px' }}>
                {contentArray &&
                  contentArray.map((content: string, index: number) => {
                    return (
                      <div
                        key={title + '_' + index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <DoneIcon
                          fontSize="small"
                          style={{
                            color: 'green',
                            marginRight: '10px',
                          }}
                        />
                        <Typography className={classes.typographyDescription}>{content}</Typography>
                      </div>
                    );
                  })}
              </div>
            )}
            {type === 'content' && contentDiv}
          </div>
          <div className="insite_small_card_button">
            {unitAmount !== '0' && (
              <Button onClick={activeBtnFn} disabled={isDisabled} primary>
                Activate
              </Button>
            )}
            <Button onClick={handleContactUsClick}>Contact us</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InSIteSmallCard;
