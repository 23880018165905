import { useState } from "react";
import services from "../../services";
import { loadStripe } from "@stripe/stripe-js";
import { getStripePublicKey } from "../../utils";

export default function useStripePay() {
  const [isFetchingStripeData, setIsFetchingStripeData] = useState(false);

  const handlePay = async (priceId: string) => {
    try {
      setIsFetchingStripeData(true);
      const response = await services.stripePay(priceId);
      const stripePromise = await loadStripe(
        getStripePublicKey(response.checkout_public_key)
      );
      stripePromise?.redirectToCheckout({
        sessionId: response.checkout_session.id,
      });
      setIsFetchingStripeData(false);
      return Promise.resolve();
    } catch (error) {
      setIsFetchingStripeData(false);
      return Promise.reject();
    }
  };

  return {
    isFetchingStripeData,
    handlePay,
  };
}
