import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';
import { fetchUsersByDate, selectNewlUser } from '../../../../store/ducks/admin-dashboard-state';

const CardAdminNewUsers = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const callsMadeData = useSelector(selectNewlUser);

  useEffect(() => {
    dispatch(fetchUsersByDate());
  }, [dispatch]);

  const callsMade = useMemo(() => {
    if (!callsMadeData) return '-';
    return callsMadeData;
  }, [callsMadeData]);

  return (
    <GraphWrapper className={className} {...rest} header="New Users" total={formatSocialValue(callsMade)}>
      <TextBlock className={cls.info} centred></TextBlock>
    </GraphWrapper>
  );
};

export { CardAdminNewUsers };
