import { Flex } from '../../../../../components/flex';
import { Section } from '../../../../../pages/get-help/ui/components/section';
import { Button } from '../../../../../components/button';
import { classNames } from '../../../../../utils/class-names';
import { TextBlock } from '../../../../../components/text-block';

import cls from './plan.module.scss';

interface Props {
  isStatic?: boolean;
  isNewSubscriber: boolean;
  isSubscribed: boolean;
  isUpgradable: boolean;
  currentPlan: any;
  currentPrice: any;
  onPlanSelect: (value: any) => void;
  onPriceSelect: any;
  planStructure: {
    Icon: React.FC;
    title: string;
    prices: any;
    images: any;
    description: string;
    features: string[];
  };
}

const Plan = (props: Props) => {
  const {
    isStatic,
    isNewSubscriber,
    isSubscribed,
    isUpgradable,
    currentPlan,
    onPlanSelect,
    planStructure,
    onPriceSelect,
    currentPrice,
  } = props;

  const buttonName = (isSubscribed: boolean, isUpgradable: boolean) => {
    if (!isSubscribed && isUpgradable) {
      return 'Upgrade';
    } else {
      return 'Select';
    }
  };

  const getPlanStatus = (isSubscribed: boolean, isUpgradable: boolean) => {
    if (isSubscribed) {
      return <TextBlock className={cls.currentPlan} bold header="CURRENT PLAN" />;
    } else {
      return (
        <Button primary onClick={() => {}} disabled={true}>
          Select
        </Button>
      );
    }
  };

  const handleSwitch = () => {
    let selectedPrice = planStructure.prices.find(
      (price: any) =>
        price.recurring.interval !== currentPrice[planStructure.title].recurring.interval,
    );
    if (!selectedPrice)
      selectedPrice = planStructure.prices.find(
        (price: any) => price.metadata.term !== currentPrice[planStructure.title].metadata.term,
      );
    onPriceSelect(planStructure.title, selectedPrice);
  };

  const handleSelect = () => {
    onPlanSelect(planStructure);
  };

  const handleContactUs = () => {
    window.location.href = 'https://rankworks.com/design-services/';
  };

  // const mods =
  //   currentPlan === 'web'
  //     ? {
  //         [cls.sixMonths]: currentPrice[planStructure.title].metadata.term === '6' ? false : true,
  //       }
  //     : {
  //         [cls.sixMonths]:
  //           currentPrice[planStructure.title].recurring.interval === 'year' ? false : true,
  //       };

  return (
    <Section className={classNames(cls.section, isSubscribed ? cls.isCurrentPlan : '')}>
      <img
        className={cls.image}
        src={planStructure.images[0] || ''}
        width={200}
        alt="plan avatar"
      />
      <h3 className={cls.header}>{planStructure.title}</h3>
      {!isStatic && (
        <p className={cls.payment}>
          <span className={cls.price}>
            ${(currentPrice[planStructure.title].unit_amount / 100).toFixed(2)}
          </span>{' '}
          / {currentPrice[planStructure.title].recurring.interval}
        </p>
      )}
      {isStatic && (
        <p className={cls.payment}>
          <span className={cls.price}>Custom</span>
        </p>
      )}

      {/* <div className={cls.paymentType} id={currentPrice[planStructure.title].id}>
        <span>12-Month</span>
        <span className={classNames(cls.switch, mods)} onClick={handleSwitch}></span>
        <span> 6-Month</span>
      </div> */}

      <p className={cls.description}>{planStructure.description}</p>
      <ul className={cls.featureList}>
        {planStructure.features.map((feature, index) => (
          <li className={cls.feature} key={index}>
            {feature}
          </li>
        ))}
      </ul>
      {!isStatic && currentPrice[planStructure.title].unit_amount !== 0 && (
        <Flex className={cls.buttons} right>
          {isUpgradable || isNewSubscriber ? (
            <Button primary onClick={handleSelect} disabled={false}>
              {buttonName(isSubscribed, isUpgradable)}
            </Button>
          ) : (
            getPlanStatus(isSubscribed, isUpgradable)
          )}
        </Flex>
      )}
      {isStatic && (
        <Flex className={cls.buttons} right>
          <Button primary onClick={handleContactUs} disabled={false}>
            Contact us
          </Button>
        </Flex>
      )}
    </Section>
  );
};

export { Plan };
