import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsDispatchedGreetingMessage,
  getUserInfo,
  setIsDispatchedGreetingMessageAction,
} from '../../../store/ducks/inSiteAppState';
import services from '../../../services';
import { eveningMessages, afternoonMessages, morningMessages } from '../../../constants';
import { getRandomInt } from '../../../utils';

import { ContentWrapper } from '../../../components/content-wrapper';
import { TextBlock } from '../../../components/text-block';
import { PageHeader } from '../../../components/page-header';
import { Button } from '../../../components/button';
import { isEmpty } from '../../../utils/is-empty';
import { ReactComponent as IconMessage } from '../../../assets/icons/icn-message.svg';
import cls from './dashboard.module.scss';
import { Flex } from '../../../components/flex';
import { BusinessRegistrationModal } from '../../../components/business-registration-modal/business-registration-modal';
import { useNavigate } from 'react-router-dom';
import { CompetitorList } from '../../../components/competitor-list/competitor-list';
import { GTMTagModal } from '../../../components/gtm-copy-modal/gtm-copy-modal';
import { checkIfGA4DataIsComing } from '../../../store/ducks/dashboardState';
import { Default } from './default/default';
import { isMobile } from '../../../utils/is-mobile';
import { Paid } from './paid/paid';
import { ECommerce } from './e-commerc/e-commerce';
import { HR } from '../../../components/hr';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/icn-hamburger.svg';

enum DashboardType {
  DEFAULT = 'default',
  E_COMMERCE = 'e-commerce',
  PAID = 'paid',
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo: any = useSelector(getUserInfo);
  const paymentInfo = userInfo?.app_plan?.plan_name; // for payment dashboard
  const isECommerce = userInfo?.company?.is_ecommerce; // for e-commerce dashboard

  const [dashboardType, setDashboardType] = useState<DashboardType | undefined>(undefined);
  const [greeting, setGreeting] = useState('');
  const [open, setOpen] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);

  const isDispatchedGreetingMessage: any = useSelector(getIsDispatchedGreetingMessage);
  const isGA4DataIsComing: boolean = useSelector(checkIfGA4DataIsComing);
  const [isCancel, setIsCancel] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCopiedSnippet, setIsCopiedSnippet] = useState<boolean>(true);
  const isSkipped = localStorage.getItem('isSkipped');

  const verifyGTMTagSnippet = async (url: string, tagId: string) => {
    try {
      const response = await services.getGTMTagSnippetStatus(url, tagId);
      if (response.hasOwnProperty('is_copied')) setIsCopiedSnippet(response.is_copied);
    } catch (error: any) {
      setIsCopiedSnippet(true);
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (paymentInfo !== 'Starter') setDashboardType(DashboardType.PAID);
    else setDashboardType(DashboardType.DEFAULT);
    if (isECommerce) setDashboardType(DashboardType.E_COMMERCE);
  }, [paymentInfo, isECommerce]);

  useEffect(() => {
    const getCurrentGreeting = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      if (currentHour >= 5 && currentHour < 12) {
        // morning
        setGreeting(morningMessages[getRandomInt(morningMessages.length)]);
      } else if (currentHour >= 12 && currentHour < 18) {
        // afternoon
        setGreeting(afternoonMessages[getRandomInt(afternoonMessages.length)]);
      } else {
        setGreeting(eveningMessages[0]);
      }
    };
    getCurrentGreeting();
  }, []);

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    if (userInfo?.company) setOpen(false);
    if (!userInfo?.company) {
      if (!isSkipped || isSkipped === 'false') navigate('/business');
      else setOpen(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const webUrl = userInfo?.company?.website;
    const tag = userInfo?.company?.gtm?.publicId || '';
    if (webUrl && tag) verifyGTMTagSnippet(webUrl, tag);
  }, [userInfo]);

  useEffect(() => {
    if (isGA4DataIsComing) setOpenCopyModal(false);
    if (!isCopiedSnippet && !isGA4DataIsComing) setOpenCopyModal(true);
  }, [isCopiedSnippet, isGA4DataIsComing]);

  const getProcessedGreetingMessage = () => {
    let firstName = userInfo?.name?.first_name || '';
    let lastName = userInfo?.name?.last_name || '';
    let name = firstName + ' ' + lastName;
    return greeting.replace(/\[Client Name\]/g, name);
  };

  const handleCustomizeClick = () => {};
  const handleRecommendationClick = () => {};

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  return (
    <ContentWrapper menuOpen={isMenuOpen} setMenuOpen={setMenuOpen}>
      <div className={cls.headerWrapper}>
        <button className={cls.hamburger} onClick={handleMenuOpen}>
          <HamburgerIcon />
        </button>
        <PageHeader>Dashboard</PageHeader>
      </div>
      {/* for tests. delete after testing */}
      {/* <>
        <button
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'block',
            width: '20px',
            height: '20px',
            background: 'transparent',
            border: 'none',
          }}
          onClick={() => setDashboardType(DashboardType.DEFAULT)}></button>
        <button
          style={{
            position: 'fixed',
            top: '20px',
            left: '0',
            display: 'block',
            width: '20px',
            height: '20px',
            background: 'transparent',
            border: 'none',
          }}
          onClick={() => setDashboardType(DashboardType.PAID)}></button>
        <button
          style={{
            position: 'fixed',
            top: '40px',
            left: '0',
            display: 'block',
            width: '20px',
            height: '20px',
            background: 'transparent',
            border: 'none',
          }}
          onClick={() => setDashboardType(DashboardType.E_COMMERCE)}></button>
      </> */}
      {openCopyModal && !isMobile() && (
        <GTMTagModal
          gray={true}
          noOverlay={false}
          onClick={() => setOpenCopyModal(false)}
          defaultPageNo={0}
          gtmCode={userInfo?.company?.gtm?.publicId || ''}
        />
      )}
      {!isCancel &&
        !isDispatchedGreetingMessage &&
        dashboardType === DashboardType.DEFAULT &&
        !isMobile() && (
          <div className={cls.greeting}>
            <IconMessage />
            <TextBlock>{getProcessedGreetingMessage()}</TextBlock>
            <Button
              className={cls.closeButton}
              closeButton
              onClick={() => {
                setIsCancel(true);
                dispatch(setIsDispatchedGreetingMessageAction(true));
              }}></Button>
          </div>
        )}
      {dashboardType === DashboardType.DEFAULT && !isMobile() && (
        <Flex className={cls.topButtonsWrapper} right>
          <Button onClick={handleCustomizeClick}>Customize dashboard</Button>
          <Button onClick={handleRecommendationClick} primary>
            View recommendation
          </Button>
        </Flex>
      )}
      <HR />
      {dashboardType === DashboardType.DEFAULT && <Default />}
      {dashboardType === DashboardType.PAID && <Paid />}
      {dashboardType === DashboardType.E_COMMERCE && <ECommerce />}
      {open && (
        <BusinessRegistrationModal
          gray={true}
          noOverlay={false}
          onClick={() => setOpen(false)}
          defaultPageNo={0}
        />
      )}
    </ContentWrapper>
  );
};

export { Dashboard };
