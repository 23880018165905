import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import services from '../../../../services';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import {
  getInstagramProfileViews,
  setInstagramProfileViewsAction,
} from '../../../../store/ducks/instagramAnalyticsState';
import { formatSocialValue } from '../../../../utils/format-social-value';

const CardInstagramProfileViews = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const profileViews = useSelector(getInstagramProfileViews);
  const [isLoading, setLoading] = useState(false);

  const getTotalInstagramProfileViews = async () => {
    try {
      setLoading(true);
      const response = await services.getInstagramProfileViewsData();
      const count = response.data[0].total_value.value.toString();
      dispatch(setInstagramProfileViewsAction(count));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (profileViews == '-') getTotalInstagramProfileViews();
  }, []);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Number of profile views"
      total={profileViews == '-' ? profileViews : formatSocialValue(Number(profileViews))}
    >
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>4%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardInstagramProfileViews };
