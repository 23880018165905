import { useEffect, useState } from 'react';

import { ContentWrapper } from '../../../components/content-wrapper';
import { Breadcrumbs } from '../../../components/breadcrumbs';
import { PageHeader } from '../../../components/page-header';
import { HR } from '../../../components/hr';
import { initialState } from '../model/types/general-information';

import { GeneralInformationWrapper } from './general-information/general-information';
import { Flex } from '../../../components/flex';
import { Button } from '../../../components/button';

import cls from './web-design-service.module.scss';
import { AdditionalInformation } from './aditional-information/additional-information';
import { Aesthetics } from './aesthetics/aesthetics';
import { Inspiration } from './inspiration/inspiration';
import { ThankYou } from './thank-you/thank-you';
import { adoptInfoToServer } from '../model/adapters/adopt-info-to-server';
import { postNewRequest } from '../services/post-info';
import { Plans } from './plans/plans';
import { Payment } from './payment/payment';
import { Congratulation } from './congratulation/congratulation';
import { Splash } from './splash/splash';

const CRUMB = { label: 'Web design service' };

// https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDc_K3Y8z4jR_-KOpU9gBHaMS1zR6gB0Jc

const WebDesignService = () => {
  const [generalInformation, setGeneralInformation] = useState({ ...initialState });
  const [paymentInformation, setPaymentInformation] = useState<object>({});
  const [step, setStep] = useState(0);

  useEffect(() => {
    const savedGeneralInformation = localStorage.getItem('generalInformation');

    if (savedGeneralInformation) {
      setGeneralInformation({ ...generalInformation, ...JSON.parse(savedGeneralInformation) });
    }
  }, []);

  const handleNextClick = () => {
    setStep(step + 1);
  };

  const handlePreviousClick = () => {
    setStep(step - 1);
  };

  const handleCancelClick = () => {
    setGeneralInformation({ ...initialState });
    setStep(1);
  };

  const handleSubmitClick = async () => {
    console.log('-> handleSubmitClick', generalInformation);
    const result = await postNewRequest(adoptInfoToServer(generalInformation));
    if (result) setStep(8);
  };

  const handleSaveDraft = () => {
    localStorage.setItem('generalInformation', JSON.stringify(generalInformation));
  };

  const setNextStep = () => {
    setStep(step + 1);
  };

  const getCrumbs = () => {
    if (step === 1) return [CRUMB, { label: 'General Information', isCurrent: true }];
    if (step === 2) return [{ ...CRUMB, isCurrent: true }];
    if (step === 3) return [CRUMB, { label: 'Summary & review', isCurrent: true }];
    if (step === 4) return [];
    if (step === 5) return [CRUMB, { label: 'Additional Information', isCurrent: true }];
    if (step === 6) return [CRUMB, { label: 'Aesthetics', isCurrent: true }];
    if (step === 7) return [CRUMB, { label: 'inspiration', isCurrent: true }];
    if (step === 8) return [];

    return [];
  };

  const getPageHeader = () => {
    if (step === 1) return 'General Information';
    if (step === 2) return 'Monthly plans';
    if (step === 3) return 'Payment';
    if (step === 4) return '';
    if (step === 5) return 'Additional Information';
    if (step === 6) return 'Aesthetics';
    if (step === 7) return 'Inspiration';
    if (step === 8) return '';

    return '';
  };

  const getCancelButton = () => {
    if (step === 0) return null;
    if (step === 2) return null;
    if (step === 3) return null;
    if (step === 4) return null;
    if (step === 8) return null;

    return (
      <Button transparent onClick={handleCancelClick}>
        Cancel
      </Button>
    );
  };

  const getSaveDraftButton = () => {
    if (step === 0) return null;
    if (step === 2) return null;
    if (step === 3) return null;
    if (step === 4) return null;
    if (step === 8) return null;

    return <Button onClick={handleSaveDraft}>Save Draft</Button>;
  };

  const getNextButton = () => {
    if (step === 0) return null;
    if (step === 2) return null;
    if (step === 3) return null;
    if (step === 4) return null;
    if (step === 7) return null;
    if (step === 8) return null;

    return (
      <Button primary onClick={handleNextClick}>
        Next
      </Button>
    );
  };

  const getSubmitButton = () => {
    if (step === 7)
      return (
        <Button primary onClick={handleSubmitClick}>
          Submit
        </Button>
      );

    return null;
  };

  const getPreviousButton = () => {
    if (step === 0) return null;
    if (step === 1) return null;
    if (step === 2) return null;
    if (step === 3) return null;
    if (step === 4) return null;
    if (step === 5) return null;
    if (step === 8) return null;

    return <Button onClick={handlePreviousClick}>Previous</Button>;
  };

  const fillGeneralInfoAI = () => {};
  const fillAdditionalInfoAI = () => {};

  const isHeaderVisible = step !== 0 && step !== 4 && step !== 8;
  const isButtonsVisible = step !== 0 && step !== 2 && step !== 3 && step !== 4 && step !== 8;

  return (
    <ContentWrapper>
      <Breadcrumbs crumbs={getCrumbs()} />
      {isHeaderVisible && <PageHeader>{getPageHeader()}</PageHeader>}
      {isHeaderVisible && <HR />}

      {step === 1 && (
        <Flex className={cls.topFlex} right>
          <Button onClick={fillGeneralInfoAI}>Autofill with AI</Button>
        </Flex>
      )}
      {step === 5 && (
        <Flex className={cls.topFlex} right>
          <Button onClick={fillAdditionalInfoAI}>Autofill with AI</Button>
        </Flex>
      )}

      {step === 0 && <Splash setNextStep={setNextStep} />}
      {step === 1 && (
        <GeneralInformationWrapper data={generalInformation} setData={setGeneralInformation} />
      )}
      {step === 2 && <Plans setData={setPaymentInformation} setNextStep={setNextStep} />}
      {step === 3 && <Payment setNextStep={setNextStep} data={paymentInformation} />}
      {step === 4 && <Congratulation setNextStep={setNextStep} />}
      {step === 5 && (
        <AdditionalInformation data={generalInformation} setData={setGeneralInformation} />
      )}
      {step === 6 && <Aesthetics data={generalInformation} setData={setGeneralInformation} />}
      {step === 7 && <Inspiration data={generalInformation} setData={setGeneralInformation} />}
      {step === 8 && <ThankYou />}

      {isButtonsVisible && (
        <Flex className={cls.bottomFlex} right>
          {getCancelButton()}
          {getSaveDraftButton()}
          {getPreviousButton()}
          {getNextButton()}
          {getSubmitButton()}
        </Flex>
      )}
    </ContentWrapper>
  );
};

export { WebDesignService };
