import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import services from '../../../../services';
import {
  getInstagramEngagedUsersDataByDate,
  setInstagramEngagedUsersDataByDateAction,
} from '../../../../store/ducks/instagramAnalyticsState';
import { Line } from 'react-chartjs-2';
import { LINE_CHART_CONFIG } from '../../../../constants';

const GraphInstagramEngagementRate = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const engagedUsersData = useSelector(getInstagramEngagedUsersDataByDate);
  const [isLoading, setLoading] = useState(false);

  const getEngagedUsersData = async () => {
    try {
      setLoading(true);
      const response = await services.getInstagramEngagedUsersData();
      dispatch(setInstagramEngagedUsersDataByDateAction(response?.data || []));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (engagedUsersData.length === 0) getEngagedUsersData();
  }, []);

  const generateLineGraph = (data: any) => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Accent-cold-Cool'),
      styles.getPropertyValue('--Accent-warm-Warm'),
    ];
    const result = {
      labels: data[0]?.values?.map((item: any) => moment(item.end_time).format('D MMM')) || [],
      datasets: data.map((content: any, index: any) => ({
        label: 'Engaged Users',
        data: content.values.map((item: any) => item.value),
        backgroundColor: [styles.getPropertyValue('--Primary-Primary-400')],
        borderColor: [styles.getPropertyValue('--Primary-Primary-400')],
        borderWidth: 1,
        pointBackgroundColor: pointColors,
        pointBorderColor: pointColors,
      })),
    };
    return result;
  };

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="INSTAGRAM ENGAGED USERS"
      total={isLoading ? 'Loading...' : ''}
    >
      <Line
        data={generateLineGraph(engagedUsersData)}
        style={{
          maxHeight: '380px',
          maxWidth: '100%',
          paddingLeft: '10px',
          display: 'flex',
        }}
        options={LINE_CHART_CONFIG}
      />
      <TextBlock className={cls.info} centred>
        <span className={cls.inc}>20%</span>
        <span className={cls.text}>increase</span>
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphInstagramEngagementRate };
