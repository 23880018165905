import { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { externalTooltipHandler } from '../../../utils';

interface Props {
  data: any;
}

const BarChart = memo((props: Props) => {
  const element = document.querySelector(':root')!;
  const styles = getComputedStyle(element);
  const { data } = props;

  return (
    <Bar
      options={{
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom' as const,
            labels: {
              boxWidth: 10,
              boxHeight: 10,
            },
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: styles.getPropertyValue('--Primary-Primary-400'),
              font: {
                size: 9,
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              color: styles.getPropertyValue('--Primary-Primary-400'),
              font: {
                size: 9,
              },
            },
          },
        },
      }}
      data={data}
    />
  );
});

export { BarChart };
