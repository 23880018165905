import { memo } from 'react';

import cls from './thank-you.module.scss';
import { Modal } from '../../../../components/modal';
import { TextBlock } from '../../../../components/text-block';
import { Frame } from '../../../../components/frame';
import { Button } from '../../../../components/button';

const ThankYou = memo(() => {
  return (
    <div className={cls.wrapper}>
      <div className={cls.color}>
        <Modal sizeM noOverlay>
          <Frame>
            <TextBlock
              centred
              header="Thank you for choosing RankWorks.Your business is important to us.">
              An Account Manager will be in touch with you within the next 48 hours to discuss your
              website customization further.
              <br />
              We look forward to helping you bringing your vision to life.
            </TextBlock>
          </Frame>
          <Button primary onClick={() => {}}>
            Close
          </Button>
        </Modal>
      </div>
    </div>
  );
});

export { ThankYou };
