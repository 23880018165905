import { Avatar, Box, Typography } from '@mui/material';
import InSIteSmallCard from '../../components/InSIteSmallCard/InSIteSmallCard';
import { ContentWrapper } from '../../components/content-wrapper';
import useStyles from '../../theme';
import infoIcon from '../../../src/assets/info.png';
import { useEffect, useState } from 'react';
import InSiteInformationDialouge from '../../components/InSiteInformationDialouge/InSiteInformationDialouge';
import { useSelector } from 'react-redux';
import { getProductsByPage } from '../../store/ducks/inSiteAppState';
import useStripePay from '../../hooks/useStripePay/useStripePay';
import { processPrice } from '../../utils';

export default function Sem() {
  const classes = useStyles();
  const [informationDialouge, setInformationDialouge] = useState(false);
  let { handlePay } = useStripePay();
  const [disableInfo, setDisableInfo] = useState(null);

  const products = useSelector((state: any) => getProductsByPage(state, 'search_engine_marketing'));

  useEffect(() => {
    let obj: any = {};
    products.forEach((element: any) => {
      obj[element._id] = false;
    });
    setDisableInfo(obj);
    // eslint-disable-next-line
  }, []);

  const handleStripePay = (id: any) => {
    setDisableInfo((prevState: any) => {
      let state = Object.assign({}, prevState); // creating copy of state variable jasper
      state[id] = true; // update the name property, assign a new value
      return state;
    });
    handlePay(id);
  };

  return (
    <ContentWrapper>
      {informationDialouge && (
        <InSiteInformationDialouge
          content={
            <p
              style={{
                color: '#4C505F',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'justify',
              }}>
              <b>Take a little or the works</b>
              <br />
              <br />
              Once your site has launched and organic SEO has started working it’s magic, it’s time
              to speed things up. Search Engine Marketing helps drive traffic faster by advertising
              with Pay Per Click, Display, and Video ads. Our monthly SEM packages help new
              customers find your business faster by paying for placement according to the keywords
              selected by our experts.
              <br />
              <br />
              <b>Google Ads</b>
              <ul style={{ paddingLeft: '20px', marginTop: '0px' }}>
                <li>
                  Search ads appear next to Google search results when people look for products and
                  services you offer. We focus on keywords that will drive the most traffic to your
                  business.
                </li>
              </ul>
              <b>YouTube Video Ads</b>
              <ul style={{ paddingLeft: '20px', marginTop: '0px' }}>
                <li>
                  Video ads are shown only to the people you want and you only pay when they watch.
                  In other words, you won’t waste money advertising to people who aren't interested
                  in your business.
                </li>
              </ul>
              <b>Display Ads</b>
              <ul style={{ paddingLeft: '20px', marginTop: '0px' }}>
                <li>
                  AdWords display ads appear on over two million websites and in over 650,000 apps,
                  so your ad can show up wherever your audience is.
                </li>
              </ul>
            </p>
          }
          title={'Search Engine Marketing'}
          openStatus={informationDialouge}
          setInformationDialouge={setInformationDialouge}
        />
      )}
      <Box sx={{ borderBottom: 2, borderColor: '#e4eff2' }}></Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          width: '100%',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography className={classes.typographyTitle}>Search Engine Marketing</Typography>
          <div
            style={{
              marginLeft: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}>
            <img
              src={infoIcon}
              alt="Info"
              className="info"
              onClick={() => {
                setInformationDialouge(true);
              }}
            />
          </div>
        </div>
        <div className="admin__table_header_wrapper">
          <Typography className={classes.typographyCardTitleItalic} style={{ marginLeft: '10px' }}>
            Contact us today and generate new opportunities for your business.
          </Typography>
        </div>

        <Typography className={classes.typographySubTitle}>
          Take a little or the works
          <br />
          <br />
          Once your site has launched and organic SEO has started working it’s magic, it’s time to
          speed things up. Search Engine Marketing helps drive traffic faster by advertising with
          Pay Per Click, Display, and Video ads.
        </Typography>

        <div
          style={{
            marginTop: '15px',
            marginBottom: '15px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Avatar sx={{ bgcolor: '#00A4CB' }} alt="Remy Sharp" src="/broken-image.jpg">
              1
            </Avatar>
            <div style={{ marginLeft: '10px' }}>
              <Typography className={classes.typographyCardTitle}>Google Ads</Typography>
              <Typography className={classes.typographySubTitle}>
                Search ads appear next to Google search results when people look for products and
                services you offer. We focus on keywords that will drive the most traffic to your
                business.
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Avatar sx={{ bgcolor: '#00A4CB' }} alt="Remy Sharp" src="/broken-image.jpg">
              2
            </Avatar>
            <div style={{ marginLeft: '10px' }}>
              <Typography className={classes.typographyCardTitle}>YouTube Video Ads</Typography>
              <Typography className={classes.typographySubTitle}>
                Video ads are shown only to the people you want and you only pay when they watch. In
                other words, you won’t waste money advertising to people who aren't interested in
                your business.
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Avatar sx={{ bgcolor: '#00A4CB' }} alt="Remy Sharp" src="/broken-image.jpg">
              3
            </Avatar>
            <div style={{ marginLeft: '10px' }}>
              <Typography className={classes.typographyCardTitle}>Display Ads</Typography>
              <Typography className={classes.typographySubTitle}>
                AdWords display ads appear on over two million websites and in over 650,000 apps, so
                your ad can show up wherever your audience is.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', gap: '15px' }}>
          {products &&
            products.map((content: any, index: number) => {
              return (
                <InSIteSmallCard
                  key={content.default_price_id}
                  title={content.name}
                  subTitle={
                    processPrice(content.pricing[0].unit_amount_decimal) +
                    '/' +
                    content.pricing[0].recurring.interval
                  }
                  priceDescription={content.ui.stripe_default_price_desc}
                  unitAmount={content.pricing[0].unit_amount_decimal}
                  contentArray={content.services}
                  tag={content.ui.insite_most_popular}
                  type="regular"
                  activeBtnFn={() => handleStripePay(content._id)}
                  isDisabled={disableInfo ? disableInfo[content._id] : false}
                />
              );
            })}
        </div>
      </div>
    </ContentWrapper>
  );
}
