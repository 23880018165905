import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import { GraphProps } from '../../model/types/graph';

import { BarChart } from '../../../bar-chart';

const GraphGA4SessionSource = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  const generateGraphData = () => {
    const element = document.querySelector(':root')!;
    const styles = getComputedStyle(element);
    const pointColors = [
      styles.getPropertyValue('--Secondary-Secondary'),
      styles.getPropertyValue('--Primary-Primary-400'),
      styles.getPropertyValue('--Primary-Primary'),
    ];

    const returnedDataSet = {
      labels: [] as string[],
      datasets: [
        {
          label: 'session source',
          data: [] as number[],
          backgroundColor: pointColors,
          borderColor: pointColors,
          borderWidth: 1,
        },
      ],
    };

    if (!dataSet) return returnedDataSet;

    const dataArray = dataSet.ga4_data;

    if (!dataArray) {
      console.log('no data array');
      return returnedDataSet;
    }

    let dataDict: { [key: string]: number } = {};

    dataSet.ga4_data.forEach((element: any) => {
      const source = element.sessionSource || 'unknown';
      const value = element.totalUsers || 0;

      if (dataDict[source]) {
        dataDict[source] += Number(value) || 0;
      } else {
        dataDict[source] = Number(value) || 0;
      }
    });

    const sources = Object.keys(dataDict);
    const values = Object.values(dataDict);

    returnedDataSet.labels = sources;
    returnedDataSet.datasets[0].data = values;

    return returnedDataSet;
  };

  return (
    <GraphWrapper className={className} {...rest} header="Session source" total={''}>
      <BarChart data={generateGraphData()} />
    </GraphWrapper>
  );
};

export { GraphGA4SessionSource };
