import { AnyAction } from 'redux';

interface PaymentState {
  checkoutInfo: any;
}

export const SET_CHECKOUT_INFO = 'SET_CHECKOUT_INFO';

const initialState: PaymentState = {
  checkoutInfo: {
    planInfo: null,
    priceInfo: null,
    userInfo: null,
    planType: '',
  },
};

/* -------------------------------------------------------------------------- */
/*                           interfaces for actions                           */
/* -------------------------------------------------------------------------- */
export interface SetCheckoutInfo extends AnyAction {
  type: typeof SET_CHECKOUT_INFO;
  data: any;
}

export type ActionType = SetCheckoutInfo;

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export const setCheckoutInfoAction = (data: any): SetCheckoutInfo => ({
  data,
  type: SET_CHECKOUT_INFO,
});

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
export default function paymentReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_CHECKOUT_INFO:
      return {
        ...state,
        checkoutInfo: action.data,
      };
    default:
      return state;
  }
}

/* -------------------------------------------------------------------------- */
/*                               Thunk Actions                                */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                  selectors                                 */
/* -------------------------------------------------------------------------- */

export const getCheckoutInfo = (state: any) => state.paymentInfo?.checkoutInfo;
