import { combineReducers, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import inSiteAppReducer, { InSiteAppState } from './ducks/inSiteAppState';
import inSiteAdminReducer, { InSiteAdminState } from './ducks/inSiteAdminState';
import socialAnalyticsReducer, { SocialAnalyticsState } from './ducks/socialAnalyticsState';
import instagramAnalyticsReducer, { InstagramAnalyticsState } from './ducks/instagramAnalyticsState';
import dashboardReducer from './ducks/dashboardState';
import gmbReducer from './ducks/gmb-state';
import adminDashboardReducer from './ducks/admin-dashboard-state';
import paymentReducer from './ducks/paymentState';
import { gmbDashboardReducer } from './ducks/gmb-dashboard-state';
import { adsDashboardReducer } from './ducks/ads-dashboard-state';

let rootReducer = combineReducers({
  inSiteAppInfo: inSiteAppReducer,
  inSiteAdminInfo: inSiteAdminReducer,
  socialAnalyticsInfo: socialAnalyticsReducer,
  instagramAnalyticsInfo: instagramAnalyticsReducer,
  dashboardInfo: dashboardReducer,
  gmbInfo: gmbReducer,
  gmbDashboard: gmbDashboardReducer,
  adsDashboard: adsDashboardReducer,
  paymentInfo: paymentReducer,
  adminDashboardInfo: adminDashboardReducer,
});

export type StoreType = Store & InSiteAppState & InSiteAdminState & SocialAnalyticsState;

/** The initial store */
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
