import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { ContentWrapper } from '../../components/content-wrapper';
import useStyles from '../../theme';

export default function Marketing() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <ContentWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
        <div>
          <Typography className={classes.typographyTitle}>Do you need Marketing Help?</Typography>
        </div>
        <div>
          <Typography className={classes.typographyCardTitleItalic}>
            <li>Does your website need more visibility?</li>
          </Typography>
          <Typography className={classes.typographyCardTitleItalic}>
            <li>Are you looking for more traffic?</li>
          </Typography>
          <Typography className={classes.typographyCardTitleItalic}>
            <li>Do you want more sales?</li>
          </Typography>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Typography className={classes.typographySubTitle} style={{ marginTop: '5px' }}>
            If you answer yes to any of these questions, Let us help you!!
          </Typography>
        </div>
        <div>
          <Card
            sx={{
              minWidth: 275,
              borderRadius: '10px',
              boxShadow: 'none',
              border: '2px solid #CECECE',
              marginBottom: '20px',
            }}>
            <CardContent>
              <Typography className={classes.typographyCardTitle}>
                Social Media Management
              </Typography>
              <Typography className={classes.typographyCardSubTitle}>
                Did you know that the daily average that a person spends on social media is 142
                minutes a day.
              </Typography>
              <Typography variant="body2" style={{ color: '#9BA6B3' }}>
                Implementing the correct Social Media strategy for your business means happier
                customers and better word of mouth. We have full-time professional social media
                specialists available to handle all of your business’s social media channels.
                <br />
                <br />
                This means that your next client is most likely browsing their social media feeds
                right now. Therefore, if you’re looking to build a strong community on Facebook, get
                more followers on Instagram, and generate lots of likes and comments, Then look no
                further because RankWorks has different levels of social media management plans that
                are designed to help your company’s brand grow online.
              </Typography>
            </CardContent>
            <CardActions style={{ marginLeft: '6px' }}>
              <Button
                primary
                onClick={() => {
                  navigate('/social-media');
                }}>
                More...
              </Button>
              <Button
                onClick={() => {
                  document?.getElementById('open-olark-chat')?.click();
                }}>
                Contact us
              </Button>
            </CardActions>
          </Card>
          <Card
            sx={{
              minWidth: 275,
              borderRadius: '10px',
              boxShadow: 'none',
              border: '2px solid #CECECE',
              marginBottom: '20px',
            }}>
            <CardContent>
              <Typography className={classes.typographyCardTitle}>
                Search Engine Optimization
              </Typography>
              <Typography className={classes.typographyCardSubTitle}>
                After the design comes... traffic.
              </Typography>
              <Typography variant="body2" style={{ color: '#9BA6B3' }}>
                Search Engine Optimization is often described as intangible by nature, but
                invaluable by result. Just like in school the goal is to get as close to a 100 score
                as possible. Site content, proper coding, accepted use of keywords, readability,
                off-site mentions, backlinks and more all contribute to a site's score. Let our
                experts set your site on the right track to help get you ranked high and stay high.
              </Typography>
            </CardContent>
            <CardActions style={{ marginLeft: '6px' }}>
              <Button
                primary
                onClick={() => {
                  navigate('/seo');
                }}>
                More...
              </Button>
              <Button
                primary
                onClick={() => {
                  document?.getElementById('open-olark-chat')?.click();
                }}>
                Contact us
              </Button>
            </CardActions>
          </Card>
          <Card
            sx={{
              minWidth: 275,
              borderRadius: '10px',
              boxShadow: 'none',
              border: '2px solid #CECECE',
              marginBottom: '20px',
            }}>
            <CardContent>
              <Typography className={classes.typographyCardTitle}>
                Search Engine Marketing
              </Typography>
              <Typography className={classes.typographyCardSubTitle}>
                Take a little or the works
              </Typography>
              <Typography variant="body2" style={{ color: '#9BA6B3' }}>
                Once your site has launched and organic SEO has started working it’s magic, it’s
                time to speed things up. Search Engine Marketing helps drive traffic faster by
                advertising with Pay Per Click, Display, and Video ads. Our monthly SEM packages
                help new customers find your business faster by paying for placement according to
                the keywords selected by our experts.
              </Typography>
            </CardContent>
            <CardActions style={{ marginLeft: '6px' }}>
              <Button
                primary
                onClick={() => {
                  navigate('/sem');
                }}>
                More...
              </Button>
              <Button
                primary
                onClick={() => {
                  document?.getElementById('open-olark-chat')?.click();
                }}>
                Contact us
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </ContentWrapper>
  );
}
