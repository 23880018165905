import { GraphWrapper } from "../graph-wrapper/graph-wrapper";
import cls from "../graph.module.scss";
import { TextBlock } from "../../../text-block";
import { GraphProps } from "../../model/types/graph";
import { useSelector } from "react-redux";
import { getCompetitorList } from "../../../../store/ducks/dashboardState";
import { getUserInfo } from "../../../../store/ducks/inSiteAppState";
import { useEffect, useState } from "react";

const CardGA4GoogleRanking = (props: GraphProps) => {
  const { className, ...rest } = props;
  const [total, setTotal] = useState("-");
  const userInfo = useSelector(getUserInfo);
  const competitorList = useSelector(getCompetitorList);

  const getCompanyRanking = () => {
    if (competitorList.length !== 0 && userInfo?.company) {
      competitorList.forEach((element: any, index: number) => {
        if (
          userInfo?.company?.name?.toLowerCase() === element.title.toLowerCase()
        ) {
          setTotal((index + 1).toString());
        }
      });
    }
  };

  useEffect(() => {
    if (total == "-") getCompanyRanking();
  }, [competitorList]);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="Google Ranking"
      total={total}
    >
      <TextBlock className={cls.info} centred></TextBlock>
    </GraphWrapper>
  );
};

export { CardGA4GoogleRanking };
