import PouchDB from "pouchdb";
let db: any = null;

const getDB = () => {
  if (!db)
    db = new PouchDB("dashboard", { revs_limit: 1, auto_compaction: true });
  return db;
};

export default getDB;
