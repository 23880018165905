import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import cls from './modal.module.scss';
import { Key, MODAL_ANIMATION_DURATION } from '../../../constant/ui';
import { classNames } from '../../../utils/class-names';
import { Button } from '../../button';
import Logo from '../../../assets/logo/rank-works-logo.png';

interface Props {
  className?: string;

  children: React.ReactNode;

  keepOpen?: boolean;
  setOpen?: (open: boolean) => void;

  sizeS?: boolean;
  sizeM?: boolean;
  sizeL?: boolean;
  sizeXL?: boolean;
  logo?: boolean;
  noOverlay?: boolean;
  gray?: boolean;
}

const Modal: FC<Props> = memo((props: Props) => {
  const {
    className,
    children,
    keepOpen = true,
    logo = true,
    setOpen,
    sizeS,
    sizeM,
    sizeL,
    sizeXL,
    noOverlay,
    gray,
  } = props;

  const [isInAnimation, setInAnimation] = useState(false);
  const [isOutAnimation, setOutAnimation] = useState(false);
  const [isNoAnimation, setNoAnimation] = useState(false);

  const animationInRef = useRef<ReturnType<typeof setTimeout>>();
  const animationOutRef = useRef<ReturnType<typeof setTimeout>>();

  const openModal = useCallback(() => {
    document.body.style.overflowY = 'hidden';

    setInAnimation(true);

    animationInRef.current = setTimeout(() => {
      setNoAnimation(true);
      setInAnimation(false);
    }, MODAL_ANIMATION_DURATION);
  }, []);

  const closeModal = useCallback(() => {
    document.body.style.overflowY = 'auto';

    setOutAnimation(true);
    setNoAnimation(false);

    animationOutRef.current = setTimeout(() => {
      setOutAnimation(false);
      setOpen && setOpen(false);
    }, MODAL_ANIMATION_DURATION);
  }, [setOpen]);

  const handleOverlayClick = () => {
    if (!noOverlay) closeModal();
  };
  const handleModalBodyClick = (evt: React.MouseEvent) => evt.stopPropagation();
  const handleCloseButtonClick = () => closeModal();
  const handleKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.key === Key.ESCAPE) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    openModal();

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      clearTimeout(animationInRef.current);
      clearTimeout(animationOutRef.current);

      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, openModal]);

  useEffect(() => {
    if (!keepOpen) {
      closeModal();
    }
  }, [keepOpen, closeModal]);

  const overlayMods = {
    [cls.overlayAnimationIn]: isInAnimation,
    [cls.overlayAnimationOut]: isOutAnimation,
    [cls.overlayNoAnimation]: isNoAnimation,
    [cls.gray]: gray,
  };

  const modalMods = {
    [cls.modalAnimationIn]: isInAnimation,
    [cls.modalAnimationOut]: isOutAnimation,
    [cls.modalNoAnimation]: isNoAnimation,
    [cls.sizeS]: sizeS,
    [cls.sizeM]: sizeM,
    [cls.sizeL]: sizeL,
    [cls.sizeXL]: sizeXL,
  };

  return (
    <div className={classNames(cls.overlay, overlayMods)} onClick={handleOverlayClick}>
      <div className={classNames(cls.modal, className, modalMods)} onClick={handleModalBodyClick}>
        <Button className={cls.closeButton} closeButton onClick={handleCloseButtonClick} />
        {logo && (
          <div className={cls.logoWrapper}>
            <img src={Logo} alt="Rank Works Logo" className={cls.logo} />
          </div>
        )}
        <div className={cls.contentWrapper}>{children}</div>
      </div>
    </div>
  );
});

export { Modal };
