import { memo } from 'react';

import { Flex } from '../../../../components/flex';
import { ContactInformation } from '../contact-information/contact-information';
import { Address } from '../address/address';
import { GeneralInformation } from '../../model/types/general-information';

import cls from './general-information.module.scss';

interface Props {
  className?: string;

  data: GeneralInformation;
  setData: (data: GeneralInformation) => void;
}

const GeneralInformationWrapper = memo((props: Props) => {
  const { className, data, setData } = props;

  return (
    <Flex className={cls.flex} style={{ alignItems: 'stretch' }}>
      <ContactInformation data={data} setData={setData} />
      <Address data={data} setData={setData} />
    </Flex>
  );
});

export { GeneralInformationWrapper };
