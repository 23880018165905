import { memo } from 'react';

import cls from './congratulation.module.scss';
import { Modal } from '../../../../components/modal';
import { TextBlock } from '../../../../components/text-block';
import { Frame } from '../../../../components/frame';
import { Button } from '../../../../components/button';
import { Flex } from '../../../../components/flex';

interface Props {
  setNextStep: () => void;
}

const Congratulation = memo((props: Props) => {
  const { setNextStep } = props;

  return (
    <div className={cls.wrapper}>
      <div className={cls.color}>
        <Modal sizeM noOverlay>
          <Frame>
            <TextBlock centred header="Congratulations!">
              You are now ready to start designing your website. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. In sed libero at ligula fermentum hendrerit.
            </TextBlock>
          </Frame>
          <Flex center>
            <Button primary onClick={setNextStep}>
              Let start designinng
            </Button>
          </Flex>
        </Modal>
      </div>
    </div>
  );
});

export { Congratulation };
