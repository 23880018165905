import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import services from "../../services";
import {
  setIsUserAuthenticatedAction,
  setUserRoleAction,
} from "../../store/ducks/inSiteAppState";
import { decryptRoleSecrets } from "../../utils";

export default function useRefreshAuthentication() {
  const dispatch = useDispatch();
  const [appAuthenticating, setAppAuthenticating] = useState(true);

  const refresh = async () => {
    console.log("Refresh Call");
    try {
      let res = await services.refresh();
      const enc = res.headers.enc;
      const iv = res.headers.iv;
      if (res.status === 204 && enc && iv) {
        dispatch(setUserRoleAction(decryptRoleSecrets(enc, iv)));
        dispatch(setIsUserAuthenticatedAction(true));
        setAppAuthenticating(false);
        return Promise.resolve();
      } else {
        dispatch(setIsUserAuthenticatedAction(false));
        setAppAuthenticating(false);
        return Promise.reject();
      }
    } catch (error: any) {
      setAppAuthenticating(false);
      dispatch(setIsUserAuthenticatedAction(false));
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  return appAuthenticating;
}
