import axios from 'axios';
import { getAxiosConfig } from '../utils';

const URL_SSO = process.env.REACT_APP_ENV_SSO_API_DOMAIN || '';
const URL_GMB = process.env.REACT_APP_ENV_GMB_API_DOMAIN || '';

const getGMBConnectionLink = async () => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(URL_SSO, '/gmb_callback', 'GET', headers, data),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBAccounts = async () => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(URL_GMB, '/accounts/list_accounts', 'GET', headers, data),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBAccountLocations = async (accountId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(URL_GMB, `/locations/list_locations/${accountId}`, 'GET', headers, data),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBProfileInfo = async (locationId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(URL_GMB, `/locations/get_location/${locationId}`, 'GET', headers, data),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBReviewList = async (accountId: string, locationId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/reviews/list_reviews?account_id=${accountId}&location_id=${locationId}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBReview = async (accountId: string, locationId: string, reviewId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/reviews/get_review?account_id=${accountId}&location_id=${locationId}&review_id=${reviewId}`,
        'GET',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const postGMBReview = async (
  accountId: string,
  locationId: string,
  reviewId: string,
  comment: string,
) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {
    comment,
  };

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/reviews/reply_to_review?account_id=${accountId}&location_id=${locationId}&review_id=${reviewId}`,
        'PUT',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteGMBReview = async (accountId: string, locationId: string, reviewId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    const response = await axios.request(
      getAxiosConfig(
        URL_GMB,
        `/reviews/delete_review_reply?account_id=${accountId}&location_id=${locationId}&review_id=${reviewId}`,
        'DELETE',
        headers,
        data,
      ),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGMBWriteReviewLink = async (locationId: string) => {
  const headers = {
    accept: 'application/json',
  };

  const data = {};

  try {
    // base url is different, should be hardcoded :facepalm:
    const response = await axios.request(
      getAxiosConfig(`https://g.rankworks.com`, `/r/${locationId}`, 'GET', headers, data),
    );

    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export {
  getGMBConnectionLink,
  getGMBAccounts,
  getGMBAccountLocations,
  getGMBProfileInfo,
  getGMBReviewList,
  getGMBReview,
  postGMBReview,
  deleteGMBReview,
  getGMBWriteReviewLink,
};
