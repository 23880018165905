const formatSocialValue = (value: number | string): string => {
  if (value === 0) return '0';
  if (!value) return '-';
  if (typeof value === 'string') return value;

  if (value < 1_000) {
    if (value % 1 === 0) return `${value}`;
    else return `${value.toFixed(2)}`;
  }
  if (value < 10_000) return `${(value / 1_000).toFixed(2)}K`;
  if (value < 100_000) return `${(value / 1_000).toFixed(1)}K`;
  if (value < 1_000_000) return `${(value / 1_000) >> 0}K`;
  if (value < 10_000_000) return `${(value / 1_000_000).toFixed(2)}M`;
  if (value < 100_000_000) return `${(value / 1_000_000).toFixed(1)}M`;
  if (value < 1_000_000_000) return `${(value / 1_000_000) >> 0}M`;
  if (value < 10_000_000_000) return `${(value / 1_000_000_000).toFixed(2)}B`;
  if (value < 100_000_000_000) return `${(value / 1_000_000_000).toFixed(1)}B`;

  return `${Math.round(value / 1_000_000_000)}B`;
};

export { formatSocialValue };
